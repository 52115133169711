import { Grid } from '@material-ui/core'
import { useDispatch } from 'react-redux'

import Footer from './footer'
import './transactionHistory.css'
import HistoryTable from './historyTable'
import { ModalHeader } from '../../../components'
import { setTHModal } from '../../../redux/modules/globalModals'

const TransactionHistory = () => {
  const dispatch = useDispatch()

  const closeModal = () => dispatch(setTHModal({ isTHModalVisible: false }))

  return (
    <div className="modalContainer">
      <ModalHeader title={'Transaction History'} closeModal={closeModal} />
      <div style={{ flex: 1 }}>
        <Grid container>
          <Grid item lg={2} xl={3} md={2} sm={0} xs={0}></Grid>
          <Grid item lg={8} xl={6} md={8} sm={12} xs={12}>
            <HistoryTable />
          </Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  )
}

export default TransactionHistory
