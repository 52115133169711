import React, { useState, useEffect } from 'react'
import { useLocation, Route, Switch } from 'react-router-dom'

import Login from './Login'
import SignUp from './Signup'
import RegistrationDetail from './RegistrationDetail'
import VerifyEmail from './verifyEmail'
import { Navbar } from '../../components'
import Cast from './Cast/Cast'
import Game from './Game/Game'
import Home from './Home'
import ForgetPassword from './ForgetPassword'
import './unAuthenticate.css'

const UnAuthenticate = () => {
  const [selectedPage, setSelectedPage] = useState('home')

  const location = useLocation()

  useEffect(() => {
    const path = location.pathname.split('/')

    if (path[path.length - 1] === '') setSelectedPage('home')
    else if (path[path.length - 1] === 'cast') setSelectedPage('cast')
    else if (path[path.length - 1] === 'game') setSelectedPage('game')
    else setSelectedPage('')
  }, [location.pathname])

  return (
    <div className="pagesContainer">
      {selectedPage !== '' && (
        <div className="navBarPostionContainer">
          <Navbar selectedPage={selectedPage} />
        </div>
      )}
      <Switch>
        <Route exact path={'/'}>
          <Home />
        </Route>
        <Route exact path={'/game'}>
          <Game />
        </Route>
        <Route exact path={'/cast'}>
          <Cast />
        </Route>
        <Route exact path={'/login'}>
          <Login />
        </Route>
        <Route exact path={'/reset-password'}>
          <ForgetPassword />
        </Route>
        <Route exact path={'/register'}>
          <SignUp />
        </Route>
        <Route exact path={'/register/detail'}>
          <RegistrationDetail />
        </Route>
        <Route exact path={'/register/mobile-verification'}>
          <VerifyEmail />
        </Route>
      </Switch>
    </div>
  )
}

export default UnAuthenticate
