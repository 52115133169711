import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  icon: {
    fill: '#969696'
  },
  selected: {
    width: '100%',
    color: 'white'
  },
  unselected: {
    width: '100%',
    color: 'rgb(119,119,131)'
  }
})

export { useStyles }
