import React, { useState } from 'react'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core'

import { VideoPlayer, ButtonComp } from '../../../components'
import { PrimaryButton } from '../../../styles/colors'
import { setWatcher } from '../../../redux/modules/reserveData'

const videoJsOptions = {
  autoplay: true,
  controls: true,
  preload: 'none',
  poster:
    'https://upload.wikimedia.org/wikipedia/commons/4/46/Bear_Alaska_%283%29.jpg',
  sources: [
    {
      src:
        'https://lamberta.github.io/html5-animation/examples/ch04/assets/movieclip.webm',
      type: 'video/webm'
    }
  ]
}

const useStyles = makeStyles((theme) => ({
  playIcon: {
    color: 'rgba(255,255,255,0.5)',
    fontSize: 40
  }
}))
const WatchTrailer = () => {
  const [isVisible, setIsVisible] = useState(false)
  const [player, setPlayer] = useState(null)

  const classes = useStyles()

  const User = useSelector((state) => state.user)

  const dispatch = useDispatch()

  const history = useHistory()

  const listenPlayPause = (value) => setIsVisible(value)

  const getPlayer = (_player) => setPlayer(_player)

  const handleByForYourself = () => {
    if (User.isLogged) {
      dispatch(setWatcher({ for: 'yourself' }))
      history.push('/checkout')
    }
    history.push('/login')
  }

  const handleBuyForFriend = () => {
    if (User.isLogged) {
      dispatch(setWatcher({ for: 'friend' }))
      history.push('/checkout')
    }
    history.push('/login')
  }

  const playTrailer = () => {
    if (player) player.play()
  }

  return (
    <div className="trailerContainer">
      <VideoPlayer
        getPlayer={getPlayer}
        {...videoJsOptions}
        showCloseButton={false}
        listenPlayPause={listenPlayPause}
      />

      {isVisible && (
        <div className="trailer-reserveContainer">
          <div className="trailerButtons">
            <p className="buyNowDescription">
              Buy Now or share with your friends
            </p>
            <div className="centeredContainer">
              <div className="trailerButtonContainer">
                <ButtonComp
                  title="BUY FOR YOURSELF"
                  height={35}
                  width={'100%'}
                  borderColor={PrimaryButton}
                  backgroundColor={'transparent'}
                  hoverBackgroundColor={PrimaryButton}
                  onClickHandler={handleByForYourself}
                />
              </div>
              <div className="trailerButtonContainer">
                <ButtonComp
                  title="BUY FOR A FRIEND"
                  height={35}
                  width={'100%'}
                  borderColor={PrimaryButton}
                  backgroundColor={'transparent'}
                  hoverBackgroundColor={PrimaryButton}
                  onClickHandler={handleBuyForFriend}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {isVisible && (
        <div
          className="trailer-play-button"
          role="presentation"
          onClick={playTrailer}>
          <PlayArrowIcon className={classes.playIcon} />
        </div>
      )}
    </div>
  )
}

export { WatchTrailer }
