import React, { useState } from 'react'
import { Grid, InputBase } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useMediaPredicate } from 'react-media-hook'
import { useDispatch } from 'react-redux'

import { setEmailAddress } from '../../../redux/modules/user'
import ButtonComp from '../../../components/Button'
import { MintrioLogo } from '../../../assets'
import { SecondaryButton } from '../../../styles/colors'
import { validateEmail } from '../../../lib/utils'
import './signup.css'
import { useStyles } from './styles'

const SignUp = () => {
  const lessThan770 = useMediaPredicate('(max-width: 770px)')

  const history = useHistory()

  const [email, setEmail] = useState('')
  const [error, setError] = useState(null)

  const dispatch = useDispatch()

  const classes = useStyles()

  const handleSignup = () => {
    if (email.trim() === '') {
      setError('Please enter your email address')
      return
    }

    if (!validateEmail(email.trim())) {
      setError('Please enter a valid email address')
      return
    }

    dispatch(setEmailAddress({ email }))

    history.push(`/register/detail`)
  }
  const handleEmail = (value) => {
    setError(null)
    setEmail(value)
  }

  return (
    <div className="fullContainer">
      <div class="mintrioBackground">
        <div className="logoContainer">
          <img src={MintrioLogo} class="mintrioLogo" />
          <p class="heading">All Mintrio Originals</p>
        </div>

        <div className="formContainer">
          <Grid container className={'flex'}>
            <Grid item md={2} lg={4} xl={4} sm={0} xs={0}></Grid>
            <Grid item md={8} lg={4} xl={4} sm={12} xs={12}>
              <div className="loginFormContent">
                <div className="subHeadingContainer">
                  <div className="welcomeBackText">READY TO WATCH?</div>
                  <div className="signupSubHeader">
                    ENTER YOUR EMAIL ADDRESS TO CREATE YOUR ACCOUNT
                  </div>
                </div>
                <div className="signUpContent">
                  <Grid container spacing={1}>
                    {lessThan770 && (
                      <Grid
                        item
                        lg={0}
                        xl={0}
                        md={0}
                        sm={2}
                        xs={2}
                        className="padding-top-20"></Grid>
                    )}
                    <Grid
                      item
                      lg={8}
                      xl={8}
                      md={8}
                      sm={8}
                      xs={8}
                      className="padding-top-20">
                      <InputBase
                        placeholder="Email Address"
                        variant="outlined"
                        type="email"
                        className={
                          lessThan770
                            ? classes.mobileTextField
                            : classes.desktopTextField
                        }
                        value={email}
                        onChange={(event) => handleEmail(event.target.value)}
                      />
                      <p className="phoneNumberErrorText">{error}</p>
                    </Grid>
                    {lessThan770 && (
                      <Grid
                        item
                        lg={0}
                        xl={0}
                        md={0}
                        sm={2}
                        xs={2}
                        className="padding-top-20"></Grid>
                    )}
                    {lessThan770 && (
                      <Grid
                        item
                        lg={0}
                        xl={0}
                        md={0}
                        sm={2}
                        xs={2}
                        className="padding-top-20"></Grid>
                    )}
                    <Grid
                      item
                      lg={4}
                      xl={4}
                      md={lessThan770 ? 8 : 4}
                      sm={8}
                      xs={8}
                      className="padding-top-20">
                      <ButtonComp
                        backgroundColor={SecondaryButton}
                        borderColor={SecondaryButton}
                        borderTopLeftRadius={lessThan770 ? 5 : 0}
                        borderBottomLeftRadius={lessThan770 ? 5 : 0}
                        onClickHandler={() => handleSignup()}
                        disabled={false}
                        height={36}
                        title={'GET STARTED'}
                        width={'100%'}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div className="alreadyHaveAccount">
                  Already have an account?{' '}
                  <a href="/login">
                    <span className="alreadySigninText">SIGN IN</span>
                  </a>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  )
}

export default SignUp
