import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from '@material-ui/core'

import PreBooking from './PreBooking'
import {
  setIsReserveModal,
  setTHModal,
  setEditAccountModal
} from '../../redux/modules/globalModals'
import TransactionHistory from './TransactionHistory'
import EditAccount from './EditAccount'

const ProtectedModals = () => {
  const dispatch = useDispatch()

  const globalModals = useSelector((state) => state.globalModals)

  const closePreBookingModal = () =>
    dispatch(setIsReserveModal({ isReserveModalVisible: false }))

  const closeTransactionHistoryModal = () =>
    dispatch(setTHModal({ isTHModalVisible: false }))

  const closeEditAccountModal = () =>
    dispatch(setEditAccountModal({ isEAModalVisible: false }))

  return (
    <div>
      <Modal
        open={globalModals.isReserveModalVisible}
        onClose={closePreBookingModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="centeredContainer">
        <PreBooking closeModal={closePreBookingModal} />
      </Modal>

      <Modal
        open={globalModals.isTHModalVisible}
        onClose={closeTransactionHistoryModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="centeredContainer">
        <TransactionHistory />
      </Modal>

      <Modal
        open={globalModals.isEAModalVisible}
        onClose={closeEditAccountModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="centeredContainer">
        <EditAccount />
      </Modal>
    </div>
  )
}

export default ProtectedModals
