import React from 'react'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'

import { SocialIcons } from '../../../lib/constant'

const Footer = ({ handleNext, handlePrevious }) => {
  return (
    <div className="footerLayer">
      <div className="footerIconText">
        <div className="socialIconsContainer">
          {SocialIcons.map((item) => {
            return (
              <div id={item.id}>
                <img src={item.icon} className="footer-iconSize" />
              </div>
            )
          })}
        </div>
        <div className="ishratMadeInChina">#ISHRATMADEINCHINA</div>
      </div>

      <div className="nextPrevButtonContainer">
        <div className="nextButtonContainer">
          <div
            className="nextPrevButton"
            role="presentation"
            onClick={handlePrevious}>
            <ChevronLeft style={{ fontSize: 40 }} />
          </div>
        </div>
        <div className="previousButtonContainer">
          <div
            className="nextPrevButton"
            role="presentation"
            onClick={handleNext}>
            <ChevronRight style={{ fontSize: 40 }} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
