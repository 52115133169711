import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  desktopTextField: {
    color: '#ffffff',
    border: '1px solid white',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    height: 36,
    borderRadius: 4,
    width: '100%',
    paddingLeft: 12,
    paddingRight: 12
  },
  mobileTextField: {
    color: '#ffffff',
    border: '1px solid white',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    height: 36,
    borderRadius: 4,
    width: '100%',
    paddingLeft: 12,
    paddingRight: 12
  }
}))
