import React, { useState, useReducer } from 'react'
import { useHistory } from 'react-router-dom'
import { API, graphqlOperation } from 'aws-amplify'
import { useDispatch, useSelector } from 'react-redux'

import { ButtonComp, FormInput } from '../../../components'
import { validatePhoneNumber } from '../../../lib/utils'
import * as subscriptions from '../../../graphql/subscriptions'
import { processCheckout } from '../../../backend/payment'
import { purchaseMovie } from '../../../redux/modules/user'
import { postCheckoutJazzCash } from '../../../redux/modules/checkout'

const JazzCashForm = ({ setErrorDialog }) => {
  const [{ phoneNumber, cnic }, setPayload] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { cnic: '', phoneNumber: '' }
  )
  const {
    plan: { id: planId, price }
  } = useSelector((state) => state.reserveData)

  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const history = useHistory()

  const dispatch = useDispatch()

  const handleInput = (event) => {
    const { name, value } = event.target

    setPayload({ [name]: value })
    setError(null)
  }

  const checkout = async () => {
    if (phoneNumber === '') {
      setError('Please enter your mobile number')

      return
    }

    if (!validatePhoneNumber(phoneNumber)) {
      setError('Please enter a valid phone number')

      return
    }

    if (cnic === '' || cnic.length !== 6) {
      setError('Please enter last 6 digits of CNIC')

      return
    }

    if (isNaN(cnic)) {
      setError('Please enter valid last 6 digits of CNIC')

      return
    }

    setLoading(true)

    const payload = {
      cnic,
      phoneNumber,
      planId,
      price
    }

    try {
      const id = await processCheckout(payload, 'JAZZ_CASH_MOBILE_ACCOUNT')
      if (!id) {
        setLoading(false)
        setErrorDialog()

        return
      }
      listenForPayment(id)
    } catch (err) {
      setLoading(false)
      setErrorDialog()
    }
  }

  const listenForPayment = (checkoutId) => {
    const subscription = API.graphql(
      graphqlOperation(subscriptions.onUpdatePaymentProcessingStatus, {
        id: checkoutId
      })
    ).subscribe({
      next: async ({ provider, value }) => {
        try {
          subscription.unsubscribe()

          setLoading(false)

          const { id, status } = value.data.onUpdatePaymentProcessingStatus
          if (id === checkoutId && status === 'SUCCESS') {
            await dispatch(postCheckoutJazzCash(id))

            dispatch(purchaseMovie())

            history.replace('/purchase-done')
          } else {
            setErrorDialog()
          }
        } catch (error) {
          console.error(error)
        }
      },
      error: (error) => {
        subscription.unsubscribe()

        setLoading(false)
        setErrorDialog()
      }
    })
  }

  return (
    <div>
      <div className="jazzcashInput">
        <FormInput
          value={phoneNumber}
          placeholder="Phone Number"
          id="phoneNumber"
          name="phoneNumber"
          onChangeHandler={handleInput}
        />
      </div>
      <div className="jazzcashInput">
        <FormInput
          value={cnic}
          placeholder="Last Six Digits of CNIC"
          id="cnic"
          name="cnic"
          onChangeHandler={handleInput}
        />
      </div>

      {error && <div className="jazzcashInput">{error}</div>}

      <div className="jazzcashInput">
        <ButtonComp
          onClickHandler={checkout}
          title={'CONTINUE'}
          height={36}
          loading={loading}
          width={300}
        />
      </div>
    </div>
  )
}

export default JazzCashForm
