export const primaryBackground = '#151429'
export const SelectedBackground = '#64648B'
export const SelectedDarkBackground = '#3C3C63'
export const SelectedBorder = '#282DDB'
export const PrimaryButton = '#282DDB'
export const SecondaryButton = '#3C3C63'
export const PrimaryButtonHover = '#141AF5'
export const SecondaryButtonHover = '#ffffff'
export const ButtonText = '#ffffff'
export const SecondaryHoverButtonText = '#000000'
export const PrimaryText = '#ffffff'
export const SecondaryText = '#9D9D9D'
export const SelectedPlan = '#282DDB'
export const unSelectedPlan = '#1E2082'
export const TextLink = '#00FBAA'
export const Transparent = 'transparent'
