import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { useDispatch } from 'react-redux'

import ButtonComp from '../../../components/Button'
import { setWatcher } from '../../../redux/modules/reserveData'
import {
  SelectedYourself,
  Yourself,
  SelectedFriend,
  Friend
} from '../../../assets'
import './preBooking.css'
import { ModalHeader } from '../../../components'

const PreBooking = (props) => {
  const history = useHistory()

  const [selected, setSelected] = useState(null)

  const dispatch = useDispatch()

  const navigateToFamilyDetail = () => {
    if (selected) {
      dispatch(setWatcher({ for: selected }))

      history.push('/friends-detail')

      props.closeModal()
    }

    return
  }
  const navigateToPlan = () => {
    if (selected) {
      dispatch(setWatcher({ for: selected }))

      history.push('/checkout')

      props.closeModal()
    }

    return
  }

  const handleClose = () => props.closeModal()
  return (
    <div className="preBookingModal">
      <div className="reserveContainer">
        <ModalHeader title="RESERVE NOW" closeModal={handleClose} />
        <div className="preBookingContent">
          <Grid container justify="center" spacing={3}>
            <Grid item xs={8} md={3} ls={4} xl={4} xs={8}>
              <div
                role="presentation"
                onClick={() => setSelected('yourself')}
                className={`preBookingContainer ${
                  selected === 'yourself' ? 'itemSelected' : 'itemNotSelected'
                }`}>
                <img
                  src={selected === 'yourself' ? SelectedYourself : Yourself}
                  className="reserIcon"
                  alt=""
                />
                <h4
                  className={
                    selected === 'friend' ? 'selectedColor' : 'notSelectedColor'
                  }>
                  FOR YOURSELF
                </h4>
              </div>
            </Grid>
            <Grid item xs={8} md={3} ls={4} xl={4} xs={8}>
              <div
                role="presentation"
                className={`preBookingContainer ${
                  selected === 'friend' ? 'itemSelected' : 'itemNotSelected'
                }`}
                onClick={() => setSelected('friend')}>
                <img
                  src={selected === 'friend' ? SelectedFriend : Friend}
                  className="reserIcon"
                  alt=""
                />
                <h4
                  className={
                    selected === 'friend' ? 'selectedColor' : 'notSelectedColor'
                  }>
                  FOR A FRIEND
                </h4>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="buttonContainer">
          <div className="buttonComp">
            <ButtonComp
              onClickHandler={
                selected === 'friend' ? navigateToFamilyDetail : navigateToPlan
              }
              disabled={selected ? false : true}
              title={'CONTINUE'}
              width={'100%'}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PreBooking
