import React, { useState, useEffect } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Navbar } from '../../../components'
import Cast from '../../unauthenticate/Cast/Cast'
import Game from '../../unauthenticate/Game/Game'
import HomePage from '../../unauthenticate/Home'
import LoggedIn from '../LoggedIn'

const Home = () => {
  const [selectedPage, setSelectedPage] = useState('home')

  const location = useLocation()

  const User = useSelector((state) => state.user)

  //select the menu relative to scrolling
  useEffect(() => {
    const path = location.pathname.split('/')
    if (path[path.length - 1] === '') {
      setSelectedPage('home')
    } else if (path[path.length - 1] === 'cast') {
      setSelectedPage('cast')
    } else if (path[path.length - 1] === 'game') {
      setSelectedPage('game')
    }
  }, [location])

  return (
    <div className="fullColumnContainer">
      <div className="navBarPostionContainer">
        <Navbar selectedPage={selectedPage} />
      </div>
      <Switch>
        <Route exact path={'/'}>
          {User.purchased ? <LoggedIn /> : <HomePage />}
        </Route>
        <Route exact path={'/game'}>
          <Game />
        </Route>
        <Route exact path={'/cast'}>
          <Cast />
        </Route>
      </Switch>
    </div>
  )
}

export default Home
