import React, { useState } from 'react'
import { AppBar, Toolbar, IconButton, Menu, Modal } from '@material-ui/core'
import {
  AccountCircle,
  Notifications as NotificationsIcon,
  NotificationsNone as NotificationsNoneIcon,
  Menu as MenuIcon
} from '@material-ui/icons'
import { useSelector, useDispatch } from 'react-redux'
import { useMediaPredicate } from 'react-media-hook'
import { useHistory } from 'react-router-dom'

import './NavBar.css'
import { useStyles } from './styles'
import { logo } from '../../assets'
import MenuDrawer from './menuDrawer'
import {
  setTHModal,
  setEditAccountModal
} from '../../redux/modules/globalModals'
import { logoutUser } from '../../redux/modules/user'
import { setWatcher } from '../../redux/modules/reserveData'
import ProfileMenuDrawer from './profileMenuDrawer'
import NotificationPane from './notificationPane'
import NotificationModal from './notificationModal'
import {
  NavBarItems,
  ProfileDropDownItems,
  BuyDropDownItems
} from '../../lib/constant'

const mobileMenuId = 'primary-search-account-menu-mobile'
const profileMenuId = 'primary-search-profile-menu-mobile'

function NavBar(props) {
  const lessThan450 = useMediaPredicate('(max-width: 450px)')
  const lessThan600 = useMediaPredicate('(max-width: 600px)')
  const User = useSelector((state) => state.user)

  const history = useHistory()
  const dispatch = useDispatch()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const [bookNowanchorEl, setBookNowAnchorEl] = useState(null)
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null)
  const [notificationModalVisible, setNotificationModalVisible] = useState(
    false
  )

  const isMenuOpen = Boolean(anchorEl)
  const isBookNowOpen = Boolean(bookNowanchorEl)

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  })

  const menuId = 'primary-search-account-menu'
  const bookMenu = 'primary-search-account-menu'
  const handleProfileMenuOpen = (event) => setAnchorEl(event.currentTarget)
  const handleMenuClose = () => setAnchorEl(null)

  const handleProfileMenuClick = async (id) => {
    setAnchorEl(null)
    if (id === '0') dispatch(setEditAccountModal({ isEAModalVisible: true }))
    else if (id === '1') dispatch(setTHModal({ isTHModalVisible: true }))
    else if (id === '2') {
      try {
        await dispatch(logoutUser())

        history.push('/')
      } catch (err) {
        console.log('Error in logout is: ', err)
      }
    }
  }

  const openBookNowMenu = (event) => setBookNowAnchorEl(event.currentTarget)
  const closeBookNowMenu = () => setBookNowAnchorEl(null)

  const openNotificationMenu = (event) => {
    if (lessThan600) {
      return setNotificationModalVisible(true)
    }
    setNotificationAnchorEl(event.currentTarget)
  }

  const closeNotificationPane = () => setNotificationAnchorEl(null)

  const navigateToFriendDetail = () => {
    dispatch(setWatcher({ for: 'friend' }))
    history.push('/friends-detail')
    setBookNowAnchorEl(null)
  }
  const navigateToPlan = () => {
    dispatch(setWatcher({ for: 'yourself' }))
    history.push('/checkout')
    setBookNowAnchorEl(null)
  }

  const closeNotificationModal = () => setNotificationModalVisible(false)

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    setState({ ...state, [anchor]: open })
  }

  const toggleProfileDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    setState({ ...state, [anchor]: open })
  }

  const handleMenu = (menuId) => {
    if (menuId === '0') {
      history.push('/')
      return
    }
    if (menuId === '1') {
      history.push('/cast')
      return
    }
    if (menuId === '2') {
      history.push('/game')
      return
    }
    if (menuId === '3') {
      return
    }
  }

  const handleBuyNow = (event) => {
    if (User.isLogged) {
      openBookNowMenu(event)
      return
    }
    history.push('/login')
  }

  return (
    <div className={classes.grow}>
      <AppBar position="sticky" className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={toggleDrawer('left', true)}
              color="inherit">
              <MenuIcon className={'menuIcon'} />
            </IconButton>
            <div className="mobile-logo-container">
              <img className="mobile-logo" src={logo} />
            </div>
          </div>
          <div className="desktop-container">
            <div className="image">
              <img className="image-size" src={logo} />
              <p className="mintrio-title">MINTRIO</p>
            </div>

            <div className={classes.grow}>
              <div className={classes.sectionDesktop}>
                {NavBarItems.map((item) => {
                  return (
                    <div
                      id={item.id}
                      role="presentation"
                      onClick={() => handleMenu(item.id)}
                      className={
                        props.selectedPage === item.pageUrl
                          ? 'topMenuActive'
                          : 'topMenu'
                      }>
                      {item.name}
                    </div>
                  )
                })}

                <div role="presentation" className={'buyNowButton'}>
                  <span
                    role="presentation"
                    className="buyNowText"
                    onClick={(event) => handleBuyNow(event)}>
                    BUY NOW
                  </span>
                </div>
                <div className={'notificationSection'}>
                  <span style={{ marginRight: 10, position: 'relative' }}>
                    <IconButton
                      onClick={openNotificationMenu}
                      edge="center"
                      aria-label="show 17 new notifications"
                      size={8}
                      color="inherit">
                      {lessThan600 ? (
                        <NotificationsNoneIcon style={{ color: 'white' }} />
                      ) : (
                        <NotificationsIcon style={{ color: '#282DDB' }} />
                      )}
                    </IconButton>
                    {!lessThan600 && (
                      <div className="notificationBellCircle"></div>
                    )}
                  </span>
                  {!User.isLogged ? (
                    <span
                      className="loginText"
                      role="presentation"
                      onClick={() => history.push('/login')}>
                      LOGIN
                    </span>
                  ) : (
                    <IconButton
                      edge="center"
                      size={8}
                      aria-label="account of current user"
                      aria-controls={lessThan450 ? profileMenuId : menuId}
                      aria-haspopup="true"
                      onClick={
                        lessThan450
                          ? toggleProfileDrawer('right', true)
                          : handleProfileMenuOpen
                      }
                      color="inherit"
                      className="avatarButton">
                      <AccountCircle className={classes.avatar} />
                    </IconButton>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <MenuDrawer
        mobileMenuId={mobileMenuId}
        state={state}
        toggleDrawer={toggleDrawer}
        selectedPage={props.selectedPage}
      />
      <ProfileMenuDrawer
        state={state}
        toggleProfileDrawer={toggleProfileDrawer}
      />

      {/* profile drop down menu */}
      <Menu
        className={classes.menuRoot}
        MenuListProps={{ disablePadding: true }}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isMenuOpen}
        onClose={handleMenuClose}>
        <div className="centeredContainer">
          <div className="topArrow" />
        </div>

        {User && User.attributes && (
          <div className={classes.dropDownMenuName}>{User.attributes.name}</div>
        )}

        {ProfileDropDownItems.map((item) => {
          return (
            <div className="customMenuContainer" role={'presentation'}>
              <div
                className="customMenuItem"
                role="presentation"
                onClick={() => handleProfileMenuClick(item.id)}>
                <div className="menuTitle">{item.title}</div>
              </div>
            </div>
          )
        })}
      </Menu>

      {/* Buy button drop down menu */}
      <Menu
        className={classes.menuRoot}
        MenuListProps={{ disablePadding: true }}
        anchorEl={bookNowanchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        id={bookMenu}
        keepMounted
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isBookNowOpen}
        onClose={closeBookNowMenu}>
        <div className="centeredContainer">
          <div className="topArrow" />
        </div>
        {BuyDropDownItems.map((item) => {
          return (
            <div className="customMenuContainer" role={'presentation'}>
              <div
                className="customMenuItem"
                role="presentation"
                onClick={
                  item.id == '0' ? navigateToPlan : navigateToFriendDetail
                }>
                <div className="menuTitle">{item.title}</div>
              </div>
            </div>
          )
        })}
      </Menu>

      {/* Notification Pane */}
      <NotificationPane
        notificationAnchorEl={notificationAnchorEl}
        closeNotificationPane={closeNotificationPane}
      />

      <Modal
        open={notificationModalVisible}
        onClose={closeNotificationModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        <NotificationModal closeModal={closeNotificationModal} />
      </Modal>
    </div>
  )
}

export default NavBar
