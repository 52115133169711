import { useDispatch } from 'react-redux'

import { ButtonComp } from '../../../components'
import { setTHModal } from '../../../redux/modules/globalModals'
import { PrimaryButton } from '../../../styles/colors'

const Footer = () => {
  const dispatch = useDispatch()
  const closeModal = () => dispatch(setTHModal({ isTHModalVisible: false }))

  return (
    <div className="thFooterContainer">
      <ButtonComp
        backgroundColor={PrimaryButton}
        onClickHandler={closeModal}
        disabled={false}
        title={'DONE'}
        width={170}
      />
    </div>
  )
}

export default Footer
