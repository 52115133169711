import React, { useState, useEffect, useReducer } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Grid,
  Select,
  MenuItem,
  Modal,
  IconButton,
  InputAdornment
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { Auth } from 'aws-amplify'
import { Visibility, VisibilityOff } from '@material-ui/icons'

import { useStyles } from './styles'
import {
  MuhibAvatar,
  SanamAvatar,
  SaraAvatar,
  HSYAvatar
} from '../../../assets'
import './registrationDetail.css'
import { ButtonComp, FormInput, SelectInput, Header } from '../../../components'
import { Months, NameToNumber, Days, Years } from '../../../lib/constant'
import { validatePhoneNumber } from '../../../lib/utils'
import { setAccountPassword } from '../../../redux/modules/user'
import AvatarList from './avatarList'

const mapping = {
  0: MuhibAvatar,
  1: SanamAvatar,
  2: SaraAvatar,
  3: HSYAvatar
}

const RegistrationDetail = () => {
  const history = useHistory()

  const [
    {
      firstName,
      lastName,
      gender,
      day,
      month,
      year,
      phoneCode,
      phoneNumber,
      password
    },
    setInputValue
  ] = useReducer((state, newState) => ({ ...state, ...newState }), {
    firstName: '',
    lastName: '',
    gender: 'Gender',
    day: 'DAY',
    month: 'MONTH',
    year: 'YEAR',
    phoneCode: '+1',
    phoneNumber: '',
    password: ''
  })

  const classes = useStyles()

  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(null)
  const [showPassword, setShowPassword] = useState(false)
  const [isAvatarModalVisible, setIsAvatarModalVisible] = useState(false)
  const [selectedAvatar, setSelectedAvatar] = useState('0')

  const User = useSelector((state) => state.user)

  const dispatch = useDispatch()

  const handleInput = (event) => {
    const { name, value } = event.target

    setInputValue({ [name]: value })
  }

  useEffect(() => {
    if (User.email) return

    history.push('/register')
  }, [User.email, history])

  const closeAvatarModal = () => setIsAvatarModalVisible(false)

  const handleAvatar = (value) => {
    setSelectedAvatar(value)
    setIsAvatarModalVisible(false)
  }

  const handleSignUp = async () => {
    setError(null)

    if (!validateData()) {
      return
    }

    setLoading(true)

    let detail = {
      birthdate: `${day}/${NameToNumber[month]}/${year}`,
      gender: gender,
      email: User.email,
      name: firstName,
      given_name: lastName,
      picture: selectedAvatar
    }

    if (phoneNumber) {
      const phone = `${phoneCode}${phoneNumber}`

      detail['phone_number'] = phone
    }

    try {
      await Auth.signUp({
        username: User.email,
        password: password,
        attributes: {
          ...detail
        }
      })

      dispatch(setAccountPassword({ password }))

      history.push('/register/mobile-verification')

      setLoading(false)
    } catch (err) {
      setLoading(false)

      switch (err.code) {
        case 'UsernameExistsException':
          setError('An account with this email address already exists')
          break
        default:
          setError('Something went wrong please try again')
      }
    }
  }

  const validateData = () => {
    if (firstName.trim() === '') {
      setError('Please enter your first name')
      return false
    }

    if (lastName.trim() === '') {
      setError('Please enter your last name')
      return false
    }

    if (gender === 'Gender') {
      setError('Please select your gender')
      return false
    }

    if (day === 'DAY') {
      setError('Please select your day')
      return false
    }

    if (month === 'MONTH') {
      setError('Please select your month')
      return false
    }

    if (year === 'YEAR') {
      setError('Please select your year')
      return false
    }

    if (password.trim() === '') {
      setError('Please enter a password')
      return false
    }

    if (password.length < 8) {
      setError('Password must not be less than 8')
      return false
    }

    if (phoneNumber) {
      if (!validatePhoneNumber(`${phoneCode}${phoneNumber}`)) {
        setError('Please enter a valid phone number')
        return false
      }
    }
    return true
  }

  return (
    <div className="pageWithBackgound registrationDetailPage">
      <Header title="ENTER YOUR DETAILS" />

      <div className="preBookingContent">
        <Grid container className="formGrid">
          <Grid item md={3} lg={4} xl={4} sm={1} xs={0}></Grid>
          <Grid item md={6} lg={4} xl={4} sm={10} xs={12}>
            <div className="loginFormContent">
              <div className="selectedAvatarContainer">
                <img src={mapping[selectedAvatar]} className="selectedAvatar" />

                <div className="flexCenteredConatiner">
                  <ButtonComp
                    onClickHandler={() => setIsAvatarModalVisible(true)}
                    disabled={false}
                    borderColor="#000000"
                    title={'CHOOSE AVATAR'}
                    width={150}
                  />
                </div>
              </div>

              <div className="signupInputContainer">
                <FormInput
                  value={firstName}
                  placeholder="First Name *"
                  id="firstName"
                  name="firstName"
                  onChangeHandler={handleInput}
                />
              </div>
              <div className="signupInputContainer">
                <FormInput
                  value={lastName}
                  placeholder="Last Name *"
                  id="lastName"
                  name="lastName"
                  onChangeHandler={handleInput}
                />
              </div>

              <div className="signupInputContainer">
                <Select
                  inputProps={{
                    classes: {
                      icon: classes.icon
                    }
                  }}
                  className={
                    gender == 'Gender'
                      ? classes.unselectedGenderInput
                      : classes.genderInput
                  }
                  id="gender"
                  name="gender"
                  input={<SelectInput />}
                  value={gender}
                  onChange={handleInput}>
                  <MenuItem value={'Gender'}>Gender *</MenuItem>
                  <MenuItem value={'Male'}>Male</MenuItem>
                  <MenuItem value={'Female'}>Female</MenuItem>
                  <MenuItem value={'Other'}>Other</MenuItem>
                </Select>
              </div>

              <div className="dobInputContainer">
                <Select
                  inputProps={{
                    classes: {
                      icon: classes.icon
                    }
                  }}
                  id="day"
                  name="day"
                  input={<SelectInput />}
                  value={day}
                  className={
                    day == 'DAY' ? classes.unselected : classes.selected
                  }
                  onChange={handleInput}>
                  <MenuItem value={'DAY'}>DAY *</MenuItem>
                  {Days.map((item) => {
                    return <MenuItem value={item}>{item}</MenuItem>
                  })}
                </Select>
                <Select
                  inputProps={{
                    classes: {
                      icon: classes.icon
                    }
                  }}
                  className={
                    month == 'MONTH' ? classes.unselected : classes.selected
                  }
                  input={<SelectInput />}
                  value={month}
                  id="month"
                  name="month"
                  onChange={handleInput}>
                  <MenuItem value={'MONTH'}>MONTH *</MenuItem>
                  {Months.map((item) => {
                    return <MenuItem value={item}>{item}</MenuItem>
                  })}
                </Select>
                <Select
                  inputProps={{
                    classes: {
                      icon: classes.icon
                    }
                  }}
                  className={
                    year == 'YEAR' ? classes.unselected : classes.selected
                  }
                  id="year"
                  name="year"
                  input={<SelectInput />}
                  value={year}
                  onChange={handleInput}>
                  <MenuItem value={'YEAR'}>YEAR *</MenuItem>
                  {Years.map((item) => {
                    return <MenuItem value={item}>{item}</MenuItem>
                  })}
                </Select>
              </div>
              <div className="mobileInputContainer">
                <Select
                  inputProps={{
                    classes: {
                      icon: classes.icon
                    }
                  }}
                  className={
                    phoneCode == '+1'
                      ? classes.unselectedCode
                      : classes.selectedCode
                  }
                  id="phoneCode"
                  name="phoneCode"
                  value={phoneCode}
                  onChange={handleInput}
                  input={<SelectInput />}>
                  <MenuItem value={'+1'}>+1</MenuItem>
                  <MenuItem value={'+2'}>+92</MenuItem>
                </Select>
                <div style={{ width: '70%' }}>
                  <FormInput
                    value={phoneNumber}
                    placeholder="Mobile Number"
                    id="phoneNumber"
                    name="phoneNumber"
                    onChangeHandler={handleInput}
                  />
                </div>
              </div>
              <div className="signupInputContainer">
                <FormInput
                  value={password}
                  placeholder="Password "
                  id="password"
                  name="password"
                  onChangeHandler={handleInput}
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowPassword((preValue) => !preValue)
                        }>
                        {showPassword ? (
                          <Visibility className={classes.passwordIcon} />
                        ) : (
                          <VisibilityOff className={classes.passwordIcon} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </div>
              {error && <p className="signUpError">{error}</p>}
              <div
                className={
                  error
                    ? 'signupButtonContainer-error'
                    : 'signupButtonContainer'
                }>
                <ButtonComp
                  onClickHandler={handleSignUp}
                  borderColor="black"
                  disabled={false}
                  loading={loading}
                  title={'CONTINUE'}
                  width={'70%'}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <Modal
        open={isAvatarModalVisible}
        onClose={closeAvatarModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="avatarModalContainer">
        <AvatarList
          handleAvatar={handleAvatar}
          closeAvatarModal={closeAvatarModal}
          selectedValue={selectedAvatar}
        />
      </Modal>
    </div>
  )
}

export default RegistrationDetail
