export const logo = require('./images/logo.svg').default
export const user_profile = require('./images/profile.png').default
export const background4 = require('./images/background4.png').default
export const background_game = require('./images/background-game.png').default
export const hsy = require('./images/hsy.png').default
export const mohib = require('./images/mohib.png').default
export const sanam = require('./images/sanam.png').default
export const chiness = require('./images/chiness.png').default
export const reserveImage = require('./images/reserveImage.png').default
export const mohib_background = require('./images/mohib_background.png').default
export const sanam_background = require('./images/sanam_background.png').default
export const chiness_background = require('./images/chiness_background.png')
  .default
export const hsy_background = require('./images/hsy_background.png').default
export const game = require('./images/game.png').default
export const applestore = require('./images/applestore.png').default
export const playstore = require('./images/google.png').default
export const mentrio_background = require('./images/mentrio_background.png')
  .default
export const paypal = require('./images/paypal.svg').default
export const visa = require('./images/visa.svg').default
export const masterCard = require('./images/masterCard.svg').default
export const easypesa = require('./images/easypesa.svg').default
export const jazCash = require('./images/jazCash.svg').default
export const profileImage = require('./images/profileImage.png').default
export const toonifyImage = require('./images/toonifyImage.png').default
export const Yourself = require('./images/yourself.png').default
export const Friend = require('./images/friend.png').default
export const SelectedFriend = require('./images/selectedFriend.png').default
export const SelectedYourself = require('./images/selectedYourself.png').default
export const IshratLogo2 = require('./images/ishratLogo2.png').default
export const ThumbsUp = require('./images/thumbsUp.svg').default
export const ThumbsDown = require('./images/thumbsDown.svg').default
export const LoggedInBackground = require('./images/mobile/loggedInBackground.png')
export const SelectedUp = require('./images/selectedUp.svg').default
export const SelectedDown = require('./images/selectedDown.svg').default
export const AvatarDummy = require('./images/avatar.png').default
export const IshratLogo = require('./images/ishratLogo.svg').default
export const MintrioBackground = require('./images/mintrioBackground.png')
export const MintrioLogo = require('./images/mintrioLogo.svg').default
export const Check = require('./images/grey/check.png').default
export const MuhibAvatar = require('./images/muhibAvatar.png').default
export const HSYAvatar = require('./images/hsyAvatar.png').default
export const SaraAvatar = require('./images/saraAvatar.png').default
export const SanamAvatar = require('./images/sanamAvatar.png').default
export const purcahseDone = require('./images/purchaseDone.svg').default

// for cast screen
export const Sanam2 = require('./images/sanam2.svg').default
export const HSY2 = require('./images/hsy2.svg').default
export const Sara2 = require('./images/sara2.svg').default
export const Mustafa2 = require('./images/mustafa2.svg').default
export const Samoon2 = require('./images/samoon2.svg').default
export const Shabbir2 = require('./images/shabbir2.svg').default
export const Muhib2 = require('./images/muhib2.svg').default
export const Nayyer2 = require('./images/nayyer2.svg').default
export const Imam2 = require('./images/imam2.svg').default
export const Ali2 = require('./images/ali2.svg').default
export const Murtaza2 = require('./images/murtaza2.svg').default
export const MuhibMobile = require('./images/mobile/muhib.svg').default

export const SanamTitle = require('./images/sanamTitle.svg').default
export const HSYTitle = require('./images/hsyTitle.svg').default
export const SaraTitle = require('./images/saraTitle.svg').default
export const SamoonTitle = require('./images/samoonTitle.svg').default
export const ShabbirTitle = require('./images/shabbirTitle.svg').default
export const MuhibTitle = require('./images/muhibTitle.svg').default
export const NayyerTitle = require('./images/nayyerTitle.svg').default
export const ImamTitle = require('./images/imamTitle.svg').default
export const AliTitle = require('./images/aliTitle.svg').default
export const MustafaTitle = require('./images/mustafaTitle.svg').default
export const MurtazaTitle = require('./images/murtazaTitle.svg').default

export const HSYBackground = require('./images/hsyBackground.png').default
export const SanamBackground = require('./images/sanamBackground.png').default
export const SaraBackground = require('./images/saraBackground.png').default
export const SamoonBackground = require('./images/samoonBackground.png').default
export const ShabbirBackground = require('./images/shabbirBackground.png')
  .default
export const MuhibBackground = require('./images/muhibBackground.png').default
export const NayyerBackground = require('./images/nayyerBackground.png').default
export const ImamBackground = require('./images/imamBackground.png').default
export const AliBackground = require('./images/aliBackground.png').default
export const MustafaBackground = require('./images/mustafaBackground.png')
  .default
export const MurtazaBackground = require('./images/murtazaBackground.png')
  .default

// icons
export const Facebook = require('./icons/fb.svg').default
export const Twitter = require('./icons/twitter.svg').default
export const Instagram = require('./icons/instagram.svg').default
