import React, { useState } from 'react'
import { Modal } from '@material-ui/core'
import Countdown from 'react-countdown'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import './home.css'
import { ButtonComp, Footer } from '../../../components'
import { WatchTrailer } from './watchTrailer'
import { IshratLogo } from '../../../assets'
import { setIsReserveModal } from '../../../redux/modules/globalModals'

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <></>
  } else {
    return (
      <div className="timerMain">
        <div className="timerSub">
          <span className="time">{days}</span>
          <span className="timeTitle">DAYS</span>
        </div>
        <div className="timerSub">
          <span className="time">:</span>
          <span className="timeTitle" style={{ fontWeight: 600, fontSize: 15 }}>
            :
          </span>
        </div>
        <div className="timerSub">
          <span className="time">{hours}</span>
          <span className="timeTitle">HOURS</span>
        </div>
        <div className="timerSub">
          <span className="time">:</span>
          <span className="timeTitle" style={{ fontWeight: 600, fontSize: 15 }}>
            :
          </span>
        </div>
        <div className="timerSub">
          <span className="time">{minutes}</span>
          <span className="timeTitle">MINUTES</span>
        </div>
      </div>
    )
  }
}

function Home() {
  const [isVideoPlayerOpen, setIsVideoPlayerOpen] = useState(false)

  const dispatch = useDispatch()

  const User = useSelector((state) => state.user)

  const history = useHistory()

  const getEidDate = () => {
    var myDate = '19-07-2021'
    myDate = myDate.split('-')

    var newDate = new Date(myDate[2], myDate[1] - 1, myDate[0])
    return newDate.getTime()
  }

  const handleReserveNow = () => {
    if (User.isLogged)
      dispatch(setIsReserveModal({ isReserveModalVisible: true }))
    else history.push('/login')
  }

  return (
    <div className={'homePage'}>
      <div>
        <img src={IshratLogo} className="App-logo" alt="Ishrat Logo" />
      </div>
      <div className="trailer-button-container">
        <ButtonComp
          title="Watch trailer"
          backgroundColor={'rgba(255,255,255,0.22)'}
          borderColor={'transparent'}
          hoverBackgroundColor={'rgb(69,156,204)'}
          hoverBorderColor={'rgb(69,156,204)'}
          backdropFilter={'blur(44px)'}
          onClickHandler={() => setIsVideoPlayerOpen(true)}
          width={200}
          height={30}
          icon={<PlayArrowIcon style={{ paddingRight: 5 }} />}
        />
      </div>
      <div className="trailer-button-container">
        <ButtonComp
          title="Reserve Now"
          backgroundColor={'rgba(255,255,255,0.22)'}
          borderColor={'transparent'}
          hoverBackgroundColor={'rgb(69,156,204)'}
          hoverBorderColor={'rgb(69,156,204)'}
          backdropFilter={'blur(44px)'}
          onClickHandler={handleReserveNow}
          width={200}
          height={30}
        />
      </div>
      <p className="Streaming">STREAMING TO YOUR HOME IN</p>
      <Countdown date={getEidDate()} renderer={renderer} />
      <Footer />
      <Modal
        open={isVideoPlayerOpen}
        onClose={() => setIsVideoPlayerOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="trailerModal">
        <WatchTrailer />
      </Modal>
    </div>
  )
}

export default Home
