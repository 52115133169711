import { API, Auth } from 'aws-amplify'

export const processCheckout = async (payload, type) => {
  try {
    const params = {
      body: { payload, type },
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    }

    const { id } = await API.post('checkout', '/checkout', params)

    return id
  } catch (err) {
    throw err
  }
}
