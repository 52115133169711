/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPlansByQualities = /* GraphQL */ `
  query GetPlansByQualities($qualityIds: [ID]) {
    getPlansByQualities(qualityIds: $qualityIds) {
      id
      title
      status
      supported_devices {
        items {
          id
          supported_device_id
          plan_id
          supported_devices {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      video_quality_id
      video_quality {
        id
        title
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
export const getUserNotification = /* GraphQL */ `
  query GetUserNotification($id: ID!) {
    getUserNotification(id: $id) {
      id
      message
      user_id
      user {
        id
        email_address
        status
        createdAt
        updatedAt
      }
      status
      read
      generated_by
      start_date
      end_date
      createdAt
      updatedAt
    }
  }
`
export const listUserNotifications = /* GraphQL */ `
  query ListUserNotifications(
    $filter: ModelUserNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        user_id
        user {
          id
          email_address
          status
          createdAt
          updatedAt
        }
        status
        read
        generated_by
        start_date
        end_date
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getSiteNotification = /* GraphQL */ `
  query GetSiteNotification($id: ID!) {
    getSiteNotification(id: $id) {
      id
      message
      status
      generated_by
      country_id
      start_date
      end_date
      createdAt
      updatedAt
      country {
        id
        country_code
        country_name
        Currency
        status
        createdAt
        updatedAt
      }
    }
  }
`
export const listSiteNotifications = /* GraphQL */ `
  query ListSiteNotifications(
    $filter: ModelSiteNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSiteNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        status
        generated_by
        country_id
        start_date
        end_date
        createdAt
        updatedAt
        country {
          id
          country_code
          country_name
          Currency
          status
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      status
      ref_no
      payment_total
      disabled
      payment_type_id
      user_id
      user {
        id
        email_address
        status
        createdAt
        updatedAt
      }
      promo_code_id
      promo_code {
        id
        code
        reuse
        count
        status
        video_plan_id
        video_plan {
          id
          status
          plan_id
          plan {
            id
            title
            status
            supported_devices {
              items {
                id
                supported_device_id
                plan_id
                createdAt
                updatedAt
              }
              nextToken
            }
            video_quality_id
            video_quality {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          video_by_quality_id
          createdAt
          updatedAt
          video_by_quality {
            id
            hls_url
            dash_url
            status
            video_id
            video_quality_id
            video_quality {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              description
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              genres {
                nextToken
              }
              duration
              status
              release_date
              credits_start_timestamp
              video_tags {
                nextToken
              }
              language
              video_type_id
              video_type {
                id
                title
                status
                createdAt
                updatedAt
              }
              video_rating_id
              video_rating {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
          }
        }
        percentage_off
        country_id
        end_date
        promo_type_id
        promo_type {
          id
          name
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        country {
          id
          country_code
          country_name
          Currency
          status
          createdAt
          updatedAt
        }
      }
      purchased_at
      started_at
      expired_at
      expired
      twenty_five_percent_watched
      fifty_percent_watched
      seventy_five_percent_watched
      hundred_percent_watched
      video_plan_id
      video_plan {
        id
        status
        plan_id
        plan {
          id
          title
          status
          supported_devices {
            items {
              id
              supported_device_id
              plan_id
              supported_devices {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          video_quality_id
          video_quality {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        video_by_quality_id
        createdAt
        updatedAt
        video_by_quality {
          id
          hls_url
          dash_url
          status
          video_id
          video_quality_id
          video_quality {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          video {
            id
            title
            description
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              items {
                id
                genre_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            duration
            status
            release_date
            credits_start_timestamp
            video_tags {
              items {
                id
                tag_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            video_rating_id
            video_rating {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
        }
      }
      createdAt
      updatedAt
      payment_type {
        id
        name
        disabled
        country_id
        createdAt
        updatedAt
        country {
          id
          country_code
          country_name
          Currency
          status
          createdAt
          updatedAt
        }
      }
    }
  }
`
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        ref_no
        payment_total
        disabled
        payment_type_id
        user_id
        user {
          id
          email_address
          status
          createdAt
          updatedAt
        }
        promo_code_id
        promo_code {
          id
          code
          reuse
          count
          status
          video_plan_id
          video_plan {
            id
            status
            plan_id
            plan {
              id
              title
              status
              supported_devices {
                nextToken
              }
              video_quality_id
              video_quality {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            video_by_quality_id
            createdAt
            updatedAt
            video_by_quality {
              id
              hls_url
              dash_url
              status
              video_id
              video_quality_id
              video_quality {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              video {
                id
                title
                description
                thumbnail_sm
                thumbnail_md
                thumbnail_lg
                duration
                status
                release_date
                credits_start_timestamp
                language
                video_type_id
                video_rating_id
                createdAt
                updatedAt
              }
            }
          }
          percentage_off
          country_id
          end_date
          promo_type_id
          promo_type {
            id
            name
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          country {
            id
            country_code
            country_name
            Currency
            status
            createdAt
            updatedAt
          }
        }
        purchased_at
        started_at
        expired_at
        expired
        twenty_five_percent_watched
        fifty_percent_watched
        seventy_five_percent_watched
        hundred_percent_watched
        video_plan_id
        video_plan {
          id
          status
          plan_id
          plan {
            id
            title
            status
            supported_devices {
              items {
                id
                supported_device_id
                plan_id
                createdAt
                updatedAt
              }
              nextToken
            }
            video_quality_id
            video_quality {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          video_by_quality_id
          createdAt
          updatedAt
          video_by_quality {
            id
            hls_url
            dash_url
            status
            video_id
            video_quality_id
            video_quality {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              description
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              genres {
                nextToken
              }
              duration
              status
              release_date
              credits_start_timestamp
              video_tags {
                nextToken
              }
              language
              video_type_id
              video_type {
                id
                title
                status
                createdAt
                updatedAt
              }
              video_rating_id
              video_rating {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getViewLog = /* GraphQL */ `
  query GetViewLog($id: ID!) {
    getViewLog(id: $id) {
      id
      order_id
      order {
        id
        status
        ref_no
        payment_total
        disabled
        payment_type_id
        user_id
        user {
          id
          email_address
          status
          createdAt
          updatedAt
        }
        promo_code_id
        promo_code {
          id
          code
          reuse
          count
          status
          video_plan_id
          video_plan {
            id
            status
            plan_id
            plan {
              id
              title
              status
              supported_devices {
                nextToken
              }
              video_quality_id
              video_quality {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            video_by_quality_id
            createdAt
            updatedAt
            video_by_quality {
              id
              hls_url
              dash_url
              status
              video_id
              video_quality_id
              video_quality {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              video {
                id
                title
                description
                thumbnail_sm
                thumbnail_md
                thumbnail_lg
                duration
                status
                release_date
                credits_start_timestamp
                language
                video_type_id
                video_rating_id
                createdAt
                updatedAt
              }
            }
          }
          percentage_off
          country_id
          end_date
          promo_type_id
          promo_type {
            id
            name
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          country {
            id
            country_code
            country_name
            Currency
            status
            createdAt
            updatedAt
          }
        }
        purchased_at
        started_at
        expired_at
        expired
        twenty_five_percent_watched
        fifty_percent_watched
        seventy_five_percent_watched
        hundred_percent_watched
        video_plan_id
        video_plan {
          id
          status
          plan_id
          plan {
            id
            title
            status
            supported_devices {
              items {
                id
                supported_device_id
                plan_id
                createdAt
                updatedAt
              }
              nextToken
            }
            video_quality_id
            video_quality {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          video_by_quality_id
          createdAt
          updatedAt
          video_by_quality {
            id
            hls_url
            dash_url
            status
            video_id
            video_quality_id
            video_quality {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              description
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              genres {
                nextToken
              }
              duration
              status
              release_date
              credits_start_timestamp
              video_tags {
                nextToken
              }
              language
              video_type_id
              video_type {
                id
                title
                status
                createdAt
                updatedAt
              }
              video_rating_id
              video_rating {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
          }
        }
        createdAt
        updatedAt
        payment_type {
          id
          name
          disabled
          country_id
          createdAt
          updatedAt
          country {
            id
            country_code
            country_name
            Currency
            status
            createdAt
            updatedAt
          }
        }
      }
      status
      last_timestamp
      minutes_streamed
      started_at
      user_id
      user {
        id
        email_address
        status
        createdAt
        updatedAt
      }
      video_by_quality_id
      supported_device_id
      supported_device {
        id
        title
        status
        createdAt
        updatedAt
      }
      mac_id
      bandwidth_used
      createdAt
      updatedAt
      video_by_quality {
        id
        hls_url
        dash_url
        status
        video_id
        video_quality_id
        video_quality {
          id
          title
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        video {
          id
          title
          description
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          genres {
            items {
              id
              genre_id
              video_id
              genre {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          duration
          status
          release_date
          credits_start_timestamp
          video_tags {
            items {
              id
              tag_id
              video_id
              tag {
                id
                name
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          language
          video_type_id
          video_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          video_rating_id
          video_rating {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`
export const listViewLogs = /* GraphQL */ `
  query ListViewLogs(
    $filter: ModelViewLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listViewLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        order_id
        order {
          id
          status
          ref_no
          payment_total
          disabled
          payment_type_id
          user_id
          user {
            id
            email_address
            status
            createdAt
            updatedAt
          }
          promo_code_id
          promo_code {
            id
            code
            reuse
            count
            status
            video_plan_id
            video_plan {
              id
              status
              plan_id
              plan {
                id
                title
                status
                video_quality_id
                createdAt
                updatedAt
              }
              video_by_quality_id
              createdAt
              updatedAt
              video_by_quality {
                id
                hls_url
                dash_url
                status
                video_id
                video_quality_id
                createdAt
                updatedAt
              }
            }
            percentage_off
            country_id
            end_date
            promo_type_id
            promo_type {
              id
              name
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            country {
              id
              country_code
              country_name
              Currency
              status
              createdAt
              updatedAt
            }
          }
          purchased_at
          started_at
          expired_at
          expired
          twenty_five_percent_watched
          fifty_percent_watched
          seventy_five_percent_watched
          hundred_percent_watched
          video_plan_id
          video_plan {
            id
            status
            plan_id
            plan {
              id
              title
              status
              supported_devices {
                nextToken
              }
              video_quality_id
              video_quality {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            video_by_quality_id
            createdAt
            updatedAt
            video_by_quality {
              id
              hls_url
              dash_url
              status
              video_id
              video_quality_id
              video_quality {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              video {
                id
                title
                description
                thumbnail_sm
                thumbnail_md
                thumbnail_lg
                duration
                status
                release_date
                credits_start_timestamp
                language
                video_type_id
                video_rating_id
                createdAt
                updatedAt
              }
            }
          }
          createdAt
          updatedAt
          payment_type {
            id
            name
            disabled
            country_id
            createdAt
            updatedAt
            country {
              id
              country_code
              country_name
              Currency
              status
              createdAt
              updatedAt
            }
          }
        }
        status
        last_timestamp
        minutes_streamed
        started_at
        user_id
        user {
          id
          email_address
          status
          createdAt
          updatedAt
        }
        video_by_quality_id
        supported_device_id
        supported_device {
          id
          title
          status
          createdAt
          updatedAt
        }
        mac_id
        bandwidth_used
        createdAt
        updatedAt
        video_by_quality {
          id
          hls_url
          dash_url
          status
          video_id
          video_quality_id
          video_quality {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          video {
            id
            title
            description
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              items {
                id
                genre_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            duration
            status
            release_date
            credits_start_timestamp
            video_tags {
              items {
                id
                tag_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            video_rating_id
            video_rating {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
        }
      }
      nextToken
    }
  }
`
export const getSupportedDevice = /* GraphQL */ `
  query GetSupportedDevice($id: ID!) {
    getSupportedDevice(id: $id) {
      id
      title
      status
      createdAt
      updatedAt
    }
  }
`
export const listSupportedDevices = /* GraphQL */ `
  query ListSupportedDevices(
    $filter: ModelSupportedDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSupportedDevices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getPlan = /* GraphQL */ `
  query GetPlan($id: ID!) {
    getPlan(id: $id) {
      id
      title
      status
      supported_devices {
        items {
          id
          supported_device_id
          plan_id
          supported_devices {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      video_quality_id
      video_quality {
        id
        title
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
export const listPlans = /* GraphQL */ `
  query ListPlans(
    $filter: ModelPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        status
        supported_devices {
          items {
            id
            supported_device_id
            plan_id
            supported_devices {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        video_quality_id
        video_quality {
          id
          title
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getVideoPlan = /* GraphQL */ `
  query GetVideoPlan($id: ID!) {
    getVideoPlan(id: $id) {
      id
      status
      plan_id
      plan {
        id
        title
        status
        supported_devices {
          items {
            id
            supported_device_id
            plan_id
            supported_devices {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        video_quality_id
        video_quality {
          id
          title
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      video_by_quality_id
      createdAt
      updatedAt
      video_by_quality {
        id
        hls_url
        dash_url
        status
        video_id
        video_quality_id
        video_quality {
          id
          title
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        video {
          id
          title
          description
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          genres {
            items {
              id
              genre_id
              video_id
              genre {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          duration
          status
          release_date
          credits_start_timestamp
          video_tags {
            items {
              id
              tag_id
              video_id
              tag {
                id
                name
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          language
          video_type_id
          video_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          video_rating_id
          video_rating {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`
export const listVideoPlans = /* GraphQL */ `
  query ListVideoPlans(
    $filter: ModelVideoPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        plan_id
        plan {
          id
          title
          status
          supported_devices {
            items {
              id
              supported_device_id
              plan_id
              supported_devices {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          video_quality_id
          video_quality {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        video_by_quality_id
        createdAt
        updatedAt
        video_by_quality {
          id
          hls_url
          dash_url
          status
          video_id
          video_quality_id
          video_quality {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          video {
            id
            title
            description
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              items {
                id
                genre_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            duration
            status
            release_date
            credits_start_timestamp
            video_tags {
              items {
                id
                tag_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            video_rating_id
            video_rating {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
        }
      }
      nextToken
    }
  }
`
export const getPricing = /* GraphQL */ `
  query GetPricing($id: ID!) {
    getPricing(id: $id) {
      id
      country_id
      price
      status
      video_plan_id
      video_plan {
        id
        status
        plan_id
        plan {
          id
          title
          status
          supported_devices {
            items {
              id
              supported_device_id
              plan_id
              supported_devices {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          video_quality_id
          video_quality {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        video_by_quality_id
        createdAt
        updatedAt
        video_by_quality {
          id
          hls_url
          dash_url
          status
          video_id
          video_quality_id
          video_quality {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          video {
            id
            title
            description
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              items {
                id
                genre_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            duration
            status
            release_date
            credits_start_timestamp
            video_tags {
              items {
                id
                tag_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            video_rating_id
            video_rating {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
        }
      }
      video_id
      createdAt
      updatedAt
      country {
        id
        country_code
        country_name
        Currency
        status
        createdAt
        updatedAt
      }
      video {
        id
        title
        description
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        duration
        status
        release_date
        credits_start_timestamp
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        video_rating_id
        video_rating {
          id
          title
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`
export const listPricings = /* GraphQL */ `
  query ListPricings(
    $filter: ModelPricingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPricings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        country_id
        price
        status
        video_plan_id
        video_plan {
          id
          status
          plan_id
          plan {
            id
            title
            status
            supported_devices {
              items {
                supported_devices {
                  id
                  title
                  status
                }
                id
                supported_device_id
                plan_id
                createdAt
                updatedAt
              }
              nextToken
            }
            video_quality_id
            video_quality {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          video_by_quality_id
          createdAt
          updatedAt
        }
        video_id
        createdAt
        updatedAt
        country {
          id
          country_code
          country_name
          Currency
          status
          createdAt
          updatedAt
        }
        video {
          id
          title
          description
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          genres {
            items {
              id
              genre_id
              video_id
              genre {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          duration
          status
          release_date
          credits_start_timestamp
          video_tags {
            items {
              id
              tag_id
              video_id
              tag {
                id
                name
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          language
          video_type_id
          video_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          video_rating_id
          video_rating {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`
export const getPromoType = /* GraphQL */ `
  query GetPromoType($id: ID!) {
    getPromoType(id: $id) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`
export const listPromoTypes = /* GraphQL */ `
  query ListPromoTypes(
    $filter: ModelPromoTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPromoTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getPromoCode = /* GraphQL */ `
  query GetPromoCode($id: ID!) {
    getPromoCode(id: $id) {
      id
      code
      reuse
      count
      status
      video_plan_id
      video_plan {
        id
        status
        plan_id
        plan {
          id
          title
          status
          supported_devices {
            items {
              id
              supported_device_id
              plan_id
              supported_devices {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          video_quality_id
          video_quality {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        video_by_quality_id
        createdAt
        updatedAt
        video_by_quality {
          id
          hls_url
          dash_url
          status
          video_id
          video_quality_id
          video_quality {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          video {
            id
            title
            description
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              items {
                id
                genre_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            duration
            status
            release_date
            credits_start_timestamp
            video_tags {
              items {
                id
                tag_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            video_rating_id
            video_rating {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
        }
      }
      percentage_off
      country_id
      end_date
      promo_type_id
      promo_type {
        id
        name
        description
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      country {
        id
        country_code
        country_name
        Currency
        status
        createdAt
        updatedAt
      }
    }
  }
`
export const listPromoCodes = /* GraphQL */ `
  query ListPromoCodes(
    $filter: ModelPromoCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPromoCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        reuse
        count
        status
        video_plan_id
        video_plan {
          id
          status
          plan_id
          plan {
            id
            title
            status
            supported_devices {
              items {
                id
                supported_device_id
                plan_id
                createdAt
                updatedAt
              }
              nextToken
            }
            video_quality_id
            video_quality {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          video_by_quality_id
          createdAt
          updatedAt
          video_by_quality {
            id
            hls_url
            dash_url
            status
            video_id
            video_quality_id
            video_quality {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              description
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              genres {
                nextToken
              }
              duration
              status
              release_date
              credits_start_timestamp
              video_tags {
                nextToken
              }
              language
              video_type_id
              video_type {
                id
                title
                status
                createdAt
                updatedAt
              }
              video_rating_id
              video_rating {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
          }
        }
        percentage_off
        country_id
        end_date
        promo_type_id
        promo_type {
          id
          name
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        country {
          id
          country_code
          country_name
          Currency
          status
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`
export const getSupportedCaption = /* GraphQL */ `
  query GetSupportedCaption($id: ID!, $video_id: ID!) {
    getSupportedCaption(id: $id, video_id: $video_id) {
      id
      caption
      url
      status
      video_id
      createdAt
      updatedAt
    }
  }
`
export const listSupportedCaptions = /* GraphQL */ `
  query ListSupportedCaptions(
    $id: ID
    $video_id: ModelIDKeyConditionInput
    $filter: ModelSupportedCaptionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSupportedCaptions(
      id: $id
      video_id: $video_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        caption
        url
        status
        video_id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getTrailer = /* GraphQL */ `
  query GetTrailer($id: ID!, $video_id: ID!) {
    getTrailer(id: $id, video_id: $video_id) {
      id
      hls_url
      dash_url
      status
      thumbnail_url
      video_id
      createdAt
      updatedAt
      video {
        id
        title
        description
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        duration
        status
        release_date
        credits_start_timestamp
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        video_rating_id
        video_rating {
          id
          title
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`
export const listTrailers = /* GraphQL */ `
  query ListTrailers(
    $id: ID
    $video_id: ModelIDKeyConditionInput
    $filter: ModelTrailerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTrailers(
      id: $id
      video_id: $video_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        hls_url
        dash_url
        status
        thumbnail_url
        video_id
        createdAt
        updatedAt
        video {
          id
          title
          description
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          genres {
            items {
              id
              genre_id
              video_id
              genre {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          duration
          status
          release_date
          credits_start_timestamp
          video_tags {
            items {
              id
              tag_id
              video_id
              tag {
                id
                name
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          language
          video_type_id
          video_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          video_rating_id
          video_rating {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`
export const getTrailerView = /* GraphQL */ `
  query GetTrailerView($id: ID!) {
    getTrailerView(id: $id) {
      id
      trailer_id
      user_id
      watched_at
      user {
        id
        email_address
        status
        createdAt
        updatedAt
      }
      trailer {
        id
        hls_url
        dash_url
        status
        thumbnail_url
        video_id
        createdAt
        updatedAt
        video {
          id
          title
          description
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          genres {
            items {
              id
              genre_id
              video_id
              genre {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          duration
          status
          release_date
          credits_start_timestamp
          video_tags {
            items {
              id
              tag_id
              video_id
              tag {
                id
                name
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          language
          video_type_id
          video_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          video_rating_id
          video_rating {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const listTrailerViews = /* GraphQL */ `
  query ListTrailerViews(
    $filter: ModelTrailerViewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrailerViews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        trailer_id
        user_id
        watched_at
        user {
          id
          email_address
          status
          createdAt
          updatedAt
        }
        trailer {
          id
          hls_url
          dash_url
          status
          thumbnail_url
          video_id
          createdAt
          updatedAt
          video {
            id
            title
            description
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              items {
                id
                genre_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            duration
            status
            release_date
            credits_start_timestamp
            video_tags {
              items {
                id
                tag_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            video_rating_id
            video_rating {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email_address
      status
      createdAt
      updatedAt
    }
  }
`
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email_address
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getUserActivityLog = /* GraphQL */ `
  query GetUserActivityLog($id: ID!, $user_id: ID!) {
    getUserActivityLog(id: $id, user_id: $user_id) {
      id
      action_id
      action {
        id
        description
        status
        createdAt
        updatedAt
      }
      user_id
      user {
        id
        email_address
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
export const listUserActivityLogs = /* GraphQL */ `
  query ListUserActivityLogs(
    $id: ID
    $user_id: ModelIDKeyConditionInput
    $filter: ModelUserActivityLogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserActivityLogs(
      id: $id
      user_id: $user_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        action_id
        action {
          id
          description
          status
          createdAt
          updatedAt
        }
        user_id
        user {
          id
          email_address
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getUserActivityAction = /* GraphQL */ `
  query GetUserActivityAction($id: ID!) {
    getUserActivityAction(id: $id) {
      id
      description
      status
      createdAt
      updatedAt
    }
  }
`
export const listUserActivityActions = /* GraphQL */ `
  query ListUserActivityActions(
    $filter: ModelUserActivityActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserActivityActions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getAvatar = /* GraphQL */ `
  query GetAvatar($id: ID!) {
    getAvatar(id: $id) {
      id
      name
      url
      status
      createdAt
      updatedAt
    }
  }
`
export const listAvatars = /* GraphQL */ `
  query ListAvatars(
    $filter: ModelAvatarFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAvatars(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        url
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      id
      name
      videos {
        items {
          id
          tag_id
          video_id
          tag {
            id
            name
            videos {
              items {
                id
                tag_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const listTags = /* GraphQL */ `
  query ListTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        videos {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getGenre = /* GraphQL */ `
  query GetGenre($id: ID!) {
    getGenre(id: $id) {
      id
      title
      status
      videos {
        items {
          id
          genre_id
          video_id
          genre {
            id
            title
            status
            videos {
              items {
                id
                genre_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const listGenres = /* GraphQL */ `
  query ListGenres(
    $filter: ModelGenreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGenres(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        status
        videos {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getVideoType = /* GraphQL */ `
  query GetVideoType($id: ID!) {
    getVideoType(id: $id) {
      id
      title
      status
      createdAt
      updatedAt
    }
  }
`
export const listVideoTypes = /* GraphQL */ `
  query ListVideoTypes(
    $filter: ModelVideoTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getVideoQuality = /* GraphQL */ `
  query GetVideoQuality($id: ID!) {
    getVideoQuality(id: $id) {
      id
      title
      status
      createdAt
      updatedAt
    }
  }
`
export const listVideoQualitys = /* GraphQL */ `
  query ListVideoQualitys(
    $filter: ModelVideoQualityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoQualitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getCastCrewType = /* GraphQL */ `
  query GetCastCrewType($id: ID!) {
    getCastCrewType(id: $id) {
      id
      title
      status
      createdAt
      updatedAt
    }
  }
`
export const listCastCrewTypes = /* GraphQL */ `
  query ListCastCrewTypes(
    $filter: ModelCastCrewTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCastCrewTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getCastCrew = /* GraphQL */ `
  query GetCastCrew($id: ID!) {
    getCastCrew(id: $id) {
      id
      first_name
      last_name
      nick_name
      description
      image_url
      status
      createdAt
      updatedAt
    }
  }
`
export const listCastCrews = /* GraphQL */ `
  query ListCastCrews(
    $filter: ModelCastCrewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCastCrews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        first_name
        last_name
        nick_name
        description
        image_url
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getVideoCastCrew = /* GraphQL */ `
  query GetVideoCastCrew($id: ID!, $video_id: ID!) {
    getVideoCastCrew(id: $id, video_id: $video_id) {
      id
      video_id
      cast_crew_id
      status
      cast_crew_type_id
      cast_crew_type {
        id
        title
        status
        createdAt
        updatedAt
      }
      display
      createdAt
      updatedAt
    }
  }
`
export const listVideoCastCrews = /* GraphQL */ `
  query ListVideoCastCrews(
    $id: ID
    $video_id: ModelIDKeyConditionInput
    $filter: ModelVideoCastCrewFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVideoCastCrews(
      id: $id
      video_id: $video_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        video_id
        cast_crew_id
        status
        cast_crew_type_id
        cast_crew_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        display
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getVideoRating = /* GraphQL */ `
  query GetVideoRating($id: ID!) {
    getVideoRating(id: $id) {
      id
      title
      status
      createdAt
      updatedAt
    }
  }
`
export const listVideoRatings = /* GraphQL */ `
  query ListVideoRatings(
    $filter: ModelVideoRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoRatings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getVideoRatingsByUser = /* GraphQL */ `
  query GetVideoRatingsByUser($id: ID!) {
    getVideoRatingsByUser(id: $id) {
      id
      video_id
      rating
      user_id
      user {
        id
        email_address
        status
        createdAt
        updatedAt
      }
      rating_on_timestamp
      createdAt
      updatedAt
      video {
        id
        title
        description
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        duration
        status
        release_date
        credits_start_timestamp
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        video_rating_id
        video_rating {
          id
          title
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`
export const listVideoRatingsByUsers = /* GraphQL */ `
  query ListVideoRatingsByUsers(
    $filter: ModelVideoRatingsByUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoRatingsByUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        video_id
        rating
        user_id
        user {
          id
          email_address
          status
          createdAt
          updatedAt
        }
        rating_on_timestamp
        createdAt
        updatedAt
        video {
          id
          title
          description
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          genres {
            items {
              id
              genre_id
              video_id
              genre {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          duration
          status
          release_date
          credits_start_timestamp
          video_tags {
            items {
              id
              tag_id
              video_id
              tag {
                id
                name
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          language
          video_type_id
          video_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          video_rating_id
          video_rating {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`
export const planByQuality = /* GraphQL */ `
  query PlanByQuality(
    $video_quality_id: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    planByQuality(
      video_quality_id: $video_quality_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        status
        supported_devices {
          items {
            id
            supported_device_id
            plan_id
            supported_devices {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        video_quality_id
        video_quality {
          id
          title
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const listCountrys = /* GraphQL */ `
  query ListCountrys(
    $filter: ModelCountryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCountrys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        country_code
        country_name
        Currency
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getCountry = /* GraphQL */ `
  query GetCountry($id: ID!) {
    getCountry(id: $id) {
      id
      country_code
      country_name
      Currency
      status
      createdAt
      updatedAt
    }
  }
`
export const getPaymentProcessingStatus = /* GraphQL */ `
  query GetPaymentProcessingStatus($id: ID!) {
    getPaymentProcessingStatus(id: $id) {
      id
      status
      createdAt
      updatedAt
    }
  }
`
export const listPaymentProcessingStatuss = /* GraphQL */ `
  query ListPaymentProcessingStatuss(
    $filter: ModelPaymentProcessingStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaymentProcessingStatuss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const listPaymentTypes = /* GraphQL */ `
  query ListPaymentTypes(
    $id: ID
    $country_id: ModelIDKeyConditionInput
    $filter: ModelPaymentTypeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPaymentTypes(
      id: $id
      country_id: $country_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        disabled
        country_id
        createdAt
        updatedAt
        country {
          id
          country_code
          country_name
          Currency
          status
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`
export const getPaymentType = /* GraphQL */ `
  query GetPaymentType($id: ID!, $country_id: ID!) {
    getPaymentType(id: $id, country_id: $country_id) {
      id
      name
      disabled
      country_id
      createdAt
      updatedAt
      country {
        id
        country_code
        country_name
        Currency
        status
        createdAt
        updatedAt
      }
    }
  }
`
export const listVideos = /* GraphQL */ `
  query ListVideos(
    $filter: ModelVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        duration
        status
        release_date
        credits_start_timestamp
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        video_rating_id
        video_rating {
          id
          title
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getVideo = /* GraphQL */ `
  query GetVideo($id: ID!) {
    getVideo(id: $id) {
      id
      title
      description
      thumbnail_sm
      thumbnail_md
      thumbnail_lg
      genres {
        items {
          id
          genre_id
          video_id
          genre {
            id
            title
            status
            videos {
              items {
                id
                genre_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      duration
      status
      release_date
      credits_start_timestamp
      video_tags {
        items {
          id
          tag_id
          video_id
          tag {
            id
            name
            videos {
              items {
                id
                tag_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      language
      video_type_id
      video_type {
        id
        title
        status
        createdAt
        updatedAt
      }
      video_rating_id
      video_rating {
        id
        title
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
export const listVideoByQualitys = /* GraphQL */ `
  query ListVideoByQualitys(
    $id: ID
    $video_id: ModelIDKeyConditionInput
    $filter: ModelVideoByQualityFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVideoByQualitys(
      id: $id
      video_id: $video_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        hls_url
        dash_url
        status
        video_id
        video_quality_id
        video_quality {
          id
          title
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        video {
          id
          title
          description
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          genres {
            items {
              id
              genre_id
              video_id
              genre {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          duration
          status
          release_date
          credits_start_timestamp
          video_tags {
            items {
              id
              tag_id
              video_id
              tag {
                id
                name
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          language
          video_type_id
          video_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          video_rating_id
          video_rating {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`
export const getVideoByQuality = /* GraphQL */ `
  query GetVideoByQuality($id: ID!, $video_id: ID!) {
    getVideoByQuality(id: $id, video_id: $video_id) {
      id
      hls_url
      dash_url
      status
      video_id
      video_quality_id
      video_quality {
        id
        title
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      video {
        id
        title
        description
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        duration
        status
        release_date
        credits_start_timestamp
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        video_rating_id
        video_rating {
          id
          title
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`
