import React, { useEffect, useReducer, useState } from 'react'
import { Grid, Select, MenuItem } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import ButtonComp from '../../../components/Button'
import { FormInput, Header, SelectInput } from '../../../components'
import './friendsDetail.css'
import { useStyles } from './styles'
import { Countries } from '../../../lib/constant'
import {
  PrimaryButton,
  PrimaryText,
  SecondaryButton
} from '../../../styles/colors'

const FriendsDetail = () => {
  const history = useHistory()

  const classes = useStyles()

  const [isDisabled, setIsDisabled] = useState(true)
  const [{ name, email, country }, setDetail] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { name: '', email: '', country: 'Country' }
  )

  useEffect(() => {
    if (
      name.trim() !== '' &&
      email.trim() !== '' &&
      country.trim() !== 'Country' &&
      country.trim() !== ''
    )
      setIsDisabled(false)
    else setIsDisabled(true)
  }, [name, email, country])

  const navigateToPlan = () => history.push('/checkout')

  const handleInput = (event) => {
    const { name, value } = event.target

    setDetail({ [name]: value })
  }

  return (
    <div className="pageWithBackgound">
      <Header title="ENTER YOUR FRIEND'S DETAILS" />
      <div className="fullContainer">
        <div className={'flex marginContainer'}>
          <Grid container className="nameInputGrid">
            <Grid md={4} lg={4} xl={4} sm={0} xs={0}></Grid>
            <Grid md={4} lg={4} xl={4} sm={12} xs={12}>
              <FormInput
                placeholder="Name"
                id="name"
                name="name"
                value={name}
                onChangeHandler={handleInput}
              />
            </Grid>
          </Grid>
          <Grid container className="emailInputGrid">
            <Grid md={4} lg={4} xl={4} sm={0} xs={0}></Grid>
            <Grid md={4} lg={4} xl={4} sm={12} xs={12}>
              <FormInput
                placeholder="Email Address"
                id="email"
                name="email"
                value={email}
                onChangeHandler={handleInput}
              />
            </Grid>
          </Grid>
          <Grid container className="emailInputGrid">
            <Grid md={4} lg={4} xl={4} sm={0} xs={0}></Grid>
            <Grid md={4} lg={4} xl={4} sm={12} xs={12}>
              <Select
                inputProps={{
                  classes: {
                    icon: classes.icon
                  }
                }}
                className={
                  country == 'Country' ? classes.unselected : classes.selected
                }
                id="country"
                name="country"
                input={<SelectInput />}
                value={country}
                onChange={handleInput}>
                <MenuItem value={'Country'}>Country</MenuItem>
                {Countries.map((item) => {
                  return <MenuItem value={item}>{item}</MenuItem>
                })}
              </Select>
              <p className="friends-detail-description">
                The coupon code will only be usable in the selected country.
                Please make sure you have the correct one selected.
              </p>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="friendDetailFooter">
        <ButtonComp
          onClickHandler={navigateToPlan}
          backgroundColor={isDisabled ? SecondaryButton : PrimaryButton}
          disabled={false}
          title={'CONTINUE'}
          disabled={isDisabled}
          titleColor={PrimaryText}
          width={300}
        />
      </div>
    </div>
  )
}

export default FriendsDetail
