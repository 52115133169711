import React from 'react'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { IconButton } from '@material-ui/core'

import './modalHeader.css'

const ModalHeader = ({ title, closeModal }) => {
  return (
    <div className="modalHeaderContainer">
      {title}
      <div className="backIconContainer">
        <IconButton size={'small'} onClick={() => closeModal()}>
          <ArrowBackIcon className="backIconColor" />
        </IconButton>
      </div>
    </div>
  )
}

export default ModalHeader
