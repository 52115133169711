import React, { useState } from 'react'

import { IshratLogo } from '../../../assets'
import './Cast.css'
import CastDetail from './CastDetails'
import Footer from './footer'
import { CastData } from '../../../lib/constant'

const Cast = () => {
  const [primary, setPrimary] = useState(0)
  const [secondary, setSecondary] = useState(1)

  const handleNext = () => {
    if (primary !== 10) {
      setSecondary(primary)
      setPrimary((prevState) => prevState + 1)
    } else {
      setPrimary(0)
      setSecondary(10)
    }
  }

  const handlePrevious = () => {
    if (primary !== 0) {
      setSecondary(primary)
      setPrimary((prevState) => prevState - 1)
    } else {
      setPrimary(10)
      setSecondary(0)
    }
  }

  return (
    <div
      className="castContainer"
      style={{
        backgroundImage: `url(${CastData[primary].backgroundPicture})`
      }}>
      <div className="rightLogo">
        <img src={IshratLogo} className="ishratLogoPic" />
      </div>

      <div className="blurredLayer"></div>

      <div className="gradientLayer"></div>
      {CastData.map((item, index) => {
        return (
          <CastDetail
            item={item}
            index={index}
            primary={primary}
            secondary={secondary}
          />
        )
      })}
      <Footer handleNext={handleNext} handlePrevious={handlePrevious} />
    </div>
  )
}

export default Cast
