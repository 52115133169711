import React, { useRef, useState } from 'react'
import {
  TabletAndroid,
  Smartphone,
  Laptop,
  DesktopMac,
  Cast
} from '@material-ui/icons'
import { Grid, Modal } from '@material-ui/core'
import { useMediaPredicate } from 'react-media-hook'
import { Collapse } from 'react-collapse'

import {
  SelectedPlan as SelectedPlanColor,
  PrimaryText,
  SecondaryText
} from '../../../styles/colors'
import { toonifyImage, profileImage } from '../../../assets'

const PlanItem = ({
  item,
  handlePlanSelection,
  handleToonifyClick,
  clicked,
  selectedPlan,
  codeApplied,
  showToonify,
  hovering,
  handleHovering,
  opendPlan,
  handleCollapse
}) => {
  const imageRef = useRef(null)

  const lessThan700 = useMediaPredicate('(max-width: 650px)')
  const greaterThan1024 = useMediaPredicate('(min-width: 1300px)')

  const [openToonifyModal, setOpnToonifyModal] = useState(false)

  const renderMobileView = () => {
    return (
      <div className="mobileView" style={{ width: '260px' }}>
        <div
          role="presentation"
          className={`planContainer ${
            selectedPlan && selectedPlan.id === item.id
              ? 'selectedPlanBorder'
              : 'unSelectedPlanBorder'
          }`}
          onClick={() => {
            handleCollapse(item.id)
            handlePlanSelection(item)
          }}>
          {(selectedPlan && selectedPlan.id === item.id) ||
          opendPlan === item.id ? (
            <div
              className={
                selectedPlan && selectedPlan.id === item.id
                  ? 'mobilePlanName planNameContainer-selected'
                  : 'mobilePlanName planNameContainer'
              }>
              {item.planName}
            </div>
          ) : (
            <div className="closedPlan">
              <div className="closedPlanName">{item.planName}</div>
              {codeApplied ? (
                <div className="couponAppliedText">
                  <span className="couponAppliedPrice">{item.price}</span>
                  <span>{item.price - item.price * 0.2}</span>
                </div>
              ) : (
                <div className="withoutCouponText">{item.price}</div>
              )}
            </div>
          )}

          <Collapse isOpened={opendPlan === item.id ? true : false}>
            {item.toonify && (
              <div
                role={'presentation'}
                onClick={() => setOpnToonifyModal(true)}
                className={
                  showToonify
                    ? 'mobileToonifyContainerDark'
                    : 'mobileToonifyContainer'
                }>
                TOONIFY YOURSELF IN GAME
              </div>
            )}
            <div>
              <div
                className={'mobilePriceContainer'}
                style={{
                  textDecoration: codeApplied ? 'line-through' : 'none',
                  color: PrimaryText,
                  fontSize: codeApplied ? 18 : 24
                }}>
                {`${item.currency} ${item.price}`}
              </div>
              {codeApplied && (
                <div className={'priceContainer'} style={{ marginTop: 10 }}>
                  {`${item.currency} ${item.price - item.price * 0.2}`}
                </div>
              )}
              <div
                className={
                  selectedPlan && selectedPlan.id === item.id
                    ? 'mobileQualityContainer-selected'
                    : 'mobileQualityContainer'
                }>
                {item.description}
              </div>

              <div
                className={
                  selectedPlan && selectedPlan.id === item.id
                    ? 'mobileQualityContainer-selected'
                    : 'mobileQualityContainer'
                }>
                {item.maxResolution}
              </div>
              <div
                style={{
                  fontSize: 16,
                  fontWeight: 700,
                  color: PrimaryText,
                  paddingTop: 0
                }}
                className={
                  selectedPlan && selectedPlan.id === item.id
                    ? 'qualityContainer-selected'
                    : 'qualityContainer'
                }>
                Watch on your
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingBottom: 10
                }}>
                {item.supportedDevices.tablet && (
                  <span className={'deviceContainer'}>
                    <TabletAndroid
                      style={{ fontSize: 19, color: PrimaryText }}
                    />
                    <span
                      style={{
                        fontSize: 11,
                        color: PrimaryText,
                        fontWeight: 600
                      }}
                      className="deviceTitle">
                      Tablet
                    </span>
                  </span>
                )}
                {item.supportedDevices.mobile && (
                  <span className={'deviceContainer'}>
                    <Smartphone style={{ fontSize: 19, color: PrimaryText }} />
                    <span
                      style={{
                        fontSize: 11,
                        color: PrimaryText,
                        fontWeight: 600
                      }}
                      className="deviceTitle">
                      Mobile
                    </span>
                  </span>
                )}

                {item.supportedDevices.tv && (
                  <span className={'deviceContainer'}>
                    <Cast style={{ fontSize: 19, color: PrimaryText }} />
                    <span
                      style={{
                        fontSize: 11,
                        color: PrimaryText,
                        fontWeight: 600
                      }}
                      className="deviceTitle">
                      Chromecast
                    </span>
                  </span>
                )}
                {item.supportedDevices.laptop && (
                  <span className={'deviceContainer'}>
                    <Laptop style={{ fontSize: 19, color: PrimaryText }} />
                    <span
                      style={{
                        fontSize: 11,
                        color: PrimaryText,
                        fontWeight: 600
                      }}
                      className="deviceTitle">
                      Laptop
                    </span>
                  </span>
                )}
                {item.supportedDevices.desktop && (
                  <span className={'deviceContainer'}>
                    <DesktopMac style={{ fontSize: 19, color: PrimaryText }} />
                    <span
                      style={{
                        fontSize: 11,
                        color: PrimaryText,
                        fontWeight: 600
                      }}
                      className="deviceTitle">
                      Desktop
                    </span>
                  </span>
                )}
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    )
  }

  const renderDesktopTabletView = () => {
    return (
      <div className="desktopTabletView" style={{ width: '260px' }}>
        <div
          id={item.id}
          style={{
            borderTopLeftRadius: item.toonify ? 0 : 10,
            borderTopRightRadius: item.toonify ? 0 : 10,
            border: `1px solid ${
              selectedPlan && selectedPlan.id === item.id
                ? SelectedPlanColor
                : '#969696'
            }`
          }}
          className={
            selectedPlan && selectedPlan.id === item.id
              ? 'planContainer-selected'
              : 'planContainer'
          }
          role={'presentation'}
          onClick={() => handlePlanSelection(item)}>
          <div
            style={{
              borderTopLeftRadius: item.toonify ? 0 : 9,
              borderTopRightRadius: item.toonify ? 0 : 9,
              backgroundColor:
                selectedPlan && selectedPlan.id === item.id
                  ? SelectedPlanColor
                  : 'transparent'
            }}
            className={
              selectedPlan && selectedPlan.id === item.id
                ? 'planNameContainer-selected'
                : 'planNameContainer'
            }>
            {item.planName}
          </div>

          <div style={{ padding: 20, marginBottom: 20 }}>
            <div
              className={'priceContainer'}
              style={{
                textDecoration: codeApplied ? 'line-through' : 'none',
                color: PrimaryText,
                fontSize: codeApplied ? 18 : 24
              }}>
              {`${item.currency} ${item.price}`}
            </div>
            {codeApplied && (
              <div className={'priceContainer'} style={{ marginTop: 20 }}>
                {`${item.currency} ${item.price - item.price * 0.2}`}
              </div>
            )}

            <div
              style={{
                color:
                  selectedPlan && selectedPlan.id === item.id
                    ? PrimaryText
                    : SecondaryText
              }}
              className={
                selectedPlan && selectedPlan.id === item.id
                  ? 'qualityContainer-selected'
                  : 'qualityContainer'
              }>
              {item.description}
            </div>

            <div
              style={{
                color:
                  selectedPlan && selectedPlan.id === item.id
                    ? PrimaryText
                    : SecondaryText
              }}
              className={
                selectedPlan && selectedPlan.id === item.id
                  ? 'qualityContainer-selected'
                  : 'qualityContainer'
              }>
              {item.maxResolution}
            </div>

            <div
              style={{
                fontSize: 14,
                color:
                  selectedPlan && selectedPlan.id === item.id
                    ? PrimaryText
                    : SecondaryText
              }}
              className={
                selectedPlan && selectedPlan.id === item.id
                  ? 'qualityContainer-selected'
                  : 'qualityContainer'
              }>
              Watch on your
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              {item.supportedDevices.tablet && (
                <span className={'deviceContainer'}>
                  <TabletAndroid style={{ fontSize: 15, color: '#A2A2A2' }} />
                  <span className="deviceTitle">Tablet</span>
                </span>
              )}
              {item.supportedDevices.mobile && (
                <span className={'deviceContainer'}>
                  <Smartphone style={{ fontSize: 15, color: '#A2A2A2' }} />
                  <span className="deviceTitle">Mobile</span>
                </span>
              )}

              {item.supportedDevices.tv && (
                <span className={'deviceContainer'}>
                  <Cast style={{ fontSize: 15, color: '#A2A2A2' }} />
                  <span className="deviceTitle">Chromecast</span>
                </span>
              )}
              {item.supportedDevices.laptop && (
                <span className={'deviceContainer'}>
                  <Laptop style={{ fontSize: 15, color: '#A2A2A2' }} />
                  <span className="deviceTitle">Laptop</span>
                </span>
              )}
              {item.supportedDevices.desktop && (
                <span className={'deviceContainer'}>
                  <DesktopMac style={{ fontSize: 15, color: '#A2A2A2' }} />
                  <span className="deviceTitle">Desktop</span>
                </span>
              )}
            </div>
          </div>
        </div>
        {item.toonify && (
          <div
            className={'toonifyContainer'}
            role={'presentation'}
            onClick={() => {
              handleToonifyClick(!clicked)
              if (!greaterThan1024) setOpnToonifyModal(true)
            }}
            style={{
              backgroundColor: clicked ? '#FE9B00' : '#FEC874',
              border:
                selectedPlan && selectedPlan.id === item.id
                  ? `1px solid ${SelectedPlanColor}`
                  : `1px solid ${clicked ? '#FE9B00' : '#FEC874'}`
            }}>
            TOONIFY YOURSELF IN GAME
          </div>
        )}

        {greaterThan1024 && item.toonify && clicked && (
          <div
            ref={imageRef}
            onMouseOver={() => handleHovering(true)}
            onMouseLeave={() => handleHovering(false)}
            style={{
              position: 'absolute',
              right: -240,
              top: -40,
              zIndex: 2,
              height: 200,
              width: 200
            }}>
            <img
              src={hovering ? toonifyImage : profileImage}
              style={{ height: 200, width: 200 }}
            />
          </div>
        )}

        {item.toonify && (
          <div
            style={{ display: clicked || showToonify ? 'block' : 'none' }}
            className={'shineRight'}>
            <svg
              width="20"
              height="51"
              viewBox="0 0 20 51"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2.02734 13.5223L13.1505 2.39915"
                stroke="#FEC874"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M2.2695 25.0147H18"
                stroke="#FEC874"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M2.02723 36.9777L13.1504 48.1008"
                stroke="#FEC874"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        )}

        {item.toonify && (
          <div
            style={{ display: clicked || showToonify ? 'block' : 'none' }}
            className={'shineLeft'}>
            <svg
              width="21"
              height="51"
              viewBox="0 0 21 51"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M18.251 37.1825L6.93352 48.5"
                stroke="#FEC874"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.0046 25.4894L1.9993 25.4894"
                stroke="#FEC874"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.2511 13.3175L6.9336 2.00001"
                stroke="#FEC874"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        )}
      </div>
    )
  }
  return (
    <Grid item style={{ paddingLeft: 15, paddingRight: 15 }}>
      {lessThan700 ? renderMobileView() : renderDesktopTabletView()}

      <Modal
        open={openToonifyModal}
        onClose={() => setOpnToonifyModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="centeredContainer">
        <ToonifyComp />
      </Modal>
    </Grid>
  )
}

const ToonifyComp = () => {
  const [change, setChange] = useState(false)
  return (
    <div
      className="toonifyModal"
      role="presentation"
      onClick={() => setChange(!change)}>
      <img
        src={change ? toonifyImage : profileImage}
        style={{ height: 200, width: 200 }}
        alt=""
      />
    </div>
  )
}

export default PlanItem
