import { Icons } from '../theme'
import {
  HSYBackground,
  HSYTitle,
  HSY2,
  paypal,
  easypesa,
  jazCash,
  Sanam2,
  SanamTitle,
  SanamBackground,
  SaraTitle,
  Sara2,
  SaraBackground,
  SamoonTitle,
  Samoon2,
  SamoonBackground,
  ShabbirTitle,
  Shabbir2,
  ShabbirBackground,
  MuhibTitle,
  Muhib2,
  MuhibMobile,
  MuhibBackground,
  NayyerTitle,
  Nayyer2,
  NayyerBackground,
  ImamTitle,
  Imam2,
  ImamBackground,
  AliTitle,
  Ali2,
  AliBackground,
  MustafaTitle,
  Mustafa2,
  MustafaBackground,
  MurtazaTitle,
  Murtaza2,
  MurtazaBackground,
  MuhibAvatar,
  SanamAvatar,
  SaraAvatar,
  HSYAvatar
} from '../assets'

export const PaymentOptions = {
  paypal: {
    method: 'PayPal',
    logo: paypal
  },
  easypaisa: {
    method: 'Easypaisa',
    logo: easypesa
  },
  jazzcash: {
    method: 'Jazz Cash',
    logo: jazCash
  },
  jazzcashcard: {
    method: 'Jazz Cash Card',
    logo: jazCash
  }
}

export const PAYPAL_CLIENT_ID =
  'AcqP_9Br6psN51eJ_rvnGID_c_VqCvrhC1YNWqL19zVQMHcBQ3CL-KFMBaF9VyGUHYezh4AhvgDST_EW'

export const STRIPE_CHECKOUT_URL =
  'http://localhost:4242/create-checkout-session'
export const STRIPE_KEY =
  'pk_test_51ILsR3CaNalnTQrxUTbukz94C9fum6ek9fWOzlo8QpgVG6otKFEOXIwaDjINynntqDqsmswWsSlYT6DgnGV8l5Gb00Vr6PC4U1'

export const CastData = [
  {
    id: '1',
    title: SanamTitle,
    picture: Sanam2,
    pictureForMobile: Sanam2,
    detail:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi, blandit consectetur rutrum neque. Donec turpis tempor iaculis diam tempor congue nunc. Vitae tristique libero lectus aliquam aliquam nascetur lobortis parturient. Sodales gravida sit enim scelerisque iaculis ut.',
    backgroundPicture: SanamBackground
  },
  {
    id: '2',
    title: HSYTitle,
    picture: HSY2,
    pictureForMobile: HSY2,
    detail:
      'asdf asdf asdf asdf asdf asdf asd fasd fqwer qLorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi, blandit consectetur rutrum neque. Donec turpis tempor iaculis diam tempor congue nunc. Vitae tristique libero lectus aliquam aliquam nascetur lobortis parturient. Sodales gravida sit enim scelerisque iaculis ut.',
    backgroundPicture: HSYBackground
  },
  {
    id: '3',
    title: SaraTitle,
    picture: Sara2,
    pictureForMobile: Sara2,
    detail:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi, blandit consectetur rutrum neque. Donec turpis tempor iaculis diam tempor congue nunc. Vitae tristique libero lectus aliquam aliquam nascetur lobortis parturient. Sodales gravida sit enim scelerisque iaculis ut.',
    backgroundPicture: SaraBackground
  },
  {
    id: '4',
    title: SamoonTitle,
    picture: Samoon2,
    pictureForMobile: Samoon2,
    detail:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi, blandit consectetur rutrum neque. Donec turpis tempor iaculis diam tempor congue nunc. Vitae tristique libero lectus aliquam aliquam nascetur lobortis parturient. Sodales gravida sit enim scelerisque iaculis ut.',
    backgroundPicture: SamoonBackground
  },
  {
    id: '5',
    title: ShabbirTitle,
    picture: Shabbir2,
    pictureForMobile: Shabbir2,
    detail:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi, blandit consectetur rutrum neque. Donec turpis tempor iaculis diam tempor congue nunc. Vitae tristique libero lectus aliquam aliquam nascetur lobortis parturient. Sodales gravida sit enim scelerisque iaculis ut.',
    backgroundPicture: ShabbirBackground
  },
  {
    id: '6',
    title: MuhibTitle,
    picture: Muhib2,
    pictureForMobile: MuhibMobile,
    detail:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi, blandit consectetur rutrum neque. Donec turpis tempor iaculis diam tempor congue nunc. Vitae tristique libero lectus aliquam aliquam nascetur lobortis parturient. Sodales gravida sit enim scelerisque iaculis ut.',
    backgroundPicture: MuhibBackground
  },
  {
    id: '7',
    title: NayyerTitle,
    picture: Nayyer2,
    pictureForMobile: Nayyer2,
    detail:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi, blandit consectetur rutrum neque. Donec turpis tempor iaculis diam tempor congue nunc. Vitae tristique libero lectus aliquam aliquam nascetur lobortis parturient. Sodales gravida sit enim scelerisque iaculis ut.',
    backgroundPicture: NayyerBackground
  },
  {
    id: '8',
    title: ImamTitle,
    picture: Imam2,
    pictureForMobile: Imam2,
    detail:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi, blandit consectetur rutrum neque. Donec turpis tempor iaculis diam tempor congue nunc. Vitae tristique libero lectus aliquam aliquam nascetur lobortis parturient. Sodales gravida sit enim scelerisque iaculis ut.',
    backgroundPicture: ImamBackground
  },
  {
    id: '9',
    title: AliTitle,
    picture: Ali2,
    pictureForMobile: Ali2,
    detail:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi, blandit consectetur rutrum neque. Donec turpis tempor iaculis diam tempor congue nunc. Vitae tristique libero lectus aliquam aliquam nascetur lobortis parturient. Sodales gravida sit enim scelerisque iaculis ut.',
    backgroundPicture: AliBackground
  },
  {
    id: '10',
    title: MustafaTitle,
    picture: Mustafa2,
    pictureForMobile: Mustafa2,
    detail:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi, blandit consectetur rutrum neque. Donec turpis tempor iaculis diam tempor congue nunc. Vitae tristique libero lectus aliquam aliquam nascetur lobortis parturient. Sodales gravida sit enim scelerisque iaculis ut.',
    backgroundPicture: MustafaBackground
  },
  {
    id: '11',
    title: MurtazaTitle,
    picture: Murtaza2,
    pictureForMobile: Murtaza2,
    detail:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi, blandit consectetur rutrum neque. Donec turpis tempor iaculis diam tempor congue nunc. Vitae tristique libero lectus aliquam aliquam nascetur lobortis parturient. Sodales gravida sit enim scelerisque iaculis ut.',
    backgroundPicture: MurtazaBackground
  }
]

export const SocialIcons = [
  {
    id: '8992360087688',
    icon: Icons.FB
  },
  {
    id: '8804725747982',
    icon: Icons.Twitter
  },
  {
    id: '6257482357580',
    icon: Icons.Instagram
  }
]

export const ProfileMenuItems = [
  {
    id: '0',
    name: 'EDIT ACCOUNT'
  },
  {
    id: '1',
    name: 'PAYMENT HISTORY'
  },
  {
    id: '2',
    name: 'LOG OUT'
  }
]

export const MainDrawerItems = [
  {
    id: '0',
    name: 'HOME',
    pageUrl: 'home'
  },
  {
    id: '1',
    name: 'CAST',
    pageUrl: 'cast'
  },
  {
    id: '2',
    name: 'GAME',
    pageUrl: 'game'
  },
  {
    id: '3',
    name: 'HOW TO WATCH',
    pageUrl: 'how-to-watch'
  },
  {
    id: '4',
    name: 'RESERVE NOW',
    pageUrl: 'reserve-now'
  }
]

export const NavBarItems = [
  {
    id: '0',
    name: 'HOME',
    pageUrl: 'home'
  },
  {
    id: '1',
    name: 'CAST',
    pageUrl: 'cast'
  },
  {
    id: '2',
    name: 'GAME',
    pageUrl: 'game'
  },
  {
    id: '3',
    name: 'HOW TO WATCH',
    pageUrl: 'how-to-watch'
  }
]

export const ProfileDropDownItems = [
  {
    id: '0',
    title: 'EDIT ACCOUNT'
  },
  {
    id: '1',
    title: 'PAYMENT HISTORY'
  },
  {
    id: '2',
    title: 'LOG OUT'
  }
]

export const BuyDropDownItems = [
  {
    id: '0',
    title: 'FOR YOURSELF'
  },
  {
    id: '1',
    title: 'FOR A FRIEND'
  }
]

export const HistoryTableColumns = [
  {
    id: '0',
    name: 'Account Number'
  },
  {
    id: '1',
    name: 'Title'
  },
  {
    id: '2',
    name: 'For'
  },
  {
    id: '3',
    name: 'Type'
  },
  {
    id: '4',
    name: 'Date'
  },
  {
    id: '5',
    name: 'Total'
  }
]

export const Months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

export const NameToNumber = {
  January: '01',
  February: '02',
  March: '03',
  April: '04',
  May: '05',
  June: '06',
  July: '07',
  August: '08',
  September: '09',
  October: '10',
  November: '11',
  December: '12'
}

export const Years = [
  '1990',
  '1991',
  '1992',
  '1993',
  '1994',
  '1995',
  '1996',
  '1997',
  '1998',
  '1999',
  '2000',
  '2001',
  '2002',
  '2003',
  '2004',
  '2005',
  '2006',
  '2007',
  '2008',
  '2009',
  '2010',
  '2011',
  '2012',
  '2013',
  '2014',
  '2015',
  '2016',
  '2017',
  '2018',
  '2019',
  '2020'
]

export const Days = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31'
]

export const Casts = [
  {
    id: '0',
    image: MuhibAvatar
  },
  {
    id: '1',
    image: SanamAvatar
  },
  {
    id: '2',
    image: SaraAvatar
  },
  {
    id: '3',
    image: HSYAvatar
  }
]

export const Countries = ['Pakistan', 'America']
export const FooterIcons = [
  {
    id: 'facebook',
    icon: 'fa fa-facebook footer-icon',
    navigateTo: 'https://www.facebook.com/'
  },
  {
    id: 'twitter',
    icon: 'fa fa-twitter footer-icon',
    navigateTo: 'https://twitter.com/'
  },
  {
    id: 'instagram',
    icon: 'fa fa-instagram footer-icon',
    navigateTo: 'https://www.instagram.com/'
  }
]
