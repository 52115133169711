import { useDispatch } from 'react-redux'

import { ButtonComp } from '../../../components'
import { setEditAccountModal } from '../../../redux/modules/globalModals'
import { PrimaryButton } from '../../../styles/colors'

const Footer = () => {
  const dispatch = useDispatch()

  const closeModal = () =>
    dispatch(setEditAccountModal({ isEAModalVisible: false }))

  return (
    <div className="editAccountFooterContainer">
      <div className="editAccountFooterContainer-button">
        <ButtonComp
          backgroundColor={PrimaryButton}
          onClickHandler={closeModal}
          disabled={false}
          title={'Save'}
          height={36}
          width={'100%'}
        />
      </div>
      <div className="editAccountFooterContainer-button">
        <ButtonComp
          backgroundColor="transparent"
          borderColor="white"
          onClickHandler={closeModal}
          disabled={false}
          title={'Cancel'}
          height={36}
          width={'100%'}
        />
      </div>
    </div>
  )
}

export default Footer
