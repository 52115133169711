import React, { useState, useReducer } from 'react'
import { useHistory } from 'react-router-dom'
import { API, graphqlOperation } from 'aws-amplify'
import { useSelector } from 'react-redux'

import { ButtonComp, FormInput } from '../../../components'
import * as subscriptions from '../../../graphql/subscriptions'
import { processCheckout } from '../../../backend/payment'

const JazzCashCardForm = ({ setErrorDialog }) => {
  const [{ cardNumber, expiry, cvv }, setPayload] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { expiry: '', cardNumber: '', cvv: '' }
  )

  const {
    plan: { id: planId, price }
  } = useSelector((state) => state.reserveData)

  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const history = useHistory()

  const handleInput = (event) => {
    const { name, value } = event.target

    if (isNaN(value)) return

    if (name === 'cardNumber' && value.length > 16) return

    if (name === 'cvv' && value.length > 3) return

    if (name === 'expiry' && value.length > 4) return

    setPayload({ [name]: value })
    setError(null)
  }

  const checkout = async () => {
    if (cardNumber === '') {
      setError('Please enter your card number')
      return
    }

    if (cardNumber.length < 16) {
      setError('Please enter a valid card number')
      return
    }

    if (expiry === '') {
      setError('Please enter expiry date of your card')
      return
    }

    if (cvv === '') {
      setError('Please enter cvv of card')
      return
    }

    setLoading(true)

    const payload = {
      expiry,
      cardNumber,
      cvv,
      planId,
      price
    }

    try {
      const id = await processCheckout(payload, 'JAZZ_CASH_CARD')
      if (!id) {
        setLoading(false)
        setErrorDialog()
        return
      }
      listenForPayment(id)
    } catch (err) {
      setLoading(false)
      setErrorDialog()
    }
  }

  const listenForPayment = (checkoutId) => {
    const subscription = API.graphql(
      graphqlOperation(subscriptions.onUpdatePaymentProcessingStatus, {
        id: checkoutId
      })
    ).subscribe({
      next: ({ provider, value }) => {
        subscription.unsubscribe()

        setLoading(false)

        const { id, status } = value.data.onUpdatePaymentProcessingStatus
        if (id === checkoutId && status === 'SUCCESS') {
          history.replace('/purchase-done')
        } else setErrorDialog()
      },
      error: (error) => {
        subscription.unsubscribe()
        setLoading(false)
        setErrorDialog()
      }
    })
  }

  return (
    <div>
      <div className="jazzcashInput">
        <FormInput
          value={cardNumber}
          placeholder="Card Number"
          id="cardNumber"
          name="cardNumber"
          onChangeHandler={handleInput}
        />
      </div>
      <div className="jazzcashInput">
        <FormInput
          value={expiry}
          placeholder="Expiry"
          id="expiry"
          name="expiry"
          onChangeHandler={handleInput}
        />
      </div>
      <div className="jazzcashInput">
        <FormInput
          value={cvv}
          placeholder="CVV"
          id="cvv"
          name="cvv"
          onChangeHandler={handleInput}
        />
      </div>

      {error && <div className="jazzcashInput">{error}</div>}

      <div className="jazzcashInput">
        <ButtonComp
          onClickHandler={checkout}
          title={'CONTINUE'}
          height={36}
          loading={loading}
          width={300}
        />
      </div>
    </div>
  )
}

export default JazzCashCardForm
