import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useMediaPredicate } from 'react-media-hook'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'

import ButtonComp from '../../../components/Button'
import { Casts } from '../../../lib/constant'
import {
  PrimaryButton,
  SecondaryButton,
  SecondaryButtonHover,
  Transparent
} from '../../../styles/colors'

const styles = makeStyles((theme) => ({
  gridList: {
    width: 635,
    maxHeight: 500
  },
  gridListMobile: {
    width: 300,
    maxHeight: 500
  },
  avatarItemMobile: {
    height: 110,
    width: '75%',
    borderRadius: 5,
    cursor: 'pointer'
  },
  avatarItemDesktop: {
    height: 140,
    borderRadius: 5,
    cursor: 'pointer'
  }
}))

const AvatarList = ({ handleAvatar, closeAvatarModal, selectedValue }) => {
  const [selectedAvatar, setSelectedAvatar] = useState(selectedValue)

  const lessThan450 = useMediaPredicate('(max-width: 450px)')

  const classes = styles()

  return (
    <div className="avatarListModal">
      <div className="flex">
        {lessThan450 ? (
          <GridList
            cellHeight={125}
            className={classes.gridListMobile}
            cols={2}>
            {Casts.map((item) => {
              return (
                <GridListTile key={item.id}>
                  <img
                    role="presentation"
                    onClick={() => setSelectedAvatar(item.id)}
                    className={classes.avatarItemMobile}
                    style={{
                      padding: 5,
                      border:
                        selectedAvatar === item.id
                          ? `2px solid ${PrimaryButton}`
                          : `2px solid ${Transparent}`
                    }}
                    src={item.image}
                  />
                </GridListTile>
              )
            })}
          </GridList>
        ) : (
          <GridList className={classes.gridList} cols={4}>
            {Casts.map((item) => {
              return (
                <GridListTile key={item.id}>
                  <img
                    role="presentation"
                    onClick={() => setSelectedAvatar(item.id)}
                    src={item.image}
                    style={{
                      padding: 10,
                      border:
                        selectedAvatar === item.id
                          ? `3px solid ${PrimaryButton}`
                          : `3px solid ${Transparent}`
                    }}
                    className={classes.avatarItemDesktop}
                  />
                </GridListTile>
              )
            })}
          </GridList>
        )}
      </div>
      <div className="centeredContainer">
        <div className="avatarButtons">
          <ButtonComp
            onClickHandler={() => handleAvatar(selectedAvatar)}
            disabled={selectedAvatar ? false : true}
            title={'DONE'}
            backgroundColor={SecondaryButton}
            borderColor={SecondaryButton}
            width={110}
          />
        </div>
        <div className="avatarButtons">
          <ButtonComp
            onClickHandler={closeAvatarModal}
            disabled={false}
            backgroundColor={Transparent}
            hoverBackgroundColor={Transparent}
            borderColor={SecondaryButtonHover}
            hoverBorderColor={SecondaryButtonHover}
            title={'CANCEL'}
            width={110}
          />
        </div>
      </div>
    </div>
  )
}

export default AvatarList
