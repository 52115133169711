import React from 'react'
import clsx from 'clsx'
import { Drawer, List } from '@material-ui/core'
import { useDispatch } from 'react-redux'

import {
  setTHModal,
  setEditAccountModal
} from '../../redux/modules/globalModals'
import { logoutUser } from '../../redux/modules/user'
import { makeDrawerStyles } from './styles'
import { AvatarDummy } from '../../assets'
import { ProfileMenuItems } from '../../lib/constant'

const ProfileMenuDrawer = ({ state, toggleProfileDrawer }) => {
  const classes = makeDrawerStyles()
  const dispatch = useDispatch()

  const handleProfileMenuClick = (id) => {
    if (id === '0') dispatch(setEditAccountModal({ isEAModalVisible: true }))
    else if (id === '1') dispatch(setTHModal({ isTHModalVisible: true }))
    else dispatch(logoutUser())
  }
  return (
    <div>
      <Drawer
        anchor={'right'}
        open={state['right']}
        onClose={toggleProfileDrawer('right', false)}>
        <div
          className={clsx(classes.list, {
            [classes.fullList]: false
          })}
          role="presentation"
          onClick={toggleProfileDrawer('right', false)}
          onKeyDown={toggleProfileDrawer('right', false)}>
          <List disablePadding={true} className="list-right">
            <div className="avatarSection">
              <div className="userName">Shahbaz Khan</div>
              <div className="profileDrawerImage">
                <img src={AvatarDummy} style={{ height: 115 }} />
              </div>
            </div>
            {ProfileMenuItems.map((item) => {
              return (
                <div
                  id={item.name}
                  className="right-menu"
                  role="presentation"
                  onClick={() => handleProfileMenuClick(item.id)}>
                  {item.name}
                </div>
              )
            })}
          </List>
        </div>
      </Drawer>
    </div>
  )
}

export default ProfileMenuDrawer
