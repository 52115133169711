import React, { useState, useEffect } from 'react'
import { Modal, Grid, Select, MenuItem } from '@material-ui/core'
import { useMediaPredicate } from 'react-media-hook'
import { useSelector, useDispatch } from 'react-redux'

import './plan.css'
import PlanItem from './planItem'
import ButtonComp from '../../../components/Button'
import { setPlan } from '../../../redux/modules/reserveData'
import { FormInput, Header } from '../../../components'
import { Loading } from '../../../components/Loading'
import { postReadPlans } from '../../../redux/modules/pricings'
import PaymentSetup from '../PaymentSetup'
import VisaSetup from '../VisaSetup'
import ExternalPayment from '../externalPayment'

const COUNTRY_DATA = {
  PKR: {
    video_id: {
      eq: 'd6e0c9ff-5d17-40c7-872a-0b8fce9a7d76'
    },
    country_id: {
      eq: '339ba61d-88e2-45e5-83d1-52375ddbb4e1'
    },
    status: {
      eq: true
    }
  },
  UK: {
    video_id: {
      eq: '06ac0bfd-456d-4f93-8c53-c9b2a6d563e0'
    },
    country_id: {
      eq: '292ba8eb-c36b-4638-bf50-8f2494e8f17c'
    },
    status: {
      eq: true
    }
  }
}

const Plan = ({ selectedCountry, setSelectedCountry, setCurrentStep }) => {
  const [selectedPlan, setSelectedPlan] = useState(null)
  const [hovering, setHovering] = useState(false)
  const [clicked, setClicked] = useState(false)
  const [isPromoModalVisible, setIsPromoModalVisible] = useState(false)
  const [codeApplied, setCodeApplied] = useState(false)
  // TODO Toonify
  // const [showToonify, setShowToonify] = useState(false)
  const [opendPlan, setOpenPlan] = useState('0')
  const [invalidCode, setInvalidCode] = useState(false)
  const [loading, setLoading] = useState(true)

  const reserveData = useSelector((state) => state.reserveData)
  const { plans } = useSelector((state) => state.pricings)

  const dispatch = useDispatch()

  useEffect(() => {
    async function fetchPlans() {
      setSelectedPlan(null)

      try {
        setLoading(true)

        await dispatch(postReadPlans(COUNTRY_DATA[selectedCountry]))

        setLoading(false)
      } catch (error) {
        console.error(error)

        setLoading(false)
      }
    }

    fetchPlans()
  }, [dispatch, selectedCountry])

  if (!selectedPlan && plans.length > 0) {
    let _selected = plans[0]

    plans.forEach((item) => {
      if (item.price > _selected.price) _selected = item
    })

    setSelectedPlan(_selected)
    setOpenPlan(_selected.id)
  }

  const closeModal = () => setIsPromoModalVisible(false)

  const navigateToCheckout = () => {
    if (selectedPlan) {
      dispatch(setPlan({ ...selectedPlan, couponApplied: codeApplied }))

      setCurrentStep('SelectCheckoutMethod')
    }
  }

  const addCoupon = (value) => {
    if (value === 'XYZ3') setCodeApplied(true)
    else setInvalidCode(true)

    setIsPromoModalVisible(false)
  }

  // TODO Toonify
  // useEffect(() => {
  //   const _interval = setInterval(() => {
  //     setShowToonify(!showToonify)
  //   }, 1000)

  //   return () => clearInterval(_interval)
  // },[])

  const handleCollapse = (value) => setOpenPlan(value)

  const handlePlanSelection = (item) => setSelectedPlan(item)

  const handleToonifyClick = (value) => setClicked(!clicked)

  const handleHovering = (value) => setHovering(value)

  const onCountryChange = (event) => {
    setSelectedCountry(event.target.value)
  }

  return (
    <div className="pageWithBackgound" style={{ flex: 1 }}>
      <Header
        title={`CHOOSE THE PLAN FOR ${reserveData.watcher.for.toUpperCase()}`}
      />
      <div style={{ margin: '0 auto' }}>
        <Select
          value={selectedCountry}
          onChange={onCountryChange}
          id="cty"
          name="cty"
          variant="outlined"
          className="select-country">
          <MenuItem value="PKR">Pakistan</MenuItem>
          <MenuItem value="UK">UK</MenuItem>
        </Select>
      </div>
      {loading ? (
        <Loading open={loading} />
      ) : (
        <div className="planContent">
          <div className="centeredContainer" md={3}>
            <Grid container justify="center">
              {plans.map((item) => {
                return (
                  <PlanItem
                    item={item}
                    handlePlanSelection={handlePlanSelection}
                    handleToonifyClick={handleToonifyClick}
                    clicked={clicked}
                    selectedPlan={selectedPlan}
                    codeApplied={codeApplied}
                    showToonify={false}
                    hovering={hovering}
                    handleHovering={handleHovering}
                    opendPlan={opendPlan}
                    handleCollapse={handleCollapse}
                  />
                )
              })}
            </Grid>
          </div>
          <div
            role="presentation"
            onClick={() => {
              if (!codeApplied) setIsPromoModalVisible(true)
            }}
            className={codeApplied ? 'appliedCodeText' : 'promoText'}>
            {codeApplied
              ? 'Code Applied'
              : invalidCode
              ? 'Invalid code. Try another one? ⟲'
              : 'Add Gift / Promo Code'}
          </div>
          <ButtonComp
            onClickHandler={() => navigateToCheckout()}
            disabled={false}
            title={'CONTINUE'}
            width={300}
          />
        </div>
      )}
      <Modal
        open={isPromoModalVisible}
        onClose={closeModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="coupon-modal">
        <CouponModal addCoupon={addCoupon} />
      </Modal>
    </div>
  )
}

const CouponModal = ({ addCoupon }) => {
  const [coupon, setCoupon] = useState('')

  const lessThan700 = useMediaPredicate('(max-width: 650px)')

  const handleInput = (event) => {
    const { value } = event.target

    setCoupon(value)
  }

  return (
    <div className="couponContainer">
      {!lessThan700 && (
        <div className="couponModalTitle">
          <p className="coupon-modal-title">ENTER YOUR COUPON CODE</p>
        </div>
      )}
      <div>
        <div className="textFieldContainer">
          <FormInput
            placeholder="Enter Coupon"
            id="coupon"
            value={coupon}
            name="coupon"
            onChangeHandler={handleInput}
          />
        </div>
        <div className="centeredContainer">
          <ButtonComp
            onClickHandler={() => addCoupon(coupon)}
            disabled={false}
            title={'APPLY'}
            width={'100%'}
            height={34}
          />
        </div>
      </div>
    </div>
  )
}

const Checkout = () => {
  const [selectedCountry, setSelectedCountry] = useState('PKR')
  const [currentStep, setCurrentStep] = useState('SelectPlan')

  return (
    <>
      {currentStep === 'SelectPlan' && (
        <Plan
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
          setCurrentStep={setCurrentStep}
        />
      )}
      {currentStep === 'SelectCheckoutMethod' && (
        <PaymentSetup
          setCurrentStep={setCurrentStep}
          countryId={COUNTRY_DATA[selectedCountry].country_id['eq']}
        />
      )}
      {currentStep === 'VisaSetup' && (
        <VisaSetup setCurrentStep={setCurrentStep} />
      )}
      {(currentStep === 'Paypal' ||
        currentStep === 'EasyPaisa' ||
        currentStep === 'JazzCash' ||
        currentStep === 'JazzCashCard') && (
        <ExternalPayment
          setCurrentStep={setCurrentStep}
          paymentMethod={currentStep.toLocaleLowerCase()}
        />
      )}
    </>
  )
}

export default Checkout
