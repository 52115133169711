import React, { useState, useEffect } from 'react'
import { Grid, Modal } from '@material-ui/core'
import Countdown from 'react-countdown'
import { useSelector, useDispatch } from 'react-redux'
import LinearProgress from '@material-ui/core/LinearProgress'
import { useMediaPredicate } from 'react-media-hook'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'

import Rating from '../Rating'
import { ButtonComp } from '../../../components'
import { setWatching } from '../../../redux/modules/ishrat'
import './loggedIn.css'
import {
  IshratLogo2,
  SelectedUp,
  ThumbsUp,
  SelectedDown,
  ThumbsDown
} from '../../../assets'

const LoggedIn = () => {
  const lessThan450 = useMediaPredicate('(max-width: 450px)')
  const lessThan770 = useMediaPredicate('(max-width: 770px)')

  const [seeOnDesktopModal, setSeeOnDesktopModal] = useState(false)
  const [isRatingVisible, setIsRatingVisible] = useState(false)
  const [rating, setRating] = useState(null)

  const ishratData = useSelector((state) => state.ishratData)

  const dispatch = useDispatch()

  const closeRatingModal = () => setIsRatingVisible(false)

  useEffect(() => {
    if ((!lessThan450 || !lessThan770) && seeOnDesktopModal)
      setSeeOnDesktopModal(false)
  }, [lessThan450, lessThan770])

  return (
    <div className={`loggedInBackground`}>
      {lessThan450 ? (
        <div className="mobileLoggedinContainer">
          <div className="blurredContainerMobile">
            <div className="mobileViewContentLogin">
              <p className="Streaming">YOUR MOVIE WILL EXPIRE IN</p>

              <Countdown date={Date.now() + 999999999} renderer={renderer} />

              {ishratData.startedWatcing ? (
                <div className="buttonAndProgressContainerMobile">
                  <div className="linearProgressBar">
                    <LinearProgress
                      color="secondary"
                      variant="determinate"
                      value={60}
                      style={{ backgroundColor: '#4B4B4B' }}
                    />
                  </div>
                  <div className="buttonContainerMobileView">
                    <ButtonComp
                      title="RESUME"
                      onClickHandler={() => setSeeOnDesktopModal(true)}
                      width={'60%'}
                      icon={<PlayArrowIcon style={{ paddingRight: 10 }} />}
                    />
                  </div>
                </div>
              ) : (
                <div className="buttonAndProgressContainerMobile">
                  <div className="buttonContainerMobileView">
                    <ButtonComp
                      title="PLAY NOW"
                      onClickHandler={() => setSeeOnDesktopModal(true)}
                      width={'60%'}
                      icon={<PlayArrowIcon style={{ paddingRight: 10 }} />}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="bottomContainerMobile">
              <div>
                <link
                  rel="stylesheet"
                  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
                />
                <a href="#" className="fa fa-facebook" />
                <a href="#" className="fa fa-twitter" />
                <a href="#" className="fa fa-instagram" />
              </div>
              <p className="bottomText">#ISHRATMADEINCHINA</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="desktopLoginMain">
          <Grid container style={{ padding: 30 }}>
            <Grid item xl={3} lg={3} md={2} sm={0} xs={0}></Grid>
            <Grid item xl={6} lg={6} md={8} sm={0} xs={0}>
              <div style={{ flex: 1, display: 'flex' }}>
                <div style={{ flex: 0.4, padding: 10 }}>
                  <img src={IshratLogo2} className="ishratSideLogo" />
                </div>
                <div className="detailContainer">
                  <div className="counterTitleContainer">
                    <p className="Streaming">YOUR MOVIE WILL EXPIRE IN</p>
                    <Countdown
                      date={Date.now() + 999999999}
                      renderer={renderer}
                    />
                  </div>

                  {ishratData.startedWatcing ? (
                    <div className="buttonAndProgressContainerDesktop">
                      <div className="linearProgressBarDesktop">
                        <LinearProgress
                          variant="determinate"
                          color="secondary"
                          value={60}
                          style={{ backgroundColor: '#4B4B4B' }}
                        />
                      </div>
                      <div className="resumePlayButtonContainer">
                        <ButtonComp
                          onClickHandler={() =>
                            lessThan770
                              ? setSeeOnDesktopModal(true)
                              : setIsRatingVisible(true)
                          }
                          title="RESUME"
                          width="100%"
                        />

                        <div className="thumbsButtonContainer">
                          <img
                            style={{ cursor: 'pointer' }}
                            role="presentation"
                            onClick={() => setRating('up')}
                            src={rating === 'up' ? SelectedUp : ThumbsUp}
                            className="thumbsButton"
                          />
                        </div>
                        <div style={{ width: 30 }}>
                          <img
                            onClick={() => setRating('down')}
                            style={{ cursor: 'pointer' }}
                            src={rating === 'down' ? SelectedDown : ThumbsDown}
                            className="thumbsButton"
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="buttonAndProgressContainerDesktop">
                      <div className="resumePlayButtonContainer">
                        <ButtonComp
                          onClickHandler={() =>
                            lessThan770
                              ? setSeeOnDesktopModal(true)
                              : dispatch(setWatching())
                          }
                          title="PLAY NOW"
                          width="90%"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>

          <div className="bottomContainerDesktop">
            <div>
              <link
                rel="stylesheet"
                href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
              />
              <a href="#" className="fa fa-facebook" />
              <a href="#" className="fa fa-twitter" />
              <a href="#" className="fa fa-instagram" />
            </div>
            <p className="bottomText">#ISHRATMADEINCHINA</p>
          </div>
        </div>
      )}

      <Modal
        open={seeOnDesktopModal}
        onClose={() => setSeeOnDesktopModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        <div className="playOnTabletDesktopContainer">
          <div style={{ padding: 20 }}>
            <p className="watchMovieText">WATCH THE MOVIE</p>
            <p className="watchMovieText">ON YOUR PHONE OR TABLET</p>
          </div>
          <div className="getFreeAppButton">
            <ButtonComp
              onClickHandler={() => {
                setSeeOnDesktopModal(false)
                dispatch(setWatching())
              }}
              title="GET THE FREE APP"
              width="100%"
            />
          </div>
        </div>
      </Modal>

      <Modal
        open={isRatingVisible}
        onClose={closeRatingModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="centeredContainer">
        <Rating closeRatingModal={closeRatingModal} />
      </Modal>
    </div>
  )
}

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
  } else {
    return (
      <div className="timerMain">
        <div className="timerSub">
          <span className="time">{days}</span>
          <span className="timeTitle">DAYS</span>
        </div>
        <div className="timerSub">
          <span className="time">:</span>
          <span className="timeTitle loggedInTitleTitle">:</span>
        </div>
        <div className="timerSub">
          <span className="time">{hours}</span>
          <span className="timeTitle">HOURS</span>
        </div>
        <div className="timerSub">
          <span className="time">:</span>
          <span className="timeTitle loggedInTitleTitle">:</span>
        </div>
        <div className="timerSub">
          <span className="time">{minutes}</span>
          <span className="timeTitle">MINUTES</span>
        </div>
      </div>
    )
  }
}

export default LoggedIn
