import { withStyles } from '@material-ui/core/styles'
import { InputBase } from '@material-ui/core'

import { PrimaryButton } from '../../styles/colors'

const SelectInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    position: 'relative',
    width: '100%',
    border: `1px solid #3C3C63`,
    fontSize: 16,
    borderRadius: 5,
    borderColor: '#3C3C63',
    padding: '7px 26px 7px 12px',

    '&:focus': {
      borderRadius: 5,
      borderColor: PrimaryButton,
      backgroundColor: 'transparent'
    }
  }
}))(InputBase)

export default SelectInput
