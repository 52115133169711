/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const batchCreateCountries = /* GraphQL */ `
  mutation BatchCreateCountries($countries: [CreateCountryInput]) {
    batchCreateCountries(countries: $countries) {
      id
      country_code
      country_name
      Currency
      status
      createdAt
      updatedAt
    }
  }
`
export const batchCreateSupportedDevices = /* GraphQL */ `
  mutation BatchCreateSupportedDevices(
    $supportedDevices: [CreateSupportedDeviceInput]
  ) {
    batchCreateSupportedDevices(supportedDevices: $supportedDevices) {
      id
      title
      status
      createdAt
      updatedAt
    }
  }
`
export const batchCreateVideoQuality = /* GraphQL */ `
  mutation BatchCreateVideoQuality($videoQualities: [CreateVideoQuality]) {
    batchCreateVideoQuality(videoQualities: $videoQualities) {
      id
      title
      status
      createdAt
      updatedAt
    }
  }
`
export const batchCreatePlanSupportedDevice = /* GraphQL */ `
  mutation BatchCreatePlanSupportedDevice(
    $planDevices: [CreatePlanSupportedDeviceInput]
  ) {
    batchCreatePlanSupportedDevice(planDevices: $planDevices) {
      id
      supported_device_id
      plan_id
      supported_devices {
        id
        title
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
export const batchDeletePlanSupportedDevices = /* GraphQL */ `
  mutation BatchDeletePlanSupportedDevices($planDevices: [ID]) {
    batchDeletePlanSupportedDevices(planDevices: $planDevices) {
      id
      supported_device_id
      plan_id
      supported_devices {
        id
        title
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
export const batchCreatePaymentTypes = /* GraphQL */ `
  mutation BatchCreatePaymentTypes($paymentTypes: [CreatePaymentTypeInput]) {
    batchCreatePaymentTypes(paymentTypes: $paymentTypes) {
      id
      name
      disabled
      country_id
      createdAt
      updatedAt
      country {
        id
        country_code
        country_name
        Currency
        status
        createdAt
        updatedAt
      }
    }
  }
`
export const batchVideoByQualities = /* GraphQL */ `
  mutation BatchVideoByQualities($videoByQualites: [CreateVideoByQuality]) {
    batchVideoByQualities(videoByQualites: $videoByQualites) {
      id
      hls_url
      dash_url
      status
      video_id
      video_quality_id
      video_quality {
        id
        title
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      video {
        id
        title
        description
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        duration
        status
        release_date
        credits_start_timestamp
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        video_rating_id
        video_rating {
          id
          title
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`
export const batchCreateVideoPlans = /* GraphQL */ `
  mutation BatchCreateVideoPlans($videoPlans: [CreateVideoPlanInput]) {
    batchCreateVideoPlans(videoPlans: $videoPlans) {
      id
      status
      plan_id
      plan {
        id
        title
        status
        supported_devices {
          items {
            id
            supported_device_id
            plan_id
            supported_devices {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        video_quality_id
        video_quality {
          id
          title
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      video_by_quality_id
      createdAt
      updatedAt
      video_by_quality {
        id
        hls_url
        dash_url
        status
        video_id
        video_quality_id
        video_quality {
          id
          title
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        video {
          id
          title
          description
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          genres {
            items {
              id
              genre_id
              video_id
              genre {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          duration
          status
          release_date
          credits_start_timestamp
          video_tags {
            items {
              id
              tag_id
              video_id
              tag {
                id
                name
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          language
          video_type_id
          video_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          video_rating_id
          video_rating {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`
export const batchCreatePricings = /* GraphQL */ `
  mutation BatchCreatePricings($pricings: [CreatePricingInput]) {
    batchCreatePricings(pricings: $pricings) {
      id
      country_id
      price
      status
      video_plan_id
      video_plan {
        id
        status
        plan_id
        plan {
          id
          title
          status
          supported_devices {
            items {
              id
              supported_device_id
              plan_id
              supported_devices {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          video_quality_id
          video_quality {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        video_by_quality_id
        createdAt
        updatedAt
        video_by_quality {
          id
          hls_url
          dash_url
          status
          video_id
          video_quality_id
          video_quality {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          video {
            id
            title
            description
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              items {
                id
                genre_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            duration
            status
            release_date
            credits_start_timestamp
            video_tags {
              items {
                id
                tag_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            video_rating_id
            video_rating {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
        }
      }
      video_id
      createdAt
      updatedAt
      country {
        id
        country_code
        country_name
        Currency
        status
        createdAt
        updatedAt
      }
      video {
        id
        title
        description
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        duration
        status
        release_date
        credits_start_timestamp
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        video_rating_id
        video_rating {
          id
          title
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`
export const batchDeletePricings = /* GraphQL */ `
  mutation BatchDeletePricings($pricingIds: [ID]) {
    batchDeletePricings(pricingIds: $pricingIds) {
      id
      country_id
      price
      status
      video_plan_id
      video_plan {
        id
        status
        plan_id
        plan {
          id
          title
          status
          supported_devices {
            items {
              id
              supported_device_id
              plan_id
              supported_devices {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          video_quality_id
          video_quality {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        video_by_quality_id
        createdAt
        updatedAt
        video_by_quality {
          id
          hls_url
          dash_url
          status
          video_id
          video_quality_id
          video_quality {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          video {
            id
            title
            description
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              items {
                id
                genre_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            duration
            status
            release_date
            credits_start_timestamp
            video_tags {
              items {
                id
                tag_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            video_rating_id
            video_rating {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
        }
      }
      video_id
      createdAt
      updatedAt
      country {
        id
        country_code
        country_name
        Currency
        status
        createdAt
        updatedAt
      }
      video {
        id
        title
        description
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        duration
        status
        release_date
        credits_start_timestamp
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        video_rating_id
        video_rating {
          id
          title
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`
export const batchDeleteVideoPlans = /* GraphQL */ `
  mutation BatchDeleteVideoPlans($videoPlanIds: [ID]) {
    batchDeleteVideoPlans(videoPlanIds: $videoPlanIds) {
      id
      status
      plan_id
      plan {
        id
        title
        status
        supported_devices {
          items {
            id
            supported_device_id
            plan_id
            supported_devices {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        video_quality_id
        video_quality {
          id
          title
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      video_by_quality_id
      createdAt
      updatedAt
      video_by_quality {
        id
        hls_url
        dash_url
        status
        video_id
        video_quality_id
        video_quality {
          id
          title
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        video {
          id
          title
          description
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          genres {
            items {
              id
              genre_id
              video_id
              genre {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          duration
          status
          release_date
          credits_start_timestamp
          video_tags {
            items {
              id
              tag_id
              video_id
              tag {
                id
                name
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          language
          video_type_id
          video_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          video_rating_id
          video_rating {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`
export const batchCreateGenres = /* GraphQL */ `
  mutation BatchCreateGenres($genres: [CreateGenre]) {
    batchCreateGenres(genres: $genres) {
      id
      title
      status
      videos {
        items {
          id
          genre_id
          video_id
          genre {
            id
            title
            status
            videos {
              items {
                id
                genre_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const batchCreateCastCrew = /* GraphQL */ `
  mutation BatchCreateCastCrew($casts: [CreateCastCrew]) {
    batchCreateCastCrew(casts: $casts) {
      id
      first_name
      last_name
      nick_name
      description
      image_url
      status
      createdAt
      updatedAt
    }
  }
`
export const batchCastCrewTypes = /* GraphQL */ `
  mutation BatchCastCrewTypes($castTypes: [CreateCastCrewType]) {
    batchCastCrewTypes(castTypes: $castTypes) {
      id
      title
      status
      createdAt
      updatedAt
    }
  }
`
export const batchVideoGenre = /* GraphQL */ `
  mutation BatchVideoGenre($videoGenres: [CreateVideoGenre]) {
    batchVideoGenre(videoGenres: $videoGenres) {
      id
      genre_id
      video_id
      genre {
        id
        title
        status
        videos {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
export const batchCreateSupportedCaption = /* GraphQL */ `
  mutation BatchCreateSupportedCaption($captions: [CreateSupportedCaption]) {
    batchCreateSupportedCaption(captions: $captions) {
      id
      caption
      url
      status
      video_id
      createdAt
      updatedAt
    }
  }
`
export const batchCreateVideoCastCrew = /* GraphQL */ `
  mutation BatchCreateVideoCastCrew($videoCast: [CreateVideoCastCrew]) {
    batchCreateVideoCastCrew(videoCast: $videoCast) {
      id
      video_id
      cast_crew_id
      status
      cast_crew_type_id
      cast_crew_type {
        id
        title
        status
        createdAt
        updatedAt
      }
      display
      createdAt
      updatedAt
    }
  }
`
export const createUserNotification = /* GraphQL */ `
  mutation CreateUserNotification(
    $input: CreateUserNotificationInput!
    $condition: ModelUserNotificationConditionInput
  ) {
    createUserNotification(input: $input, condition: $condition) {
      id
      message
      user_id
      user {
        id
        email_address
        status
        createdAt
        updatedAt
      }
      status
      read
      generated_by
      start_date
      end_date
      createdAt
      updatedAt
    }
  }
`
export const updateUserNotification = /* GraphQL */ `
  mutation UpdateUserNotification(
    $input: UpdateUserNotificationInput!
    $condition: ModelUserNotificationConditionInput
  ) {
    updateUserNotification(input: $input, condition: $condition) {
      id
      message
      user_id
      user {
        id
        email_address
        status
        createdAt
        updatedAt
      }
      status
      read
      generated_by
      start_date
      end_date
      createdAt
      updatedAt
    }
  }
`
export const deleteUserNotification = /* GraphQL */ `
  mutation DeleteUserNotification(
    $input: DeleteUserNotificationInput!
    $condition: ModelUserNotificationConditionInput
  ) {
    deleteUserNotification(input: $input, condition: $condition) {
      id
      message
      user_id
      user {
        id
        email_address
        status
        createdAt
        updatedAt
      }
      status
      read
      generated_by
      start_date
      end_date
      createdAt
      updatedAt
    }
  }
`
export const createSiteNotification = /* GraphQL */ `
  mutation CreateSiteNotification(
    $input: CreateSiteNotificationInput!
    $condition: ModelSiteNotificationConditionInput
  ) {
    createSiteNotification(input: $input, condition: $condition) {
      id
      message
      status
      generated_by
      country_id
      start_date
      end_date
      createdAt
      updatedAt
      country {
        id
        country_code
        country_name
        Currency
        status
        createdAt
        updatedAt
      }
    }
  }
`
export const updateSiteNotification = /* GraphQL */ `
  mutation UpdateSiteNotification(
    $input: UpdateSiteNotificationInput!
    $condition: ModelSiteNotificationConditionInput
  ) {
    updateSiteNotification(input: $input, condition: $condition) {
      id
      message
      status
      generated_by
      country_id
      start_date
      end_date
      createdAt
      updatedAt
      country {
        id
        country_code
        country_name
        Currency
        status
        createdAt
        updatedAt
      }
    }
  }
`
export const deleteSiteNotification = /* GraphQL */ `
  mutation DeleteSiteNotification(
    $input: DeleteSiteNotificationInput!
    $condition: ModelSiteNotificationConditionInput
  ) {
    deleteSiteNotification(input: $input, condition: $condition) {
      id
      message
      status
      generated_by
      country_id
      start_date
      end_date
      createdAt
      updatedAt
      country {
        id
        country_code
        country_name
        Currency
        status
        createdAt
        updatedAt
      }
    }
  }
`
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      status
      ref_no
      payment_total
      disabled
      payment_type_id
      user_id
      user {
        id
        email_address
        status
        createdAt
        updatedAt
      }
      promo_code_id
      promo_code {
        id
        code
        reuse
        count
        status
        video_plan_id
        video_plan {
          id
          status
          plan_id
          plan {
            id
            title
            status
            supported_devices {
              items {
                id
                supported_device_id
                plan_id
                createdAt
                updatedAt
              }
              nextToken
            }
            video_quality_id
            video_quality {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          video_by_quality_id
          createdAt
          updatedAt
          video_by_quality {
            id
            hls_url
            dash_url
            status
            video_id
            video_quality_id
            video_quality {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              description
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              genres {
                nextToken
              }
              duration
              status
              release_date
              credits_start_timestamp
              video_tags {
                nextToken
              }
              language
              video_type_id
              video_type {
                id
                title
                status
                createdAt
                updatedAt
              }
              video_rating_id
              video_rating {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
          }
        }
        percentage_off
        country_id
        end_date
        promo_type_id
        promo_type {
          id
          name
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        country {
          id
          country_code
          country_name
          Currency
          status
          createdAt
          updatedAt
        }
      }
      purchased_at
      started_at
      expired_at
      expired
      twenty_five_percent_watched
      fifty_percent_watched
      seventy_five_percent_watched
      hundred_percent_watched
      video_plan_id
      video_plan {
        id
        status
        plan_id
        plan {
          id
          title
          status
          supported_devices {
            items {
              id
              supported_device_id
              plan_id
              supported_devices {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          video_quality_id
          video_quality {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        video_by_quality_id
        createdAt
        updatedAt
        video_by_quality {
          id
          hls_url
          dash_url
          status
          video_id
          video_quality_id
          video_quality {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          video {
            id
            title
            description
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              items {
                id
                genre_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            duration
            status
            release_date
            credits_start_timestamp
            video_tags {
              items {
                id
                tag_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            video_rating_id
            video_rating {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      status
      ref_no
      payment_total
      disabled
      payment_type_id
      user_id
      user {
        id
        email_address
        status
        createdAt
        updatedAt
      }
      promo_code_id
      promo_code {
        id
        code
        reuse
        count
        status
        video_plan_id
        video_plan {
          id
          status
          plan_id
          plan {
            id
            title
            status
            supported_devices {
              items {
                id
                supported_device_id
                plan_id
                createdAt
                updatedAt
              }
              nextToken
            }
            video_quality_id
            video_quality {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          video_by_quality_id
          createdAt
          updatedAt
          video_by_quality {
            id
            hls_url
            dash_url
            status
            video_id
            video_quality_id
            video_quality {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              description
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              genres {
                nextToken
              }
              duration
              status
              release_date
              credits_start_timestamp
              video_tags {
                nextToken
              }
              language
              video_type_id
              video_type {
                id
                title
                status
                createdAt
                updatedAt
              }
              video_rating_id
              video_rating {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
          }
        }
        percentage_off
        country_id
        end_date
        promo_type_id
        promo_type {
          id
          name
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        country {
          id
          country_code
          country_name
          Currency
          status
          createdAt
          updatedAt
        }
      }
      purchased_at
      started_at
      expired_at
      expired
      twenty_five_percent_watched
      fifty_percent_watched
      seventy_five_percent_watched
      hundred_percent_watched
      video_plan_id
      video_plan {
        id
        status
        plan_id
        plan {
          id
          title
          status
          supported_devices {
            items {
              id
              supported_device_id
              plan_id
              supported_devices {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          video_quality_id
          video_quality {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        video_by_quality_id
        createdAt
        updatedAt
        video_by_quality {
          id
          hls_url
          dash_url
          status
          video_id
          video_quality_id
          video_quality {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          video {
            id
            title
            description
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              items {
                id
                genre_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            duration
            status
            release_date
            credits_start_timestamp
            video_tags {
              items {
                id
                tag_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            video_rating_id
            video_rating {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
        }
      }
      createdAt
      updatedAt
      payment_type {
        id
        name
        disabled
        country_id
        createdAt
        updatedAt
        country {
          id
          country_code
          country_name
          Currency
          status
          createdAt
          updatedAt
        }
      }
    }
  }
`
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      status
      ref_no
      payment_total
      disabled
      payment_type_id
      user_id
      user {
        id
        email_address
        status
        createdAt
        updatedAt
      }
      promo_code_id
      promo_code {
        id
        code
        reuse
        count
        status
        video_plan_id
        video_plan {
          id
          status
          plan_id
          plan {
            id
            title
            status
            supported_devices {
              items {
                id
                supported_device_id
                plan_id
                createdAt
                updatedAt
              }
              nextToken
            }
            video_quality_id
            video_quality {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          video_by_quality_id
          createdAt
          updatedAt
          video_by_quality {
            id
            hls_url
            dash_url
            status
            video_id
            video_quality_id
            video_quality {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              description
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              genres {
                nextToken
              }
              duration
              status
              release_date
              credits_start_timestamp
              video_tags {
                nextToken
              }
              language
              video_type_id
              video_type {
                id
                title
                status
                createdAt
                updatedAt
              }
              video_rating_id
              video_rating {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
          }
        }
        percentage_off
        country_id
        end_date
        promo_type_id
        promo_type {
          id
          name
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        country {
          id
          country_code
          country_name
          Currency
          status
          createdAt
          updatedAt
        }
      }
      purchased_at
      started_at
      expired_at
      expired
      twenty_five_percent_watched
      fifty_percent_watched
      seventy_five_percent_watched
      hundred_percent_watched
      video_plan_id
      video_plan {
        id
        status
        plan_id
        plan {
          id
          title
          status
          supported_devices {
            items {
              id
              supported_device_id
              plan_id
              supported_devices {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          video_quality_id
          video_quality {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        video_by_quality_id
        createdAt
        updatedAt
        video_by_quality {
          id
          hls_url
          dash_url
          status
          video_id
          video_quality_id
          video_quality {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          video {
            id
            title
            description
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              items {
                id
                genre_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            duration
            status
            release_date
            credits_start_timestamp
            video_tags {
              items {
                id
                tag_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            video_rating_id
            video_rating {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
        }
      }
      createdAt
      updatedAt
      payment_type {
        id
        name
        disabled
        country_id
        createdAt
        updatedAt
        country {
          id
          country_code
          country_name
          Currency
          status
          createdAt
          updatedAt
        }
      }
    }
  }
`
export const createViewLog = /* GraphQL */ `
  mutation CreateViewLog(
    $input: CreateViewLogInput!
    $condition: ModelViewLogConditionInput
  ) {
    createViewLog(input: $input, condition: $condition) {
      id
      order_id
      order {
        id
        status
        ref_no
        payment_total
        disabled
        payment_type_id
        user_id
        user {
          id
          email_address
          status
          createdAt
          updatedAt
        }
        promo_code_id
        promo_code {
          id
          code
          reuse
          count
          status
          video_plan_id
          video_plan {
            id
            status
            plan_id
            plan {
              id
              title
              status
              supported_devices {
                nextToken
              }
              video_quality_id
              video_quality {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            video_by_quality_id
            createdAt
            updatedAt
            video_by_quality {
              id
              hls_url
              dash_url
              status
              video_id
              video_quality_id
              video_quality {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              video {
                id
                title
                description
                thumbnail_sm
                thumbnail_md
                thumbnail_lg
                duration
                status
                release_date
                credits_start_timestamp
                language
                video_type_id
                video_rating_id
                createdAt
                updatedAt
              }
            }
          }
          percentage_off
          country_id
          end_date
          promo_type_id
          promo_type {
            id
            name
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          country {
            id
            country_code
            country_name
            Currency
            status
            createdAt
            updatedAt
          }
        }
        purchased_at
        started_at
        expired_at
        expired
        twenty_five_percent_watched
        fifty_percent_watched
        seventy_five_percent_watched
        hundred_percent_watched
        video_plan_id
        video_plan {
          id
          status
          plan_id
          plan {
            id
            title
            status
            supported_devices {
              items {
                id
                supported_device_id
                plan_id
                createdAt
                updatedAt
              }
              nextToken
            }
            video_quality_id
            video_quality {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          video_by_quality_id
          createdAt
          updatedAt
          video_by_quality {
            id
            hls_url
            dash_url
            status
            video_id
            video_quality_id
            video_quality {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              description
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              genres {
                nextToken
              }
              duration
              status
              release_date
              credits_start_timestamp
              video_tags {
                nextToken
              }
              language
              video_type_id
              video_type {
                id
                title
                status
                createdAt
                updatedAt
              }
              video_rating_id
              video_rating {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
          }
        }
        createdAt
        updatedAt
        payment_type {
          id
          name
          disabled
          country_id
          createdAt
          updatedAt
          country {
            id
            country_code
            country_name
            Currency
            status
            createdAt
            updatedAt
          }
        }
      }
      status
      last_timestamp
      minutes_streamed
      started_at
      user_id
      user {
        id
        email_address
        status
        createdAt
        updatedAt
      }
      video_by_quality_id
      supported_device_id
      supported_device {
        id
        title
        status
        createdAt
        updatedAt
      }
      mac_id
      bandwidth_used
      createdAt
      updatedAt
      video_by_quality {
        id
        hls_url
        dash_url
        status
        video_id
        video_quality_id
        video_quality {
          id
          title
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        video {
          id
          title
          description
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          genres {
            items {
              id
              genre_id
              video_id
              genre {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          duration
          status
          release_date
          credits_start_timestamp
          video_tags {
            items {
              id
              tag_id
              video_id
              tag {
                id
                name
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          language
          video_type_id
          video_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          video_rating_id
          video_rating {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`
export const updateViewLog = /* GraphQL */ `
  mutation UpdateViewLog(
    $input: UpdateViewLogInput!
    $condition: ModelViewLogConditionInput
  ) {
    updateViewLog(input: $input, condition: $condition) {
      id
      order_id
      order {
        id
        status
        ref_no
        payment_total
        disabled
        payment_type_id
        user_id
        user {
          id
          email_address
          status
          createdAt
          updatedAt
        }
        promo_code_id
        promo_code {
          id
          code
          reuse
          count
          status
          video_plan_id
          video_plan {
            id
            status
            plan_id
            plan {
              id
              title
              status
              supported_devices {
                nextToken
              }
              video_quality_id
              video_quality {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            video_by_quality_id
            createdAt
            updatedAt
            video_by_quality {
              id
              hls_url
              dash_url
              status
              video_id
              video_quality_id
              video_quality {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              video {
                id
                title
                description
                thumbnail_sm
                thumbnail_md
                thumbnail_lg
                duration
                status
                release_date
                credits_start_timestamp
                language
                video_type_id
                video_rating_id
                createdAt
                updatedAt
              }
            }
          }
          percentage_off
          country_id
          end_date
          promo_type_id
          promo_type {
            id
            name
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          country {
            id
            country_code
            country_name
            Currency
            status
            createdAt
            updatedAt
          }
        }
        purchased_at
        started_at
        expired_at
        expired
        twenty_five_percent_watched
        fifty_percent_watched
        seventy_five_percent_watched
        hundred_percent_watched
        video_plan_id
        video_plan {
          id
          status
          plan_id
          plan {
            id
            title
            status
            supported_devices {
              items {
                id
                supported_device_id
                plan_id
                createdAt
                updatedAt
              }
              nextToken
            }
            video_quality_id
            video_quality {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          video_by_quality_id
          createdAt
          updatedAt
          video_by_quality {
            id
            hls_url
            dash_url
            status
            video_id
            video_quality_id
            video_quality {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              description
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              genres {
                nextToken
              }
              duration
              status
              release_date
              credits_start_timestamp
              video_tags {
                nextToken
              }
              language
              video_type_id
              video_type {
                id
                title
                status
                createdAt
                updatedAt
              }
              video_rating_id
              video_rating {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
          }
        }
        createdAt
        updatedAt
        payment_type {
          id
          name
          disabled
          country_id
          createdAt
          updatedAt
          country {
            id
            country_code
            country_name
            Currency
            status
            createdAt
            updatedAt
          }
        }
      }
      status
      last_timestamp
      minutes_streamed
      started_at
      user_id
      user {
        id
        email_address
        status
        createdAt
        updatedAt
      }
      video_by_quality_id
      supported_device_id
      supported_device {
        id
        title
        status
        createdAt
        updatedAt
      }
      mac_id
      bandwidth_used
      createdAt
      updatedAt
      video_by_quality {
        id
        hls_url
        dash_url
        status
        video_id
        video_quality_id
        video_quality {
          id
          title
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        video {
          id
          title
          description
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          genres {
            items {
              id
              genre_id
              video_id
              genre {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          duration
          status
          release_date
          credits_start_timestamp
          video_tags {
            items {
              id
              tag_id
              video_id
              tag {
                id
                name
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          language
          video_type_id
          video_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          video_rating_id
          video_rating {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`
export const deleteViewLog = /* GraphQL */ `
  mutation DeleteViewLog(
    $input: DeleteViewLogInput!
    $condition: ModelViewLogConditionInput
  ) {
    deleteViewLog(input: $input, condition: $condition) {
      id
      order_id
      order {
        id
        status
        ref_no
        payment_total
        disabled
        payment_type_id
        user_id
        user {
          id
          email_address
          status
          createdAt
          updatedAt
        }
        promo_code_id
        promo_code {
          id
          code
          reuse
          count
          status
          video_plan_id
          video_plan {
            id
            status
            plan_id
            plan {
              id
              title
              status
              supported_devices {
                nextToken
              }
              video_quality_id
              video_quality {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            video_by_quality_id
            createdAt
            updatedAt
            video_by_quality {
              id
              hls_url
              dash_url
              status
              video_id
              video_quality_id
              video_quality {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              video {
                id
                title
                description
                thumbnail_sm
                thumbnail_md
                thumbnail_lg
                duration
                status
                release_date
                credits_start_timestamp
                language
                video_type_id
                video_rating_id
                createdAt
                updatedAt
              }
            }
          }
          percentage_off
          country_id
          end_date
          promo_type_id
          promo_type {
            id
            name
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          country {
            id
            country_code
            country_name
            Currency
            status
            createdAt
            updatedAt
          }
        }
        purchased_at
        started_at
        expired_at
        expired
        twenty_five_percent_watched
        fifty_percent_watched
        seventy_five_percent_watched
        hundred_percent_watched
        video_plan_id
        video_plan {
          id
          status
          plan_id
          plan {
            id
            title
            status
            supported_devices {
              items {
                id
                supported_device_id
                plan_id
                createdAt
                updatedAt
              }
              nextToken
            }
            video_quality_id
            video_quality {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          video_by_quality_id
          createdAt
          updatedAt
          video_by_quality {
            id
            hls_url
            dash_url
            status
            video_id
            video_quality_id
            video_quality {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              description
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              genres {
                nextToken
              }
              duration
              status
              release_date
              credits_start_timestamp
              video_tags {
                nextToken
              }
              language
              video_type_id
              video_type {
                id
                title
                status
                createdAt
                updatedAt
              }
              video_rating_id
              video_rating {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
          }
        }
        createdAt
        updatedAt
        payment_type {
          id
          name
          disabled
          country_id
          createdAt
          updatedAt
          country {
            id
            country_code
            country_name
            Currency
            status
            createdAt
            updatedAt
          }
        }
      }
      status
      last_timestamp
      minutes_streamed
      started_at
      user_id
      user {
        id
        email_address
        status
        createdAt
        updatedAt
      }
      video_by_quality_id
      supported_device_id
      supported_device {
        id
        title
        status
        createdAt
        updatedAt
      }
      mac_id
      bandwidth_used
      createdAt
      updatedAt
      video_by_quality {
        id
        hls_url
        dash_url
        status
        video_id
        video_quality_id
        video_quality {
          id
          title
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        video {
          id
          title
          description
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          genres {
            items {
              id
              genre_id
              video_id
              genre {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          duration
          status
          release_date
          credits_start_timestamp
          video_tags {
            items {
              id
              tag_id
              video_id
              tag {
                id
                name
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          language
          video_type_id
          video_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          video_rating_id
          video_rating {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`
export const createSupportedDevice = /* GraphQL */ `
  mutation CreateSupportedDevice(
    $input: CreateSupportedDeviceInput!
    $condition: ModelSupportedDeviceConditionInput
  ) {
    createSupportedDevice(input: $input, condition: $condition) {
      id
      title
      status
      createdAt
      updatedAt
    }
  }
`
export const updateSupportedDevice = /* GraphQL */ `
  mutation UpdateSupportedDevice(
    $input: UpdateSupportedDeviceInput!
    $condition: ModelSupportedDeviceConditionInput
  ) {
    updateSupportedDevice(input: $input, condition: $condition) {
      id
      title
      status
      createdAt
      updatedAt
    }
  }
`
export const deleteSupportedDevice = /* GraphQL */ `
  mutation DeleteSupportedDevice(
    $input: DeleteSupportedDeviceInput!
    $condition: ModelSupportedDeviceConditionInput
  ) {
    deleteSupportedDevice(input: $input, condition: $condition) {
      id
      title
      status
      createdAt
      updatedAt
    }
  }
`
export const createPlanSupportedDevice = /* GraphQL */ `
  mutation CreatePlanSupportedDevice(
    $input: CreatePlanSupportedDeviceInput!
    $condition: ModelPlanSupportedDeviceConditionInput
  ) {
    createPlanSupportedDevice(input: $input, condition: $condition) {
      id
      supported_device_id
      plan_id
      supported_devices {
        id
        title
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
export const updatePlanSupportedDevice = /* GraphQL */ `
  mutation UpdatePlanSupportedDevice(
    $input: UpdatePlanSupportedDeviceInput!
    $condition: ModelPlanSupportedDeviceConditionInput
  ) {
    updatePlanSupportedDevice(input: $input, condition: $condition) {
      id
      supported_device_id
      plan_id
      supported_devices {
        id
        title
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
export const deletePlanSupportedDevice = /* GraphQL */ `
  mutation DeletePlanSupportedDevice(
    $input: DeletePlanSupportedDeviceInput!
    $condition: ModelPlanSupportedDeviceConditionInput
  ) {
    deletePlanSupportedDevice(input: $input, condition: $condition) {
      id
      supported_device_id
      plan_id
      supported_devices {
        id
        title
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
export const createPlan = /* GraphQL */ `
  mutation CreatePlan(
    $input: CreatePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    createPlan(input: $input, condition: $condition) {
      id
      title
      status
      supported_devices {
        items {
          id
          supported_device_id
          plan_id
          supported_devices {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      video_quality_id
      video_quality {
        id
        title
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
export const updatePlan = /* GraphQL */ `
  mutation UpdatePlan(
    $input: UpdatePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    updatePlan(input: $input, condition: $condition) {
      id
      title
      status
      supported_devices {
        items {
          id
          supported_device_id
          plan_id
          supported_devices {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      video_quality_id
      video_quality {
        id
        title
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
export const deletePlan = /* GraphQL */ `
  mutation DeletePlan(
    $input: DeletePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    deletePlan(input: $input, condition: $condition) {
      id
      title
      status
      supported_devices {
        items {
          id
          supported_device_id
          plan_id
          supported_devices {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      video_quality_id
      video_quality {
        id
        title
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
export const createVideoPlan = /* GraphQL */ `
  mutation CreateVideoPlan(
    $input: CreateVideoPlanInput!
    $condition: ModelVideoPlanConditionInput
  ) {
    createVideoPlan(input: $input, condition: $condition) {
      id
      status
      plan_id
      plan {
        id
        title
        status
        supported_devices {
          items {
            id
            supported_device_id
            plan_id
            supported_devices {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        video_quality_id
        video_quality {
          id
          title
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      video_by_quality_id
      createdAt
      updatedAt
      video_by_quality {
        id
        hls_url
        dash_url
        status
        video_id
        video_quality_id
        video_quality {
          id
          title
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        video {
          id
          title
          description
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          genres {
            items {
              id
              genre_id
              video_id
              genre {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          duration
          status
          release_date
          credits_start_timestamp
          video_tags {
            items {
              id
              tag_id
              video_id
              tag {
                id
                name
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          language
          video_type_id
          video_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          video_rating_id
          video_rating {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`
export const updateVideoPlan = /* GraphQL */ `
  mutation UpdateVideoPlan(
    $input: UpdateVideoPlanInput!
    $condition: ModelVideoPlanConditionInput
  ) {
    updateVideoPlan(input: $input, condition: $condition) {
      id
      status
      plan_id
      plan {
        id
        title
        status
        supported_devices {
          items {
            id
            supported_device_id
            plan_id
            supported_devices {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        video_quality_id
        video_quality {
          id
          title
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      video_by_quality_id
      createdAt
      updatedAt
      video_by_quality {
        id
        hls_url
        dash_url
        status
        video_id
        video_quality_id
        video_quality {
          id
          title
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        video {
          id
          title
          description
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          genres {
            items {
              id
              genre_id
              video_id
              genre {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          duration
          status
          release_date
          credits_start_timestamp
          video_tags {
            items {
              id
              tag_id
              video_id
              tag {
                id
                name
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          language
          video_type_id
          video_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          video_rating_id
          video_rating {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`
export const deleteVideoPlan = /* GraphQL */ `
  mutation DeleteVideoPlan(
    $input: DeleteVideoPlanInput!
    $condition: ModelVideoPlanConditionInput
  ) {
    deleteVideoPlan(input: $input, condition: $condition) {
      id
      status
      plan_id
      plan {
        id
        title
        status
        supported_devices {
          items {
            id
            supported_device_id
            plan_id
            supported_devices {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        video_quality_id
        video_quality {
          id
          title
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      video_by_quality_id
      createdAt
      updatedAt
      video_by_quality {
        id
        hls_url
        dash_url
        status
        video_id
        video_quality_id
        video_quality {
          id
          title
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        video {
          id
          title
          description
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          genres {
            items {
              id
              genre_id
              video_id
              genre {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          duration
          status
          release_date
          credits_start_timestamp
          video_tags {
            items {
              id
              tag_id
              video_id
              tag {
                id
                name
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          language
          video_type_id
          video_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          video_rating_id
          video_rating {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`
export const createPricing = /* GraphQL */ `
  mutation CreatePricing(
    $input: CreatePricingInput!
    $condition: ModelPricingConditionInput
  ) {
    createPricing(input: $input, condition: $condition) {
      id
      country_id
      price
      status
      video_plan_id
      video_plan {
        id
        status
        plan_id
        plan {
          id
          title
          status
          supported_devices {
            items {
              id
              supported_device_id
              plan_id
              supported_devices {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          video_quality_id
          video_quality {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        video_by_quality_id
        createdAt
        updatedAt
        video_by_quality {
          id
          hls_url
          dash_url
          status
          video_id
          video_quality_id
          video_quality {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          video {
            id
            title
            description
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              items {
                id
                genre_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            duration
            status
            release_date
            credits_start_timestamp
            video_tags {
              items {
                id
                tag_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            video_rating_id
            video_rating {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
        }
      }
      video_id
      createdAt
      updatedAt
      country {
        id
        country_code
        country_name
        Currency
        status
        createdAt
        updatedAt
      }
      video {
        id
        title
        description
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        duration
        status
        release_date
        credits_start_timestamp
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        video_rating_id
        video_rating {
          id
          title
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`
export const updatePricing = /* GraphQL */ `
  mutation UpdatePricing(
    $input: UpdatePricingInput!
    $condition: ModelPricingConditionInput
  ) {
    updatePricing(input: $input, condition: $condition) {
      id
      country_id
      price
      status
      video_plan_id
      video_plan {
        id
        status
        plan_id
        plan {
          id
          title
          status
          supported_devices {
            items {
              id
              supported_device_id
              plan_id
              supported_devices {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          video_quality_id
          video_quality {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        video_by_quality_id
        createdAt
        updatedAt
        video_by_quality {
          id
          hls_url
          dash_url
          status
          video_id
          video_quality_id
          video_quality {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          video {
            id
            title
            description
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              items {
                id
                genre_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            duration
            status
            release_date
            credits_start_timestamp
            video_tags {
              items {
                id
                tag_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            video_rating_id
            video_rating {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
        }
      }
      video_id
      createdAt
      updatedAt
      country {
        id
        country_code
        country_name
        Currency
        status
        createdAt
        updatedAt
      }
      video {
        id
        title
        description
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        duration
        status
        release_date
        credits_start_timestamp
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        video_rating_id
        video_rating {
          id
          title
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`
export const deletePricing = /* GraphQL */ `
  mutation DeletePricing(
    $input: DeletePricingInput!
    $condition: ModelPricingConditionInput
  ) {
    deletePricing(input: $input, condition: $condition) {
      id
      country_id
      price
      status
      video_plan_id
      video_plan {
        id
        status
        plan_id
        plan {
          id
          title
          status
          supported_devices {
            items {
              id
              supported_device_id
              plan_id
              supported_devices {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          video_quality_id
          video_quality {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        video_by_quality_id
        createdAt
        updatedAt
        video_by_quality {
          id
          hls_url
          dash_url
          status
          video_id
          video_quality_id
          video_quality {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          video {
            id
            title
            description
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              items {
                id
                genre_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            duration
            status
            release_date
            credits_start_timestamp
            video_tags {
              items {
                id
                tag_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            video_rating_id
            video_rating {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
        }
      }
      video_id
      createdAt
      updatedAt
      country {
        id
        country_code
        country_name
        Currency
        status
        createdAt
        updatedAt
      }
      video {
        id
        title
        description
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        duration
        status
        release_date
        credits_start_timestamp
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        video_rating_id
        video_rating {
          id
          title
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`
export const createPromoType = /* GraphQL */ `
  mutation CreatePromoType(
    $input: CreatePromoTypeInput!
    $condition: ModelPromoTypeConditionInput
  ) {
    createPromoType(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`
export const updatePromoType = /* GraphQL */ `
  mutation UpdatePromoType(
    $input: UpdatePromoTypeInput!
    $condition: ModelPromoTypeConditionInput
  ) {
    updatePromoType(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`
export const deletePromoType = /* GraphQL */ `
  mutation DeletePromoType(
    $input: DeletePromoTypeInput!
    $condition: ModelPromoTypeConditionInput
  ) {
    deletePromoType(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`
export const createPromoCode = /* GraphQL */ `
  mutation CreatePromoCode(
    $input: CreatePromoCodeInput!
    $condition: ModelPromoCodeConditionInput
  ) {
    createPromoCode(input: $input, condition: $condition) {
      id
      code
      reuse
      count
      status
      video_plan_id
      video_plan {
        id
        status
        plan_id
        plan {
          id
          title
          status
          supported_devices {
            items {
              id
              supported_device_id
              plan_id
              supported_devices {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          video_quality_id
          video_quality {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        video_by_quality_id
        createdAt
        updatedAt
        video_by_quality {
          id
          hls_url
          dash_url
          status
          video_id
          video_quality_id
          video_quality {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          video {
            id
            title
            description
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              items {
                id
                genre_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            duration
            status
            release_date
            credits_start_timestamp
            video_tags {
              items {
                id
                tag_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            video_rating_id
            video_rating {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
        }
      }
      percentage_off
      country_id
      end_date
      promo_type_id
      promo_type {
        id
        name
        description
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      country {
        id
        country_code
        country_name
        Currency
        status
        createdAt
        updatedAt
      }
    }
  }
`
export const updatePromoCode = /* GraphQL */ `
  mutation UpdatePromoCode(
    $input: UpdatePromoCodeInput!
    $condition: ModelPromoCodeConditionInput
  ) {
    updatePromoCode(input: $input, condition: $condition) {
      id
      code
      reuse
      count
      status
      video_plan_id
      video_plan {
        id
        status
        plan_id
        plan {
          id
          title
          status
          supported_devices {
            items {
              id
              supported_device_id
              plan_id
              supported_devices {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          video_quality_id
          video_quality {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        video_by_quality_id
        createdAt
        updatedAt
        video_by_quality {
          id
          hls_url
          dash_url
          status
          video_id
          video_quality_id
          video_quality {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          video {
            id
            title
            description
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              items {
                id
                genre_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            duration
            status
            release_date
            credits_start_timestamp
            video_tags {
              items {
                id
                tag_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            video_rating_id
            video_rating {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
        }
      }
      percentage_off
      country_id
      end_date
      promo_type_id
      promo_type {
        id
        name
        description
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      country {
        id
        country_code
        country_name
        Currency
        status
        createdAt
        updatedAt
      }
    }
  }
`
export const deletePromoCode = /* GraphQL */ `
  mutation DeletePromoCode(
    $input: DeletePromoCodeInput!
    $condition: ModelPromoCodeConditionInput
  ) {
    deletePromoCode(input: $input, condition: $condition) {
      id
      code
      reuse
      count
      status
      video_plan_id
      video_plan {
        id
        status
        plan_id
        plan {
          id
          title
          status
          supported_devices {
            items {
              id
              supported_device_id
              plan_id
              supported_devices {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          video_quality_id
          video_quality {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        video_by_quality_id
        createdAt
        updatedAt
        video_by_quality {
          id
          hls_url
          dash_url
          status
          video_id
          video_quality_id
          video_quality {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          video {
            id
            title
            description
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              items {
                id
                genre_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            duration
            status
            release_date
            credits_start_timestamp
            video_tags {
              items {
                id
                tag_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            video_rating_id
            video_rating {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
        }
      }
      percentage_off
      country_id
      end_date
      promo_type_id
      promo_type {
        id
        name
        description
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      country {
        id
        country_code
        country_name
        Currency
        status
        createdAt
        updatedAt
      }
    }
  }
`
export const createSupportedCaption = /* GraphQL */ `
  mutation CreateSupportedCaption(
    $input: CreateSupportedCaptionInput!
    $condition: ModelSupportedCaptionConditionInput
  ) {
    createSupportedCaption(input: $input, condition: $condition) {
      id
      caption
      url
      status
      video_id
      createdAt
      updatedAt
    }
  }
`
export const updateSupportedCaption = /* GraphQL */ `
  mutation UpdateSupportedCaption(
    $input: UpdateSupportedCaptionInput!
    $condition: ModelSupportedCaptionConditionInput
  ) {
    updateSupportedCaption(input: $input, condition: $condition) {
      id
      caption
      url
      status
      video_id
      createdAt
      updatedAt
    }
  }
`
export const deleteSupportedCaption = /* GraphQL */ `
  mutation DeleteSupportedCaption(
    $input: DeleteSupportedCaptionInput!
    $condition: ModelSupportedCaptionConditionInput
  ) {
    deleteSupportedCaption(input: $input, condition: $condition) {
      id
      caption
      url
      status
      video_id
      createdAt
      updatedAt
    }
  }
`
export const createTrailer = /* GraphQL */ `
  mutation CreateTrailer(
    $input: CreateTrailerInput!
    $condition: ModelTrailerConditionInput
  ) {
    createTrailer(input: $input, condition: $condition) {
      id
      hls_url
      dash_url
      status
      thumbnail_url
      video_id
      createdAt
      updatedAt
      video {
        id
        title
        description
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        duration
        status
        release_date
        credits_start_timestamp
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        video_rating_id
        video_rating {
          id
          title
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`
export const updateTrailer = /* GraphQL */ `
  mutation UpdateTrailer(
    $input: UpdateTrailerInput!
    $condition: ModelTrailerConditionInput
  ) {
    updateTrailer(input: $input, condition: $condition) {
      id
      hls_url
      dash_url
      status
      thumbnail_url
      video_id
      createdAt
      updatedAt
      video {
        id
        title
        description
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        duration
        status
        release_date
        credits_start_timestamp
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        video_rating_id
        video_rating {
          id
          title
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`
export const deleteTrailer = /* GraphQL */ `
  mutation DeleteTrailer(
    $input: DeleteTrailerInput!
    $condition: ModelTrailerConditionInput
  ) {
    deleteTrailer(input: $input, condition: $condition) {
      id
      hls_url
      dash_url
      status
      thumbnail_url
      video_id
      createdAt
      updatedAt
      video {
        id
        title
        description
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        duration
        status
        release_date
        credits_start_timestamp
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        video_rating_id
        video_rating {
          id
          title
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`
export const createTrailerView = /* GraphQL */ `
  mutation CreateTrailerView(
    $input: CreateTrailerViewInput!
    $condition: ModelTrailerViewConditionInput
  ) {
    createTrailerView(input: $input, condition: $condition) {
      id
      trailer_id
      user_id
      watched_at
      user {
        id
        email_address
        status
        createdAt
        updatedAt
      }
      trailer {
        id
        hls_url
        dash_url
        status
        thumbnail_url
        video_id
        createdAt
        updatedAt
        video {
          id
          title
          description
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          genres {
            items {
              id
              genre_id
              video_id
              genre {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          duration
          status
          release_date
          credits_start_timestamp
          video_tags {
            items {
              id
              tag_id
              video_id
              tag {
                id
                name
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          language
          video_type_id
          video_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          video_rating_id
          video_rating {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const updateTrailerView = /* GraphQL */ `
  mutation UpdateTrailerView(
    $input: UpdateTrailerViewInput!
    $condition: ModelTrailerViewConditionInput
  ) {
    updateTrailerView(input: $input, condition: $condition) {
      id
      trailer_id
      user_id
      watched_at
      user {
        id
        email_address
        status
        createdAt
        updatedAt
      }
      trailer {
        id
        hls_url
        dash_url
        status
        thumbnail_url
        video_id
        createdAt
        updatedAt
        video {
          id
          title
          description
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          genres {
            items {
              id
              genre_id
              video_id
              genre {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          duration
          status
          release_date
          credits_start_timestamp
          video_tags {
            items {
              id
              tag_id
              video_id
              tag {
                id
                name
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          language
          video_type_id
          video_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          video_rating_id
          video_rating {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const deleteTrailerView = /* GraphQL */ `
  mutation DeleteTrailerView(
    $input: DeleteTrailerViewInput!
    $condition: ModelTrailerViewConditionInput
  ) {
    deleteTrailerView(input: $input, condition: $condition) {
      id
      trailer_id
      user_id
      watched_at
      user {
        id
        email_address
        status
        createdAt
        updatedAt
      }
      trailer {
        id
        hls_url
        dash_url
        status
        thumbnail_url
        video_id
        createdAt
        updatedAt
        video {
          id
          title
          description
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          genres {
            items {
              id
              genre_id
              video_id
              genre {
                id
                title
                status
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          duration
          status
          release_date
          credits_start_timestamp
          video_tags {
            items {
              id
              tag_id
              video_id
              tag {
                id
                name
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          language
          video_type_id
          video_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          video_rating_id
          video_rating {
            id
            title
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email_address
      status
      createdAt
      updatedAt
    }
  }
`
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email_address
      status
      createdAt
      updatedAt
    }
  }
`
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      email_address
      status
      createdAt
      updatedAt
    }
  }
`
export const createUserActivityLog = /* GraphQL */ `
  mutation CreateUserActivityLog(
    $input: CreateUserActivityLogInput!
    $condition: ModelUserActivityLogConditionInput
  ) {
    createUserActivityLog(input: $input, condition: $condition) {
      id
      action_id
      action {
        id
        description
        status
        createdAt
        updatedAt
      }
      user_id
      user {
        id
        email_address
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
export const updateUserActivityLog = /* GraphQL */ `
  mutation UpdateUserActivityLog(
    $input: UpdateUserActivityLogInput!
    $condition: ModelUserActivityLogConditionInput
  ) {
    updateUserActivityLog(input: $input, condition: $condition) {
      id
      action_id
      action {
        id
        description
        status
        createdAt
        updatedAt
      }
      user_id
      user {
        id
        email_address
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
export const deleteUserActivityLog = /* GraphQL */ `
  mutation DeleteUserActivityLog(
    $input: DeleteUserActivityLogInput!
    $condition: ModelUserActivityLogConditionInput
  ) {
    deleteUserActivityLog(input: $input, condition: $condition) {
      id
      action_id
      action {
        id
        description
        status
        createdAt
        updatedAt
      }
      user_id
      user {
        id
        email_address
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
export const createUserActivityAction = /* GraphQL */ `
  mutation CreateUserActivityAction(
    $input: CreateUserActivityActionInput!
    $condition: ModelUserActivityActionConditionInput
  ) {
    createUserActivityAction(input: $input, condition: $condition) {
      id
      description
      status
      createdAt
      updatedAt
    }
  }
`
export const updateUserActivityAction = /* GraphQL */ `
  mutation UpdateUserActivityAction(
    $input: UpdateUserActivityActionInput!
    $condition: ModelUserActivityActionConditionInput
  ) {
    updateUserActivityAction(input: $input, condition: $condition) {
      id
      description
      status
      createdAt
      updatedAt
    }
  }
`
export const deleteUserActivityAction = /* GraphQL */ `
  mutation DeleteUserActivityAction(
    $input: DeleteUserActivityActionInput!
    $condition: ModelUserActivityActionConditionInput
  ) {
    deleteUserActivityAction(input: $input, condition: $condition) {
      id
      description
      status
      createdAt
      updatedAt
    }
  }
`
export const createAvatar = /* GraphQL */ `
  mutation CreateAvatar(
    $input: CreateAvatarInput!
    $condition: ModelAvatarConditionInput
  ) {
    createAvatar(input: $input, condition: $condition) {
      id
      name
      url
      status
      createdAt
      updatedAt
    }
  }
`
export const updateAvatar = /* GraphQL */ `
  mutation UpdateAvatar(
    $input: UpdateAvatarInput!
    $condition: ModelAvatarConditionInput
  ) {
    updateAvatar(input: $input, condition: $condition) {
      id
      name
      url
      status
      createdAt
      updatedAt
    }
  }
`
export const deleteAvatar = /* GraphQL */ `
  mutation DeleteAvatar(
    $input: DeleteAvatarInput!
    $condition: ModelAvatarConditionInput
  ) {
    deleteAvatar(input: $input, condition: $condition) {
      id
      name
      url
      status
      createdAt
      updatedAt
    }
  }
`
export const createVideoTag = /* GraphQL */ `
  mutation CreateVideoTag(
    $input: CreateVideoTagInput!
    $condition: ModelVideoTagConditionInput
  ) {
    createVideoTag(input: $input, condition: $condition) {
      id
      tag_id
      video_id
      tag {
        id
        name
        videos {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
export const updateVideoTag = /* GraphQL */ `
  mutation UpdateVideoTag(
    $input: UpdateVideoTagInput!
    $condition: ModelVideoTagConditionInput
  ) {
    updateVideoTag(input: $input, condition: $condition) {
      id
      tag_id
      video_id
      tag {
        id
        name
        videos {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
export const deleteVideoTag = /* GraphQL */ `
  mutation DeleteVideoTag(
    $input: DeleteVideoTagInput!
    $condition: ModelVideoTagConditionInput
  ) {
    deleteVideoTag(input: $input, condition: $condition) {
      id
      tag_id
      video_id
      tag {
        id
        name
        videos {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
export const createTag = /* GraphQL */ `
  mutation CreateTag(
    $input: CreateTagInput!
    $condition: ModelTagConditionInput
  ) {
    createTag(input: $input, condition: $condition) {
      id
      name
      videos {
        items {
          id
          tag_id
          video_id
          tag {
            id
            name
            videos {
              items {
                id
                tag_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const updateTag = /* GraphQL */ `
  mutation UpdateTag(
    $input: UpdateTagInput!
    $condition: ModelTagConditionInput
  ) {
    updateTag(input: $input, condition: $condition) {
      id
      name
      videos {
        items {
          id
          tag_id
          video_id
          tag {
            id
            name
            videos {
              items {
                id
                tag_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const deleteTag = /* GraphQL */ `
  mutation DeleteTag(
    $input: DeleteTagInput!
    $condition: ModelTagConditionInput
  ) {
    deleteTag(input: $input, condition: $condition) {
      id
      name
      videos {
        items {
          id
          tag_id
          video_id
          tag {
            id
            name
            videos {
              items {
                id
                tag_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const createVideoGenre = /* GraphQL */ `
  mutation CreateVideoGenre(
    $input: CreateVideoGenreInput!
    $condition: ModelVideoGenreConditionInput
  ) {
    createVideoGenre(input: $input, condition: $condition) {
      id
      genre_id
      video_id
      genre {
        id
        title
        status
        videos {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
export const updateVideoGenre = /* GraphQL */ `
  mutation UpdateVideoGenre(
    $input: UpdateVideoGenreInput!
    $condition: ModelVideoGenreConditionInput
  ) {
    updateVideoGenre(input: $input, condition: $condition) {
      id
      genre_id
      video_id
      genre {
        id
        title
        status
        videos {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
export const deleteVideoGenre = /* GraphQL */ `
  mutation DeleteVideoGenre(
    $input: DeleteVideoGenreInput!
    $condition: ModelVideoGenreConditionInput
  ) {
    deleteVideoGenre(input: $input, condition: $condition) {
      id
      genre_id
      video_id
      genre {
        id
        title
        status
        videos {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
export const createGenre = /* GraphQL */ `
  mutation CreateGenre(
    $input: CreateGenreInput!
    $condition: ModelGenreConditionInput
  ) {
    createGenre(input: $input, condition: $condition) {
      id
      title
      status
      videos {
        items {
          id
          genre_id
          video_id
          genre {
            id
            title
            status
            videos {
              items {
                id
                genre_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const updateGenre = /* GraphQL */ `
  mutation UpdateGenre(
    $input: UpdateGenreInput!
    $condition: ModelGenreConditionInput
  ) {
    updateGenre(input: $input, condition: $condition) {
      id
      title
      status
      videos {
        items {
          id
          genre_id
          video_id
          genre {
            id
            title
            status
            videos {
              items {
                id
                genre_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const deleteGenre = /* GraphQL */ `
  mutation DeleteGenre(
    $input: DeleteGenreInput!
    $condition: ModelGenreConditionInput
  ) {
    deleteGenre(input: $input, condition: $condition) {
      id
      title
      status
      videos {
        items {
          id
          genre_id
          video_id
          genre {
            id
            title
            status
            videos {
              items {
                id
                genre_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const createVideoType = /* GraphQL */ `
  mutation CreateVideoType(
    $input: CreateVideoTypeInput!
    $condition: ModelVideoTypeConditionInput
  ) {
    createVideoType(input: $input, condition: $condition) {
      id
      title
      status
      createdAt
      updatedAt
    }
  }
`
export const updateVideoType = /* GraphQL */ `
  mutation UpdateVideoType(
    $input: UpdateVideoTypeInput!
    $condition: ModelVideoTypeConditionInput
  ) {
    updateVideoType(input: $input, condition: $condition) {
      id
      title
      status
      createdAt
      updatedAt
    }
  }
`
export const deleteVideoType = /* GraphQL */ `
  mutation DeleteVideoType(
    $input: DeleteVideoTypeInput!
    $condition: ModelVideoTypeConditionInput
  ) {
    deleteVideoType(input: $input, condition: $condition) {
      id
      title
      status
      createdAt
      updatedAt
    }
  }
`
export const createVideoQuality = /* GraphQL */ `
  mutation CreateVideoQuality(
    $input: CreateVideoQualityInput!
    $condition: ModelVideoQualityConditionInput
  ) {
    createVideoQuality(input: $input, condition: $condition) {
      id
      title
      status
      createdAt
      updatedAt
    }
  }
`
export const updateVideoQuality = /* GraphQL */ `
  mutation UpdateVideoQuality(
    $input: UpdateVideoQualityInput!
    $condition: ModelVideoQualityConditionInput
  ) {
    updateVideoQuality(input: $input, condition: $condition) {
      id
      title
      status
      createdAt
      updatedAt
    }
  }
`
export const deleteVideoQuality = /* GraphQL */ `
  mutation DeleteVideoQuality(
    $input: DeleteVideoQualityInput!
    $condition: ModelVideoQualityConditionInput
  ) {
    deleteVideoQuality(input: $input, condition: $condition) {
      id
      title
      status
      createdAt
      updatedAt
    }
  }
`
export const createCastCrewType = /* GraphQL */ `
  mutation CreateCastCrewType(
    $input: CreateCastCrewTypeInput!
    $condition: ModelCastCrewTypeConditionInput
  ) {
    createCastCrewType(input: $input, condition: $condition) {
      id
      title
      status
      createdAt
      updatedAt
    }
  }
`
export const updateCastCrewType = /* GraphQL */ `
  mutation UpdateCastCrewType(
    $input: UpdateCastCrewTypeInput!
    $condition: ModelCastCrewTypeConditionInput
  ) {
    updateCastCrewType(input: $input, condition: $condition) {
      id
      title
      status
      createdAt
      updatedAt
    }
  }
`
export const deleteCastCrewType = /* GraphQL */ `
  mutation DeleteCastCrewType(
    $input: DeleteCastCrewTypeInput!
    $condition: ModelCastCrewTypeConditionInput
  ) {
    deleteCastCrewType(input: $input, condition: $condition) {
      id
      title
      status
      createdAt
      updatedAt
    }
  }
`
export const createCastCrew = /* GraphQL */ `
  mutation CreateCastCrew(
    $input: CreateCastCrewInput!
    $condition: ModelCastCrewConditionInput
  ) {
    createCastCrew(input: $input, condition: $condition) {
      id
      first_name
      last_name
      nick_name
      description
      image_url
      status
      createdAt
      updatedAt
    }
  }
`
export const updateCastCrew = /* GraphQL */ `
  mutation UpdateCastCrew(
    $input: UpdateCastCrewInput!
    $condition: ModelCastCrewConditionInput
  ) {
    updateCastCrew(input: $input, condition: $condition) {
      id
      first_name
      last_name
      nick_name
      description
      image_url
      status
      createdAt
      updatedAt
    }
  }
`
export const deleteCastCrew = /* GraphQL */ `
  mutation DeleteCastCrew(
    $input: DeleteCastCrewInput!
    $condition: ModelCastCrewConditionInput
  ) {
    deleteCastCrew(input: $input, condition: $condition) {
      id
      first_name
      last_name
      nick_name
      description
      image_url
      status
      createdAt
      updatedAt
    }
  }
`
export const createVideoCastCrew = /* GraphQL */ `
  mutation CreateVideoCastCrew(
    $input: CreateVideoCastCrewInput!
    $condition: ModelVideoCastCrewConditionInput
  ) {
    createVideoCastCrew(input: $input, condition: $condition) {
      id
      video_id
      cast_crew_id
      status
      cast_crew_type_id
      cast_crew_type {
        id
        title
        status
        createdAt
        updatedAt
      }
      display
      createdAt
      updatedAt
    }
  }
`
export const updateVideoCastCrew = /* GraphQL */ `
  mutation UpdateVideoCastCrew(
    $input: UpdateVideoCastCrewInput!
    $condition: ModelVideoCastCrewConditionInput
  ) {
    updateVideoCastCrew(input: $input, condition: $condition) {
      id
      video_id
      cast_crew_id
      status
      cast_crew_type_id
      cast_crew_type {
        id
        title
        status
        createdAt
        updatedAt
      }
      display
      createdAt
      updatedAt
    }
  }
`
export const deleteVideoCastCrew = /* GraphQL */ `
  mutation DeleteVideoCastCrew(
    $input: DeleteVideoCastCrewInput!
    $condition: ModelVideoCastCrewConditionInput
  ) {
    deleteVideoCastCrew(input: $input, condition: $condition) {
      id
      video_id
      cast_crew_id
      status
      cast_crew_type_id
      cast_crew_type {
        id
        title
        status
        createdAt
        updatedAt
      }
      display
      createdAt
      updatedAt
    }
  }
`
export const createVideoRating = /* GraphQL */ `
  mutation CreateVideoRating(
    $input: CreateVideoRatingInput!
    $condition: ModelVideoRatingConditionInput
  ) {
    createVideoRating(input: $input, condition: $condition) {
      id
      title
      status
      createdAt
      updatedAt
    }
  }
`
export const updateVideoRating = /* GraphQL */ `
  mutation UpdateVideoRating(
    $input: UpdateVideoRatingInput!
    $condition: ModelVideoRatingConditionInput
  ) {
    updateVideoRating(input: $input, condition: $condition) {
      id
      title
      status
      createdAt
      updatedAt
    }
  }
`
export const deleteVideoRating = /* GraphQL */ `
  mutation DeleteVideoRating(
    $input: DeleteVideoRatingInput!
    $condition: ModelVideoRatingConditionInput
  ) {
    deleteVideoRating(input: $input, condition: $condition) {
      id
      title
      status
      createdAt
      updatedAt
    }
  }
`
export const createVideoRatingsByUser = /* GraphQL */ `
  mutation CreateVideoRatingsByUser(
    $input: CreateVideoRatingsByUserInput!
    $condition: ModelVideoRatingsByUserConditionInput
  ) {
    createVideoRatingsByUser(input: $input, condition: $condition) {
      id
      video_id
      rating
      user_id
      user {
        id
        email_address
        status
        createdAt
        updatedAt
      }
      rating_on_timestamp
      createdAt
      updatedAt
      video {
        id
        title
        description
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        duration
        status
        release_date
        credits_start_timestamp
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        video_rating_id
        video_rating {
          id
          title
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`
export const updateVideoRatingsByUser = /* GraphQL */ `
  mutation UpdateVideoRatingsByUser(
    $input: UpdateVideoRatingsByUserInput!
    $condition: ModelVideoRatingsByUserConditionInput
  ) {
    updateVideoRatingsByUser(input: $input, condition: $condition) {
      id
      video_id
      rating
      user_id
      user {
        id
        email_address
        status
        createdAt
        updatedAt
      }
      rating_on_timestamp
      createdAt
      updatedAt
      video {
        id
        title
        description
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        duration
        status
        release_date
        credits_start_timestamp
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        video_rating_id
        video_rating {
          id
          title
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`
export const deleteVideoRatingsByUser = /* GraphQL */ `
  mutation DeleteVideoRatingsByUser(
    $input: DeleteVideoRatingsByUserInput!
    $condition: ModelVideoRatingsByUserConditionInput
  ) {
    deleteVideoRatingsByUser(input: $input, condition: $condition) {
      id
      video_id
      rating
      user_id
      user {
        id
        email_address
        status
        createdAt
        updatedAt
      }
      rating_on_timestamp
      createdAt
      updatedAt
      video {
        id
        title
        description
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        duration
        status
        release_date
        credits_start_timestamp
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        video_rating_id
        video_rating {
          id
          title
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`
export const createCountry = /* GraphQL */ `
  mutation CreateCountry(
    $input: CreateCountryInput!
    $condition: ModelCountryConditionInput
  ) {
    createCountry(input: $input, condition: $condition) {
      id
      country_code
      country_name
      Currency
      status
      createdAt
      updatedAt
    }
  }
`
export const updateCountry = /* GraphQL */ `
  mutation UpdateCountry(
    $input: UpdateCountryInput!
    $condition: ModelCountryConditionInput
  ) {
    updateCountry(input: $input, condition: $condition) {
      id
      country_code
      country_name
      Currency
      status
      createdAt
      updatedAt
    }
  }
`
export const deleteCountry = /* GraphQL */ `
  mutation DeleteCountry(
    $input: DeleteCountryInput!
    $condition: ModelCountryConditionInput
  ) {
    deleteCountry(input: $input, condition: $condition) {
      id
      country_code
      country_name
      Currency
      status
      createdAt
      updatedAt
    }
  }
`
export const createPaymentProcessingStatus = /* GraphQL */ `
  mutation CreatePaymentProcessingStatus(
    $input: CreatePaymentProcessingStatusInput!
    $condition: ModelPaymentProcessingStatusConditionInput
  ) {
    createPaymentProcessingStatus(input: $input, condition: $condition) {
      id
      status
      createdAt
      updatedAt
    }
  }
`
export const updatePaymentProcessingStatus = /* GraphQL */ `
  mutation UpdatePaymentProcessingStatus(
    $input: UpdatePaymentProcessingStatusInput!
    $condition: ModelPaymentProcessingStatusConditionInput
  ) {
    updatePaymentProcessingStatus(input: $input, condition: $condition) {
      id
      status
      createdAt
      updatedAt
    }
  }
`
export const deletePaymentProcessingStatus = /* GraphQL */ `
  mutation DeletePaymentProcessingStatus(
    $input: DeletePaymentProcessingStatusInput!
    $condition: ModelPaymentProcessingStatusConditionInput
  ) {
    deletePaymentProcessingStatus(input: $input, condition: $condition) {
      id
      status
      createdAt
      updatedAt
    }
  }
`
export const createPaymentType = /* GraphQL */ `
  mutation CreatePaymentType(
    $input: CreatePaymentTypeInput!
    $condition: ModelPaymentTypeConditionInput
  ) {
    createPaymentType(input: $input, condition: $condition) {
      id
      name
      disabled
      country_id
      createdAt
      updatedAt
      country {
        id
        country_code
        country_name
        Currency
        status
        createdAt
        updatedAt
      }
    }
  }
`
export const updatePaymentType = /* GraphQL */ `
  mutation UpdatePaymentType(
    $input: UpdatePaymentTypeInput!
    $condition: ModelPaymentTypeConditionInput
  ) {
    updatePaymentType(input: $input, condition: $condition) {
      id
      name
      disabled
      country_id
      createdAt
      updatedAt
      country {
        id
        country_code
        country_name
        Currency
        status
        createdAt
        updatedAt
      }
    }
  }
`
export const deletePaymentType = /* GraphQL */ `
  mutation DeletePaymentType(
    $input: DeletePaymentTypeInput!
    $condition: ModelPaymentTypeConditionInput
  ) {
    deletePaymentType(input: $input, condition: $condition) {
      id
      name
      disabled
      country_id
      createdAt
      updatedAt
      country {
        id
        country_code
        country_name
        Currency
        status
        createdAt
        updatedAt
      }
    }
  }
`
export const createVideo = /* GraphQL */ `
  mutation CreateVideo(
    $input: CreateVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    createVideo(input: $input, condition: $condition) {
      id
      title
      description
      thumbnail_sm
      thumbnail_md
      thumbnail_lg
      genres {
        items {
          id
          genre_id
          video_id
          genre {
            id
            title
            status
            videos {
              items {
                id
                genre_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      duration
      status
      release_date
      credits_start_timestamp
      video_tags {
        items {
          id
          tag_id
          video_id
          tag {
            id
            name
            videos {
              items {
                id
                tag_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      language
      video_type_id
      video_type {
        id
        title
        status
        createdAt
        updatedAt
      }
      video_rating_id
      video_rating {
        id
        title
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
export const updateVideo = /* GraphQL */ `
  mutation UpdateVideo(
    $input: UpdateVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    updateVideo(input: $input, condition: $condition) {
      id
      title
      description
      thumbnail_sm
      thumbnail_md
      thumbnail_lg
      genres {
        items {
          id
          genre_id
          video_id
          genre {
            id
            title
            status
            videos {
              items {
                id
                genre_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      duration
      status
      release_date
      credits_start_timestamp
      video_tags {
        items {
          id
          tag_id
          video_id
          tag {
            id
            name
            videos {
              items {
                id
                tag_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      language
      video_type_id
      video_type {
        id
        title
        status
        createdAt
        updatedAt
      }
      video_rating_id
      video_rating {
        id
        title
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
export const deleteVideo = /* GraphQL */ `
  mutation DeleteVideo(
    $input: DeleteVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    deleteVideo(input: $input, condition: $condition) {
      id
      title
      description
      thumbnail_sm
      thumbnail_md
      thumbnail_lg
      genres {
        items {
          id
          genre_id
          video_id
          genre {
            id
            title
            status
            videos {
              items {
                id
                genre_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      duration
      status
      release_date
      credits_start_timestamp
      video_tags {
        items {
          id
          tag_id
          video_id
          tag {
            id
            name
            videos {
              items {
                id
                tag_id
                video_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      language
      video_type_id
      video_type {
        id
        title
        status
        createdAt
        updatedAt
      }
      video_rating_id
      video_rating {
        id
        title
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
export const createVideoByQuality = /* GraphQL */ `
  mutation CreateVideoByQuality(
    $input: CreateVideoByQualityInput!
    $condition: ModelVideoByQualityConditionInput
  ) {
    createVideoByQuality(input: $input, condition: $condition) {
      id
      hls_url
      dash_url
      status
      video_id
      video_quality_id
      video_quality {
        id
        title
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      video {
        id
        title
        description
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        duration
        status
        release_date
        credits_start_timestamp
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        video_rating_id
        video_rating {
          id
          title
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`
export const updateVideoByQuality = /* GraphQL */ `
  mutation UpdateVideoByQuality(
    $input: UpdateVideoByQualityInput!
    $condition: ModelVideoByQualityConditionInput
  ) {
    updateVideoByQuality(input: $input, condition: $condition) {
      id
      hls_url
      dash_url
      status
      video_id
      video_quality_id
      video_quality {
        id
        title
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      video {
        id
        title
        description
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        duration
        status
        release_date
        credits_start_timestamp
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        video_rating_id
        video_rating {
          id
          title
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`
export const deleteVideoByQuality = /* GraphQL */ `
  mutation DeleteVideoByQuality(
    $input: DeleteVideoByQualityInput!
    $condition: ModelVideoByQualityConditionInput
  ) {
    deleteVideoByQuality(input: $input, condition: $condition) {
      id
      hls_url
      dash_url
      status
      video_id
      video_quality_id
      video_quality {
        id
        title
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      video {
        id
        title
        description
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        duration
        status
        release_date
        credits_start_timestamp
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              videos {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        video_rating_id
        video_rating {
          id
          title
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`
