import React from 'react'
import { useHistory } from 'react-router-dom'

import ButtonComp from '../../../components/Button'
import './purchaseDone.css'
import { purcahseDone } from '../../../assets'
import { Header } from '../../../components'

const PurchaseDone = () => {
  const history = useHistory()

  const finishPurchasing = () => {
    history.push('/')
  }
  return (
    <div className="pageWithBackgound">
      <div className="columnCenteredConatiner mainPurchasedContainer">
        <Header title={'THANK YOU FOR PURCHASING'} />

        <div className="purchaseDoneImgContainer">
          <img src={purcahseDone} className="purchaseDonwImage" />
        </div>
      </div>
      <div className="centeredContainer mainPurchasedFooter">
        <ButtonComp
          onClickHandler={finishPurchasing}
          disabled={false}
          title={'CONTINUE'}
          width={300}
        />
      </div>
    </div>
  )
}

export default PurchaseDone
