import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Home from './Home'
import Checkout from './Plan'
import PurchaseDone from './PurchaseDone'
import FriendsDetail from './FriendsDetail'
import ProtectedModals from './protectedModals'
import './authenticate.css'

const Authenticate = (props) => {
  return (
    <div className="authenticatedPages">
      <Switch>
        <Route exact path={['/', '/cast', '/game']}>
          <Home />
        </Route>
        <Route exact path="/checkout">
          <Checkout />
        </Route>
        <Route exact path="/purchase-done">
          <PurchaseDone />
        </Route>
        <Route exact path="/friends-detail">
          <FriendsDetail />
        </Route>
      </Switch>
      <ProtectedModals />
    </div>
  )
}

export default Authenticate
