import './footer.css'
import { FooterIcons } from '../../lib/constant'

const Footer = () => {
  return (
    <div className="footer-container">
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      />
      {FooterIcons.map((item) => {
        return (
          <div className="footer-icon-container">
            <a
              key={item.id}
              href={item.navigateTo}
              target="_blank"
              className={item.icon}
            />
          </div>
        )
      })}
      <div className="footer-hash-tag">#ISHRATMADEINCHINA</div>
    </div>
  )
}

export default Footer
