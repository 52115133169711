import { combineReducers } from 'redux'

import user from './modules/user'
import reserveData from './modules/reserveData'
import ishratData from './modules/ishrat'
import globalModals from './modules/globalModals'
import checkout from './modules/checkout'
import pricings from './modules/pricings'

const reducers = combineReducers({
  user,
  reserveData,
  ishratData,
  globalModals,
  checkout,
  pricings
})

const rootReducer = (state, action) => {
  // Clear persistant storage on logout
  // Dispatch RESET_APP on logout
  if (action.type === 'RESET_APP') {
    state = {}
  }

  return reducers(state, action)
}

export default rootReducer
