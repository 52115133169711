import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Grid } from '@material-ui/core'

import './rating.css'
import ButtonComp from '../../../components/Button'
import { setWatcher } from '../../../redux/modules/reserveData'
import ThumbsUp2 from '../../../assets/images/thumbsUp2.svg'
import SelectedThumbsUp2 from '../../../assets/images/selectedThumbsUp2.svg'
import SelectedThumbsDown2 from '../../../assets/images/selectedThumbsDown2.svg'
import ThumbsDown2 from '../../../assets/images/thumbsDown2.svg'
import Twitter from '../../../assets/icons/twitter.svg'
import Instagram from '../../../assets/icons/instagram.svg'
import Facebook from '../../../assets/icons/facebook.svg'
import { PrimaryButton, SecondaryButton } from '../../../styles/colors'

const Rating = ({ closeRatingModal }) => {
  const history = useHistory()

  const dispatch = useDispatch()

  const [ratingStatus, setRatingStatus] = useState(null)

  const handleBuyForAFriend = () => {
    dispatch(setWatcher({ for: 'friend' }))

    history.push('/friends-detail')

    closeRatingModal()
  }
  return (
    <div className="ratingContainer">
      <Header closeRatingModal={closeRatingModal} />
      <div className="mainDiv">
        <Grid container>
          <Grid item xl={4} md={3} lg={4} sm={1} xs={1}></Grid>
          <Grid xl={4} md={6} lg={4} sm={10} xs={10}>
            <div className="thumbsTopContainer">
              <div
                role="presentation"
                onClick={() => setRatingStatus('up')}
                className="thumbsIconContainer">
                <img
                  className="thumbsIcon"
                  src={ratingStatus === 'up' ? SelectedThumbsUp2 : ThumbsUp2}
                />
              </div>
              <div
                className="thumbsIconContainer"
                role="presentation"
                onClick={() => setRatingStatus('down')}>
                <img
                  className="thumbsIcon"
                  src={
                    ratingStatus === 'down' ? SelectedThumbsDown2 : ThumbsDown2
                  }
                />
              </div>
            </div>
            <div className="buyForFriendButtContainer">
              <ButtonComp
                onClickHandler={handleBuyForAFriend}
                backgroundColor={SecondaryButton}
                borderColor={SecondaryButton}
                titleColor="white"
                title="BUY FOR A FRIEND"
                width={250}
              />
            </div>
            <div className="sharingText">Share it with your friends</div>
            <SocialAccounts />
          </Grid>
        </Grid>
      </div>

      <div className="ratingFooterButton">
        <ButtonComp
          onClickHandler={closeRatingModal}
          backgroundColor={PrimaryButton}
          title="CONTINUE"
          width={330}
        />
      </div>
    </div>
  )
}

const Header = () => {
  return (
    <div>
      <div className={'ratingHeader'}>
        <p className={'ratingTitleText'}>Thank YOU FOR WATCHING ISHRAT</p>
      </div>
      <p className="ratingSubHeading">Please rate your experience</p>
    </div>
  )
}

const socialConnections = [
  {
    id: 'facebook',
    icon: Facebook
  },
  {
    id: 'twitter',
    icon: Twitter
  },
  {
    id: 'instagram',
    icon: Instagram
  }
]

const SocialAccounts = () => {
  return (
    <div>
      <div className={'centeredContainer'}>
        {socialConnections.map((item) => {
          return (
            <div key={item.id} className="iconBox">
              <img className="icon-height" src={item.icon} />
            </div>
          )
        })}
      </div>
      <p className="hashTagText">#ISHRATMADEINCHINA</p>
    </div>
  )
}

export default Rating
