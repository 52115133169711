// constants
const SET_WATCHER = 'SET_WATCHER'
const SET_PLAN = 'SET_PLAN'
const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD'

// actions
export const setWatcher = (watcher) => ({
  type: SET_WATCHER,
  watcher: watcher
})

export const setPlan = (plan) => ({
  type: SET_PLAN,
  plan: plan
})

export const setMethod = (method) => ({
  type: SET_PAYMENT_METHOD,
  paymentMethod: method
})

// thunks

// reducer
const initialState = {
  watcher: {
    for: 'yourself'
  }
}

const reducer = (state = initialState, action) => {
  const { type } = action
  switch (type) {
    case SET_WATCHER:
      return {
        ...state,
        watcher: action.watcher
      }
    case SET_PLAN:
      return {
        ...state,
        plan: action.plan
      }
    case SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.paymentMethod
      }
    default:
      return state
  }
}

export default reducer
