import { makeStyles } from '@material-ui/core/styles'

const makeDrawerStyles = makeStyles((theme) => ({
  list: {
    width: window.innerWidth,
    backgroundColor: 'black',
    height: '100vh'
  },
  fullList: {
    width: 'auto'
  },
  drawerIconButton: {
    marginLeft: 15,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 60
  },
  drawerIcon: {
    height: '1em',
    width: '1em',
    color: 'white'
  }
}))

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1
  },
  appbar: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    height: 60,
    padding: 0,
    boxShadow: 'none',
    backdropFilter: 'blur(30px)',
    background: 'rgba(0, 0, 0, 0.2)',
    backdropFilter: 'blur(5px)'
  },
  mobileAppBar: {
    backgroundColor: '#000000',
    height: 45,
    padding: 0,
    boxShadow: 'none'
  },
  menuIcon: {
    height: 35,
    width: 35,
    color: 'white'
  },
  avatar: {
    height: 30,
    width: 30
  },
  toolbar: {
    minHeight: 60
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  inputRoot: {
    color: 'inherit'
  },
  sectionDesktop: {
    marginTop: 0,
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    },
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    },
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  dropDownMenu: {
    fontWeight: 600,
    fontSize: 11,
    paddingRight: 30,
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 10,
    textAlign: 'left',
    borderBottom: '1px solid lightgray',
    backgroundColor: 'white'
  },
  dropDownMenuName: {
    backgroundColor: 'lightgray',
    fontWeight: 600,
    fontSize: 11,
    paddingRight: 30,
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 10,
    textAlign: 'left',
    textTransform: 'uppercase',
    borderBottom: '1px solid lightgray',
    pointerEvents: 'none'
  },
  menuRoot: {
    marginTop: 35,
    boxShadow: 'none',
    '& .MuiPaper-root': {
      width: 140,
      borderRadius: '0px',
      backgroundColor: 'transparent',
      boxShadow: 'none'
    }
  }
}))

const notificationStyles = makeStyles((theme) => ({
  notificationMenuRoot: {
    marginTop: 35,
    boxShadow: 'none',
    '& .MuiPaper-root': {
      borderRadius: '0px',
      width: 270,
      backgroundColor: 'transparent',
      boxShadow: 'none'
    }
  }
}))

export { makeDrawerStyles, useStyles, notificationStyles }
