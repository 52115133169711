import React, { useState, useRef, useEffect } from 'react'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import Popover from '@material-ui/core/Popover'
import { useSelector, useDispatch } from 'react-redux'

import './planSelector.css'
import { setPlan } from '../redux/modules/reserveData'
import { useMediaPredicate } from 'react-media-hook'

const PlanSelector = (props) => {
  const { plan } = useSelector((state) => state.reserveData)
  const { plans } = useSelector((state) => state.pricings)
  const { selectedPaymentName } = useSelector((state) => state.checkout)

  const lessThan700 = useMediaPredicate('(max-width: 650px)')

  const { id, couponApplied, currency, price, planName } = plan

  const [anchorEl, setAnchorEl] = useState(null)

  const planElement = useRef(null)

  const [popoverWidth, setPopOverWidth] = useState(window.innerWidth * 0.33)

  const dispatch = useDispatch()

  const open = Boolean(anchorEl)
  const popoverId = open ? 'simple-popover' : undefined

  useEffect(() => {
    if (planElement) setPopOverWidth(planElement.current.clientWidth)
  }, [])

  const handleClick = (event) => setAnchorEl(event.currentTarget)

  const handleClose = () => setAnchorEl(null)

  const handlePlanSelection = (item) => {
    setAnchorEl(null)

    dispatch(
      setPlan({
        ...item,
        couponApplied: couponApplied
      })
    )
  }

  const getWidth = (paymentTypeName) => {
    if (paymentTypeName === 'STRIPE') {
      return '32%'
    }

    return undefined
  }

  const renderDropDown = () => {
    return (
      <Popover
        style={{ width: 500, marginTop: 20, flex: 1 }}
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        <div style={{ flex: 1, width: popoverWidth, backgroundColor: 'white' }}>
          {plans.map((item) => {
            return (
              <div
                style={{
                  paddingTop: item.id === id ? 0 : 2,
                  paddingBottom: item.id === '0' ? 0 : 2
                }}>
                <div
                  role="presentation"
                  onClick={() => handlePlanSelection(item)}
                  className={
                    item.id == id ? 'selectedDropDownMenu' : 'dropDownMenu'
                  }>
                  <div
                    className={
                      item.id == id
                        ? 'selectedPlanPriceContainer'
                        : 'planPriceContainer'
                    }>
                    {item.currency}
                    <span
                      style={{
                        textDecoration: couponApplied ? 'line-through' : 'none',
                        paddingLeft: 5,
                        paddingRight: 5
                      }}>
                      {item.price}
                    </span>
                    {couponApplied && (
                      <span style={{ paddingLeft: 5, paddingRight: 5 }}>
                        {item.price - item.price * 0.2}
                      </span>
                    )}
                  </div>
                  <div
                    className={
                      item.id == id
                        ? 'selectedPlanTitleContainer'
                        : 'planTitleContainer'
                    }>
                    {item.planName}
                  </div>
                  {item.id === id ? (
                    <div
                      style={{
                        color: 'white',
                        flex: 1,
                        fontWeight: '600',
                        textAlign: 'right',
                        paddingLeft: 10,
                        paddingRight: 10
                      }}>
                      <CheckCircleOutlineIcon
                        style={{ color: 'white', fontSize: 18 }}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        color: 'white',
                        flex: 1,
                        fontWeight: '600',
                        textAlign: 'right',
                        paddingLeft: 10,
                        paddingRight: 10
                      }}
                    />
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </Popover>
    )
  }
  return (
    <>
      <div
        ref={planElement}
        onClick={handleClick}
        className="selectedDropDownMenu"
        style={{
          borderRadius: 4,
          paddingLeft: 10,
          paddingRight: 10,
          marginTop: '60px',
          width: lessThan700 ? '80%' : getWidth(selectedPaymentName)
        }}>
        <div style={{ color: 'white', flex: 1, fontWeight: '600' }}>
          {currency}
          <span
            style={{
              paddingLeft: 5,
              paddingRight: 5,
              textDecoration: couponApplied ? 'line-through' : 'none'
            }}>
            {price}
          </span>
          {couponApplied && (
            <span style={{ paddingLeft: 5, paddingRight: 5 }}>
              {price - price * 0.2}
            </span>
          )}
        </div>
        <div
          style={{
            color: 'white',
            flex: 1,
            textAlign: 'center',
            fontWeight: '600'
          }}>
          {planName}
        </div>
        <div
          style={{
            color: 'white',
            flex: 1,
            fontWeight: '600',
            textAlign: 'right'
          }}>
          Change
        </div>
      </div>
      {renderDropDown()}
    </>
  )
}

export default PlanSelector
