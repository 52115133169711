import React, { useState, useReducer } from 'react'
import { Grid, InputBase } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { validateEmail } from '../../../lib/utils'
import { loginUser } from '../../../redux/modules/user'
import { MintrioLogo } from '../../../assets'
import { ButtonComp } from '../../../components/'
import {
  PrimaryButton,
  SecondaryButton,
  SecondaryButtonHover,
  SecondaryHoverButtonText
} from '../../../styles/colors'
import './Login.css'

const useStyles = makeStyles((theme) => ({
  textField: {
    color: '#ffffff',
    border: '1px solid #ffffff',
    borderRadius: 4,
    height: 36,
    width: '100%',
    paddingLeft: 12,
    paddingRight: 12
  }
}))

const Login = () => {
  const classes = useStyles()

  const [{ email, password }, setCredentials] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { email: '', password: '' }
  )

  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const history = useHistory()

  const validation = () => {
    setError(null)

    if (email.trim() === '') {
      setError('Please enter your email address')

      return false
    }

    if (!validateEmail(email)) {
      setError('Please enter a valid email address')

      return false
    }

    if (password.trim() === '') {
      setError('Please enter your password')

      return false
    }

    if (password.length < 8) {
      setError('Password must not be less then 8')

      return false
    }

    return true
  }

  const handleLogin = async () => {
    if (!validation()) return

    setLoading(true)

    try {
      await dispatch(loginUser(email, password))

      setLoading(false)

      history.push('/')
    } catch (err) {
      setLoading(false)

      switch (err.code) {
        case 'UserNotFoundException':
          setError('User not found')
          break
        case 'UserNotConfirmedException':
          setError('Account not confirmed')
          break
        case 'NotAuthorizedException':
          setError('Incorrect email or password')
          break
        default:
          setError('Something went wrong.')
          break
      }
    }
  }

  const handleInput = (event) => {
    const { name, value } = event.target

    setCredentials({ [name]: value })
  }

  return (
    <div className="fullContainer">
      <div class="mintrioBackground">
        <div className="logoContainer">
          <img src={MintrioLogo} class="mintrioLogo" alt="" />
          <p class="heading">All Mintrio Originals</p>
        </div>

        <div className="formContainer">
          <Grid container style={{ flex: 1 }}>
            <Grid item md={2} lg={4} xl={4} sm={0} xs={0}></Grid>
            <Grid item md={8} lg={4} xl={4} sm={12} xs={12}>
              <div className="loginFormContent">
                <div className="subHeadingContainer">
                  <div className="welcomeBackText">WELCOME BACK</div>
                  <div className="signInText">SIGN IN TO YOUR ACCOUNT</div>
                </div>
                <div className="formInputContainer">
                  <InputBase
                    value={email}
                    name="email"
                    id="email"
                    onChange={handleInput}
                    placeholder="Email Address"
                    variant="outlined"
                    className={classes.textField}
                  />
                </div>

                <div className="formInputContainer">
                  <InputBase
                    name="password"
                    id="password"
                    value={password}
                    onChange={handleInput}
                    placeholder="Password"
                    variant="outlined"
                    type="password"
                    className={classes.textField}
                  />
                </div>
                <div
                  role="presentation"
                  onClick={() => history.push('/reset-password')}
                  className="forgetPasswordText">
                  Forget Password?
                </div>
                {error && <div className="loginError"> {error} </div>}
                <div className="loginButtonContainer">
                  <ButtonComp
                    height={36}
                    backgroundColor={PrimaryButton}
                    titleColor="white"
                    loading={loading}
                    borderColor={PrimaryButton}
                    onClickHandler={handleLogin}
                    disabled={false}
                    title={'LOG IN'}
                    width={'100%'}
                  />
                </div>

                <div className="dividerContainer">
                  <div className="dividerLine"></div>
                  <div className="orText">OR</div>
                  <div className="dividerLine"></div>
                </div>

                <div className="loginButtonContainer">
                  <ButtonComp
                    backgroundColor={SecondaryButton}
                    hoverBackgroundColor={SecondaryButtonHover}
                    hoverTitleColor={SecondaryHoverButtonText}
                    borderColor={SecondaryButton}
                    height={36}
                    onClickHandler={() => history.push('/register')}
                    disabled={false}
                    title={'SIGN UP'}
                    width={'100%'}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  )
}

export default Login
