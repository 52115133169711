import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { useMediaPredicate } from 'react-media-hook'

import './Game.css'
import { game, playstore, applestore } from '../../../assets'
import { ButtonComp } from '../../../components'
import { PrimaryButton } from '../../../styles/colors'

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: 10
  },
  withoutMargin: {
    margin: 0
  }
}))

function Game() {
  const lessThan350 = useMediaPredicate('(max-width: 350px)')
  const lessThan450 = useMediaPredicate('(max-width: 450px)')

  const classes = useStyles()

  return (
    <div className="game-body">
      <div className="left-container">
        <Grid container>
          <Grid item lg={0} xl={0} md={0} sm={1} xs={1}></Grid>
          <Grid item lg={5} xl={5} md={9} sm={10} xs={10}>
            <p className="game-title">ISHARAT GAME</p>
            <p className="game-detail">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nulla
              nibh cursus neque id duis. Nisl lorem nulla purus tristique
              tincidunt in a libero.
            </p>
            <div className="image-container">
              <img src={game} className="game-image" alt="" />
              <img src={game} className="game-image" alt="" />
              <img src={game} className="game-image" alt="" />
            </div>

            <p className="download-game">Download our game</p>

            <p className="download-game-link">
              Download the game from following links
            </p>

            <div className="button-class">
              <div
                className={
                  lessThan350 ? classes.withoutMargin : classes.margin
                }>
                <ButtonComp
                  title=""
                  icon={<img src={playstore} className="game-button" alt="" />}
                  width={lessThan450 ? '90%' : 150}
                  backgroundColor="transparent"
                  borderColor={PrimaryButton}
                  hoverBackgroundColor={PrimaryButton}
                />
              </div>
              <div
                className={
                  lessThan350 ? classes.withoutMargin : classes.margin
                }>
                <ButtonComp
                  title=""
                  icon={<img src={applestore} className="game-button" alt="" />}
                  width={lessThan450 ? '90%' : 150}
                  backgroundColor="transparent"
                  borderColor={PrimaryButton}
                  hoverBackgroundColor={PrimaryButton}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default Game
