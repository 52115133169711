import { API, Auth, graphqlOperation } from 'aws-amplify'
import { createOrder } from '../../graphql/mutations'
import moment from 'moment'

// constants
const SET_SELECTED_PAYMENT_TYPE = 'SET_SELECTED_PAYMENT_TYPE'

// actions
export const setSelectedPaymentType = (payload) => ({
  type: SET_SELECTED_PAYMENT_TYPE,
  payload
})

// thunks
export const postCheckoutStripe = (
  { firstName, lastName },
  stripe,
  cardElement
) => async (dispatch, getState) => {
  const {
    checkout: { selectedPaymentType },
    reserveData: {
      plan: { id: video_plan_id, price, currency }
    },
    user: {
      attributes: { email },
      accessToken: {
        payload: { username }
      }
    }
  } = getState()

  const billingDetails = {
    name: `${firstName} ${lastName}`,
    email
  }

  try {
    const authToken = await (await Auth.currentSession())
      .getIdToken()
      .getJwtToken()

    const params = {
      body: { price: price * 100, currency },
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }

    const client_secret = await API.post(
      'stripeCheckoutSession',
      '/stripe-checkout-session',
      params
    )

    const paymentMethodReq = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: billingDetails
    })

    const { error, paymentIntent } = await stripe.confirmCardPayment(
      client_secret,
      {
        payment_method: paymentMethodReq.paymentMethod.id
      }
    )

    if (error) {
      throw error
    }

    console.log('paymentIntent', paymentIntent)

    const { id, created, amount } = paymentIntent

    // kept this response for future reference
    // amount: 30000
    // canceled_at: null
    // cancellation_reason: null
    // capture_method: "automatic"
    // client_secret: "pi_1InqEkCaNalnTQrxwbfFghjV_secret_s6pcIDIpULAwRtMvSoq4Ggjm4"
    // confirmation_method: "automatic"
    // created: 1620242302
    // currency: "pkr"
    // description: null
    // id: "pi_1InqEkCaNalnTQrxwbfFghjV"
    // last_payment_error: null
    // livemode: false
    // next_action: null
    // object: "payment_intent"
    // payment_method: "pm_1InqElCaNalnTQrxYqwfCIe3"
    // payment_method_types: ["card"]
    // receipt_email: null
    // setup_future_usage: null
    // shipping: null
    // source: null
    // status: "succeeded"

    const orderInput = {
      ref_no: id,
      payment_total: amount / 100,
      disabled: false,
      payment_type_id: selectedPaymentType,
      user_id: username,
      purchased_at: moment(created, 'X').format(), // convert into ISO 8601 datetime format.
      expired: false,
      video_plan_id
    }

    console.log('params', orderInput)

    try {
      await API.graphql(
        graphqlOperation(createOrder, {
          input: orderInput
        })
      )
    } catch (error) {
      console.error('order error', error)
    }

    // kept this response for future reference.
    // id: ID!
    // status: Boolean
    // ref_no: String!
    // payment_total: Float!
    // disabled: Boolean!
    // payment_type_id: ID!
    // payment_type: PaymentType @connection(fields: ["payment_type_id"])
    // user_id: ID!
    // user: User @connection(fields: ["user_id"])
    // promo_code_id: ID
    // promo_code: PromoCode @connection(fields: ["promo_code_id"])
    // purchased_at: AWSDateTime!
    // started_at: AWSDateTime
    // expired_at: AWSDateTime
    // expired: Boolean
    // twenty_five_percent_watched: Boolean
    // fifty_percent_watched: Boolean
    // seventy_five_percent_watched: Boolean
    // hundred_percent_watched: Boolean
    // video_plan_id: ID!
    // video_plan: VideoPlan @connection(fields: ["video_plan_id"])
  } catch (err) {
    console.warn('error', err)
    throw err
  }
}

export const postCheckoutPaypal = (orderInput) => async (
  dispatch,
  getState
) => {
  const {
    checkout: { selectedPaymentType }
  } = getState()

  orderInput.payment_type_id = selectedPaymentType

  try {
    await API.graphql(
      graphqlOperation(createOrder, {
        input: orderInput
      })
    )
  } catch (error) {
    throw error
  }
}

export const postCheckoutJazzCash = (id) => async (dispatch, getState) => {
  const {
    checkout: { selectedPaymentType },
    reserveData: {
      plan: { id: video_plan_id, price }
    },
    user: {
      accessToken: {
        payload: { username }
      }
    }
  } = getState()

  try {
    const orderInput = {
      ref_no: id,
      payment_total: price,
      disabled: false,
      payment_type_id: selectedPaymentType,
      user_id: username,
      purchased_at: moment().toISOString(),
      expired: false,
      video_plan_id
    }

    await API.graphql(
      graphqlOperation(createOrder, {
        input: orderInput
      })
    )
  } catch (error) {
    throw error
  }
}

// reducer
const initialState = {
  selectedPaymentType: null,
  selectedPaymentName: null
}

const reducer = (state = initialState, action) => {
  const { payload, type } = action

  switch (type) {
    case SET_SELECTED_PAYMENT_TYPE: {
      return {
        ...state,
        selectedPaymentType: payload.selectedPaymentType,
        selectedPaymentName: payload.selectedPaymentName
      }
    }
    default:
      return state
  }
}

export default reducer
