import { Grid } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useHistory } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import { PayPalButton } from 'react-paypal-button-v2'

import './externalPayment.css'
import JazzCashForm from './jazzcashForm'
import { Header } from '../../../components'
import { PaymentOptions, PAYPAL_CLIENT_ID } from '../../../lib/constant'
import PlanSelector from '../../../components/planSelector'
import { postCheckoutPaypal } from '../../../redux/modules/checkout'
import { Loading } from '../../../components/Loading'
import { purchaseMovie } from '../../../redux/modules/user'
import JazzCashCardForm from './jazzcashCardForm'
import { AlertDialog } from '../../../components/Alert/Alert'

const ExternalPayment = ({ paymentMethod, setCurrentStep }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [dialog, setDialog] = useState(false)
  const [method, setMethod] = useState()
  const [loading, setLoading] = useState(false)

  const {
    plan: { id, currency, price }
  } = useSelector((state) => state.reserveData)
  const {
    accessToken: {
      payload: { username }
    }
  } = useSelector((state) => state.user)

  const setErrorDialog = () => setDialog(true)

  useEffect(() => {
    setMethod(() => paymentMethod)

    if (
      !paymentMethod &&
      paymentMethod !== 'paypal' &&
      paymentMethod !== 'easypaisa' &&
      paymentMethod !== 'jazzcash' &&
      paymentMethod !== 'jazzcashcard'
    ) {
      history.push('/checkout')
    }
  }, [paymentMethod, history])

  const onOrderSucess = async (details, price, id, username) => {
    try {
      setLoading(true)

      const orderInput = {
        ref_no: details.id,
        payment_total: price,
        disabled: false,
        user_id: username,
        purchased_at: details.create_time,
        expired: false,
        video_plan_id: id
      }

      await dispatch(postCheckoutPaypal(orderInput))

      dispatch(purchaseMovie())

      history.replace('/purchase-done')
    } catch (error) {
      console.error(error)

      setLoading(false)
    }
  }

  return (
    <div className="pageWithBackgound">
      <div style={{ position: 'relative' }}>
        {method && (
          <Header
            title={`SET UP YOUR ${PaymentOptions[method].method.toUpperCase()}`}
          />
        )}
        {loading && <Loading open={loading} />}
        <div
          className="payment-back-button"
          onClick={() => setCurrentStep('SelectCheckoutMethod')}>
          <ArrowBackIcon color="white" />
        </div>
      </div>

      <div className="centeredContainer">
        {method && (
          <img
            src={PaymentOptions[method].logo}
            style={{ height: 20 }}
            alt=""
          />
        )}
      </div>
      <div className="externalPaymentDetail">
        <div className="fullColumnContainer marginContainer50">
          <Grid container style={{ marginTop: 20 }}>
            <Grid md={3} lg={4} xl={4} sm={0} xs={0}></Grid>
            <Grid md={6} lg={4} xl={4} sm={12} xs={12}>
              <PlanSelector />
            </Grid>
          </Grid>

          <Grid container style={{ marginTop: 20 }}>
            <Grid md={3} lg={3} xl={3} sm={0} xs={0}></Grid>
            <Grid md={6} lg={6} xl={6} sm={12} xs={12}>
              <div className="paymentButtonText">
                To finish signup, click the
                <span style={{ fontWeight: '600' }}>CONTINUE</span> button and
                log into {method && PaymentOptions[method].method} using your
                email and password
              </div>
            </Grid>
          </Grid>

          <div className="continuePaymentButton">
            {method === 'paypal' && (
              <div onClick={() => setLoading(true)} style={{ width: '500px' }}>
                <PayPalButton
                  currency={currency}
                  amount={price}
                  shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                  createOrder={(data, actions, err) => {
                    return actions.order.create({
                      intent: 'CAPTURE',
                      purchase_units: [
                        {
                          description: id,
                          amount: {
                            currency_code: currency,
                            value: price
                          }
                        }
                      ]
                    })
                  }}
                  onSuccess={async (details, data) =>
                    onOrderSucess(details, price, id, username)
                  }
                  onError={() =>
                    alert('Something went wrong. please try again.')
                  }
                  options={{
                    clientId: PAYPAL_CLIENT_ID,
                    currency: currency
                  }}
                />
              </div>
            )}

            {method === 'jazzcash' && (
              <JazzCashForm setErrorDialog={setErrorDialog} />
            )}
            {method === 'jazzcashcard' && (
              <JazzCashCardForm setErrorDialog={setErrorDialog} />
            )}
          </div>
        </div>
      </div>

      <AlertDialog
        open={dialog}
        handleClose={() => setDialog(false)}
        title="Payment Request Failed!"
        description="Something Went Wrong. Please try again. Thank you."
      />
    </div>
  )
}

export default ExternalPayment
