// constants
const SET_RESERVE_MODAL = 'SET_RESERVE_MODAL'
const SET_TRANSATION_HISTROY_MODAL_VISIBLE =
  'SET_TRANSATION_HISTROY_MODAL_VISIBLE'
const SET_EDIT_ACCOUN_TMODAL_VISIBLE = 'SET_EDIT_ACCOUNT_MODAL_VISIBLE'

// actions
export const setIsReserveModal = (payload) => ({
  type: SET_RESERVE_MODAL,
  payload
})
export const setTHModal = (payload) => ({
  type: SET_TRANSATION_HISTROY_MODAL_VISIBLE,
  payload
})
export const setEditAccountModal = (payload) => ({
  type: SET_EDIT_ACCOUN_TMODAL_VISIBLE,
  payload
})

// thunks

// reducer
const initialState = {
  isReserveModalVisible: false,
  isTHModalVisible: false,
  isEAModalVisible: false
}

const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_RESERVE_MODAL:
      return {
        ...state,
        isReserveModalVisible: payload.isReserveModalVisible
      }
    case SET_TRANSATION_HISTROY_MODAL_VISIBLE:
      return {
        ...state,
        isTHModalVisible: payload.isTHModalVisible
      }
    case SET_EDIT_ACCOUN_TMODAL_VISIBLE:
      return {
        ...state,
        isEAModalVisible: payload.isEAModalVisible
      }
    default:
      return state
  }
}

export default reducer
