import React from 'react'
import { Menu } from '@material-ui/core'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'

import { notificationStyles } from './styles'

const notifications = [
  {
    id: '0',
    title: 'Pre Book with code ISHRAT20 for 20% off and other perks',
    time: '20 mints'
  },
  {
    id: '1',
    title: 'Pre Book with code ISHRAT20 for 20% off and other perks',
    time: '30 mints'
  }
]
const notificationMenuId = 'primary-notification-menu'
const NotificationPane = ({ notificationAnchorEl, closeNotificationPane }) => {
  const classes = notificationStyles()

  return (
    <Menu
      className={classes.notificationMenuRoot}
      MenuListProps={{ disablePadding: true }}
      anchorEl={notificationAnchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      id={notificationMenuId}
      keepMounted
      transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={notificationAnchorEl}
      onClose={closeNotificationPane}>
      <div className="centeredContainer">
        <div className="topArrow" />
      </div>
      {notifications.map((item) => {
        return (
          <div key={item.id} className="customMenuContainer">
            <div
              className="customMenuItem"
              role="presentation"
              onClick={closeNotificationPane}>
              <div className="menuListIconContainer">
                <FiberManualRecordIcon
                  className="menuListIcon"
                  style={{ fontSize: 10 }}
                />
              </div>
              <div className="notificationTitleContainer">
                <div className="notificationTitle">{item.title}</div>
                <div className="notificationTime">{item.time}</div>
              </div>
            </div>
          </div>
        )
      })}
    </Menu>
  )
}
export default NotificationPane
