import React, { useEffect, useState } from 'react'
import { KeyboardArrowRightOutlined } from '@material-ui/icons'
import { Grid } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { API, graphqlOperation } from 'aws-amplify'

import './paymentSetup.css'
import { visa, masterCard, paypal, easypesa, jazCash } from '../../../assets'
import { Header } from '../../../components'
import { listPaymentTypes } from '../../../graphql/queries'
import { Loading } from '../../../components/Loading'
import { paymentTypesResponse } from '../../../lib/map'
import { setSelectedPaymentType } from '../../../redux/modules/checkout'

const PaymentSetup = ({ countryId, setCurrentStep }) => {
  const [loading, setLoading] = useState(true)
  const [paymentTypes, setPaymentTypes] = useState([])

  const dispatch = useDispatch()

  const onSelectPaymentType = (selectedPaymentTypeName, paymentTypes) => {
    const paymentType = paymentTypes.find(
      (paymentType) => paymentType.name === selectedPaymentTypeName
    )

    dispatch(
      setSelectedPaymentType({
        selectedPaymentType: paymentType.id,
        selectedPaymentName: selectedPaymentTypeName
      })
    )
  }

  useEffect(() => {
    async function fetchPaymentTypes() {
      try {
        const {
          data: {
            listPaymentTypes: { items }
          }
        } = await API.graphql(
          graphqlOperation(listPaymentTypes, {
            filter: {
              country_id: {
                eq: countryId
              },
              disabled: {
                eq: false
              }
            }
          })
        )

        if (items.length > 0) {
          const paymentTypeResponse = paymentTypesResponse(items)

          setPaymentTypes(paymentTypeResponse)
        }

        setLoading(false)
      } catch (error) {
        console.error(error)

        setLoading(false)
      }
    }

    fetchPaymentTypes()
  }, [countryId])

  return (
    <div className="pageWithBackgound">
      <Header title="SETUP YOUR PAYMENT" />
      {loading ? (
        <Loading open={loading} />
      ) : (
        <div className="paymentMethodContainer padding-30">
          <Grid container justify="center" style={{ marginTop: 20 }}>
            <Grid md={6} lg={4} xl={4} sm={12} xs={12}>
              {paymentTypes.some(
                (paymentType) => paymentType.name === 'STRIPE'
              ) && (
                <div
                  role="presentation"
                  onClick={() => {
                    onSelectPaymentType('STRIPE', paymentTypes)

                    setCurrentStep('VisaSetup')
                  }}
                  className={'methodContainer'}>
                  <div className="paymentMethodTitle">Stripe</div>
                  <div className="visaImagesContainer">
                    <div style={{ padding: 10 }}>
                      <img
                        src={visa}
                        style={{ height: 20, marginTop: 5 }}
                        alt=""
                      />
                    </div>
                    <div>
                      <img src={masterCard} style={{ height: 20 }} alt="" />
                    </div>
                  </div>
                  <div className="rightArrowIcon">
                    <KeyboardArrowRightOutlined className="rightArrow" />
                  </div>
                </div>
              )}
              {paymentTypes.some(
                (paymentType) => paymentType.name === 'PAYPAL'
              ) && (
                <div
                  role="presentation"
                  onClick={() => {
                    onSelectPaymentType('PAYPAL', paymentTypes)

                    setCurrentStep('Paypal')
                  }}
                  className={'methodContainer'}>
                  <div className="paymentMethodTitle">PayPal</div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={paypal} style={{ height: 20 }} alt="" />
                  </div>
                  <div className="rightArrowIcon">
                    <KeyboardArrowRightOutlined className="rightArrow" />
                  </div>
                </div>
              )}
              {paymentTypes.some(
                (paymentType) => paymentType.name === 'EASY_PAISA'
              ) && (
                <div
                  className={'methodContainer'}
                  role="presentation"
                  onClick={() => {
                    onSelectPaymentType('EASY_PAISA', paymentTypes)

                    setCurrentStep('EasyPaisa')
                  }}>
                  <div className="paymentMethodTitle">Easy Paisa</div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={easypesa} style={{ height: 15 }} alt="" />
                  </div>
                  <div className="rightArrowIcon">
                    <KeyboardArrowRightOutlined className="rightArrow" />
                  </div>
                </div>
              )}
              {paymentTypes.some(
                (paymentType) => paymentType.name === 'JAZZ_CASH_MOBILE'
              ) && (
                <div
                  role="presentation"
                  onClick={() => {
                    onSelectPaymentType('JAZZ_CASH_MOBILE', paymentTypes)

                    setCurrentStep('JazzCash')
                  }}
                  className={'methodContainer'}>
                  <div className="paymentMethodTitle">Jazz Cash</div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={jazCash} style={{ height: 25 }} alt="" />
                  </div>
                  <div className="rightArrowIcon">
                    <KeyboardArrowRightOutlined className="rightArrow" />
                  </div>
                </div>
              )}
              {paymentTypes.some(
                (paymentType) => paymentType.name === 'JAZZ_CASH_CARD'
              ) && (
                <div
                  role="presentation"
                  onClick={() => {
                    onSelectPaymentType('JAZZ_CASH_CARD', paymentTypes)

                    setCurrentStep('JazzCashCard')
                  }}
                  className={'methodContainer'}>
                  <div className="paymentMethodTitle">Jazz Cash Card</div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={jazCash} style={{ height: 25 }} alt="" />
                  </div>
                  <div className="rightArrowIcon">
                    <KeyboardArrowRightOutlined className="rightArrow" />
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  )
}

export default PaymentSetup
