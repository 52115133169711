import React from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import '../theme/Common.css'
// SCREENS
import UnAuthenticate from '../pages/unauthenticate'
import Authenticate from '../pages/authenticate'

function Routes() {
  const User = useSelector((state) => state.user)
  return (
    <Router>
      <div
        style={{
          width: window.innerWidth,
          height: window.innerHeight,
          display: 'flex'
        }}>
        <Switch>
          <Route path={'/'}>
            {User.isLogged ? <Authenticate /> : <UnAuthenticate />}
          </Route>
        </Switch>
      </div>
    </Router>
  )
}

export default Routes
