import { API, graphqlOperation } from 'aws-amplify'

import { listPricings as ListPricings } from '../../graphql/queries'
import { pricingsResponse } from '../../lib/map'

//constants
const READ_PLANS = 'READ_PLANS'
const RESET_PLANS = 'RESET_PLANS'

//actions
const readPlans = (payload) => ({
  type: READ_PLANS,
  payload
})

const resetPlans = () => ({
  type: RESET_PLANS
})

//thunks
export const postReadPlans = (filter) => async (dispatch, getState) => {
  try {
    // Need to reset so that we can get selected plan by default.
    // By default hightest price plan will be selected.
    dispatch(resetPlans())

    const {
      data: {
        listPricings: { items }
      }
    } = await API.graphql(
      graphqlOperation(ListPricings, {
        filter
      })
    )

    if (items.length === 0) return

    const response = pricingsResponse(items)

    dispatch(readPlans({ plans: response }))
  } catch (error) {
    throw error
  }
}

//reducer
const initialState = {
  plans: []
}

const pricingReducer = (state = initialState, action) => {
  const { payload, type } = action

  switch (type) {
    case READ_PLANS:
      return {
        ...state,
        plans: payload.plans
      }
    case RESET_PLANS:
      return {
        ...state,
        plans: []
      }
    default:
      return state
  }
}

export default pricingReducer
