import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { InputBase } from '@material-ui/core'

import { PrimaryText, PrimaryButton } from '../../styles/colors'

const useStyles = makeStyles(() => ({
  textField: {
    border: '1px solid #3C3C63',
    width: '100%',
    borderRadius: 4,
    paddingLeft: 12,
    paddingRight: 12,
    color: PrimaryText,
    '&:focused': {
      borderColor: PrimaryButton
    }
  },
  inputFocused: {
    border: `1px solid ${PrimaryButton}`,
    width: '100%',
    borderRadius: 4,
    paddingLeft: 12,
    paddingRight: 12,
    color: PrimaryText
  }
}))

const FormInput = ({
  placeholder,
  id,
  name,
  onChangeHandler = () => {},
  type = 'text',
  value = '',
  endAdornment = null
}) => {
  const classes = useStyles()

  return (
    <InputBase
      value={value}
      placeholder={placeholder}
      variant="outlined"
      id={id}
      classes={{ focused: classes.inputFocused }}
      name={name}
      type={type}
      onChange={onChangeHandler}
      className={classes.textField}
      endAdornment={endAdornment}
    />
  )
}

export default FormInput
