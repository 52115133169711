import { useDispatch } from 'react-redux'
import { Grid } from '@material-ui/core'

import { ModalHeader } from '../../../components'
import { setEditAccountModal } from '../../../redux/modules/globalModals'
import './editAccount.css'
import EditForm from './editForm'

const EditAccount = () => {
  const dispatch = useDispatch()

  const closeModal = () =>
    dispatch(setEditAccountModal({ isEAModalVisible: false }))

  return (
    <div className="modalContainer">
      <ModalHeader title={'Account Details'} closeModal={closeModal} />
      <div className="fullContainer">
        <Grid container style={{ flex: 1 }}>
          <Grid item lg={4} xl={4} md={4} sm={0} xs={0}></Grid>
          <Grid item lg={4} xl={4} md={4} sm={12} xs={12}>
            <EditForm />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default EditAccount
