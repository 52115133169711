// constants
const SET_WATCHING = 'SET_WATCHING'

// actions
export const setWatching = () => ({
  type: SET_WATCHING,
  startedWatcing: true
})

// thunks

// reducer
const initialState = {
  startedWatcing: false
}

const reducer = (state = initialState, action) => {
  const { type } = action

  switch (type) {
    case SET_WATCHING:
      return {
        ...state,
        startedWatcing: action.startedWatcing
      }
    default:
      return state
  }
}

export default reducer
