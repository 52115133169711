import { visa, masterCard } from '../../../assets'

export function FormTitle() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 20
      }}>
      <h3 style={{ color: 'white' }}>SETUP YOUR CREDIT OR DEBIT CARD</h3>
    </div>
  )
}

export function FormBanner() {
  return (
    <div
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
      }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 10
        }}>
        <img src={visa} style={{ height: 50 }} />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 10
        }}>
        <img src={masterCard} style={{ height: 50 }} />
      </div>
    </div>
  )
}
