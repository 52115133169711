import React, { useReducer, useState } from 'react'
import { Grid, TextField } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import './visaSetup.css'
import ButtonComp from '../../../components/Button'
import PlanSelector from '../../../components/planSelector'
import { FormInput } from '../../../components/'
import { FormBanner, FormTitle } from './_index'
import { postCheckoutStripe } from '../../../redux/modules/checkout'
import { AlertDialog } from '../../../components/Alert/Alert'
import { Loading } from '../../../components/Loading'
import Layout from '../../../components/CheckoutForm/Elements'
import { purchaseMovie } from '../../../redux/modules/user'

const formFields = [
  {
    label: 'cardNumber',
    checkout: true
  },
  {
    label: 'expiry',
    checkout: true
  },
  {
    label: 'cvv',
    checkout: true
  }
]

const ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#FFFFFF'
    },
    invalid: {
      color: '#9e2146'
    }
  }
}

const elementStyle = {
  border: '1px solid #3C3C63',
  width: '98%',
  borderRadius: 4,
  paddingTop: '10px',
  paddingBottom: '10px',
  paddingLeft: '12px'
}

const VisaSetup = ({ setCurrentStep }) => {
  const [dialog, setDialog] = useState(false)
  const [loading, setLoading] = useState(false)

  const history = useHistory()

  const stripe = useStripe()
  const elements = useElements()

  const dispatch = useDispatch()

  const [cardComplete, setCardComplete] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false
  })

  const [{ firstName, lastName }, setInputValue] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      firstName: '',
      lastName: ''
    }
  )

  const handleInput = (event) => {
    const { name, value } = event.target

    setInputValue({ [name]: value })
  }

  function handleCardElementOnChange(e) {
    setCardComplete({ ...cardComplete, [e.elementType]: e.complete })
  }

  async function onSubmit(cardValid) {
    if (!cardValid) {
      return alert('Invalid Card. Please try again.')
    }

    try {
      const cardElement = elements.getElement(CardNumberElement)

      setLoading(true)

      await dispatch(
        postCheckoutStripe({ firstName, lastName }, stripe, cardElement)
      )

      dispatch(purchaseMovie())

      setLoading(false)

      history.push('/purchase-done')
    } catch (error) {
      setLoading(false)
      setDialog(true)
    }
  }

  const cardValid =
    cardComplete.cardCvc && cardComplete.cardExpiry && cardComplete.cardNumber

  return (
    <div className="pageWithBackgound">
      <div className="fullColumnContainer">
        <FormTitle />
        <FormBanner />
        <div style={{ position: 'relative' }}>
          <div
            className="payment-back-button"
            onClick={() => setCurrentStep('SelectCheckoutMethod')}>
            <ArrowBackIcon color="white" />
          </div>
        </div>
        <div className="visaForm">
          <Grid container style={{ margin: 0 }}>
            <Grid md={4} lg={4} xl={4} sm={0} xs={0}></Grid>
            <Grid md={4} lg={4} xl={4} sm={12} xs={12}>
              <FormInput
                name="firstName"
                id="firstName"
                value={firstName}
                placeholder="First Name"
                onChangeHandler={handleInput}
              />
            </Grid>
          </Grid>
          <Grid container style={{ margin: '20px 0px' }}>
            <Grid md={4} lg={4} xl={4} sm={0} xs={0}></Grid>
            <Grid md={4} lg={4} xl={4} sm={12} xs={12}>
              <FormInput
                name="lastName"
                id="lastName"
                value={lastName}
                placeholder="Last Name"
                onChangeHandler={handleInput}
              />
            </Grid>
          </Grid>
          {formFields.map((field, index) => (
            <Grid
              container
              style={{ marginTop: index === 0 ? 0 : 20 }}
              key={index}>
              <Grid
                md={4}
                lg={4}
                xl={4}
                sm={12}
                xs={12}
                style={{ margin: '0 auto' }}>
                {['cardNumber', 'expiry', 'cvv'].includes(field.label) ===
                  false && (
                  <TextField
                    id={`outlined-basic-${index}`}
                    label={field.label}
                    variant="outlined"
                    style={{ width: '100%' }}
                    size="small"
                  />
                )}

                {field.label === 'cardNumber' && (
                  <div style={elementStyle}>
                    <CardNumberElement
                      options={ELEMENT_OPTIONS}
                      id="cardNumber"
                      onChange={handleCardElementOnChange}
                    />
                  </div>
                )}
                {field.label === 'expiry' && (
                  <div style={elementStyle}>
                    <CardExpiryElement
                      options={ELEMENT_OPTIONS}
                      id="expiry"
                      onChange={handleCardElementOnChange}
                    />
                  </div>
                )}
                {field.label === 'cvv' && (
                  <div style={elementStyle}>
                    <CardCvcElement
                      options={ELEMENT_OPTIONS}
                      id="cvc"
                      onChange={handleCardElementOnChange}
                    />
                  </div>
                )}
              </Grid>
            </Grid>
          ))}
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center">
            <PlanSelector />
          </Grid>
        </div>
        <div className="visaButtonContainer">
          <ButtonComp
            onClickHandler={() => onSubmit(cardValid)}
            disabled={false}
            title={'CONTINUE'}
            width={300}
          />
        </div>
      </div>
      <AlertDialog
        open={dialog}
        handleClose={() => setDialog(false)}
        title="Payment Request Failed!"
        description="Something Went Wrong. Please try again. Thank you."
      />
      <Loading open={loading} />
    </div>
  )
}

const CheckoutForm = ({ setCurrentStep }) => {
  return (
    <Layout>
      <VisaSetup setCurrentStep={setCurrentStep} />
    </Layout>
  )
}

export default CheckoutForm
