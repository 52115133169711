import _ from 'lodash'

export const pricingsResponse = (pricings) => {
  const plans = pricings.map((pricing) => {
    const {
      id,
      video_plan: {
        plan: {
          title,
          video_quality: { title: maxResolution },
          supported_devices
        }
      },
      country: { country_name, Currency: currency },
      price
    } = pricing

    const supportedDevices = supported_devices.items.reduce(
      (accum, { supported_devices }) => {
        const deviceTitle = _.get(supported_devices, 'title', '').toLowerCase()
        const deviceStatus = _.get(supported_devices, 'status', false)

        if (deviceTitle === 'laptop' && deviceStatus === true) {
          return {
            ...accum,
            laptop: true
          }
        }

        if (deviceTitle === 'mobile' && deviceStatus === true) {
          return {
            ...accum,
            mobile: true
          }
        }

        if (deviceTitle === 'tablet' && deviceStatus === true) {
          return {
            ...accum,
            tablet: true
          }
        }

        if (deviceTitle === 'desktop' && deviceStatus === true) {
          return {
            ...accum,
            desktop: true
          }
        }

        if (deviceTitle === 'tv' && deviceStatus === true) {
          return {
            ...accum,
            tv: true
          }
        }

        return accum
      },
      {
        tablet: false,
        mobile: false,
        laptop: false,
        desktop: false,
        tv: false
      }
    )

    return {
      id,
      planName: title,
      currency,
      country: country_name,
      price,
      maxResolution,
      description: 'Good', // We are setting by default to good because if below conditions not met it will be Good always.
      supportedDevices
    }
  })

  const sortedPlans = _.orderBy(plans, ['price'], ['asc'])

  if (sortedPlans.length === 2) {
    sortedPlans[sortedPlans.length - 1].description = 'Best'
  } else if (sortedPlans.length >= 3) {
    sortedPlans[sortedPlans.length - 1].description = 'Better'
    sortedPlans[sortedPlans.length - 2].description = 'Best'
  }

  return sortedPlans
}

export const paymentTypesResponse = (paymentTypes) => {
  return paymentTypes.map((paymentType) => ({
    id: paymentType.id,
    name: paymentType.name
  }))
}
