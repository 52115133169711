import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { MenuItem, Select } from '@material-ui/core'

import { MuhibAvatar } from '../../../assets'
import { ButtonComp, FormInput, SelectInput } from '../../../components'
import Footer from './footer'

const useStyles = makeStyles((theme) => ({
  icon: {
    fill: 'white'
  }
}))

const EditForm = () => {
  const classes = useStyles()
  const [mobileCode, setMobileCode] = useState('+1')

  return (
    <div className="editFormContainer">
      <div className="centeredContainer">
        <img src={MuhibAvatar} className="avatarImage" />
      </div>
      <div className="chooseAvatarButton">
        <ButtonComp
          height={35}
          backgroundColor="#282DDB"
          hoverBackgroundColor="#282DDB"
          titleColor="white"
          borderColor="#282DDB"
          onClickHandler={() => {}}
          disabled={false}
          title={'CHOOSE AVATAR'}
          width={'85%'}
        />
      </div>
      <div className="editInputContainer">
        <div className="editFormInput">
          <FormInput id="firstName" name="lastName" placeholder="First Name" />
        </div>
      </div>
      <div className="editInputContainer">
        <div className="editFormInput">
          <FormInput id="email" name="email" placeholder="Email Address" />
        </div>
      </div>

      <div className="editInputContainer">
        <div className="dropDownSelectContainer">
          <div className="codeSelect">
            <Select
              style={{ width: '100%' }}
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              value={mobileCode}
              onChange={(event) => {
                setMobileCode(event.target.value)
              }}
              inputProps={{
                classes: {
                  icon: classes.icon
                }
              }}
              input={<SelectInput />}>
              <MenuItem value={'+1'}>+1</MenuItem>
              <MenuItem value={'+2'}>+92</MenuItem>
            </Select>
          </div>
          <div className="mobileNumberInput">
            <FormInput
              id="mobileNumber"
              name="mobileNumber"
              placeholder="Mobile Number"
            />
          </div>
        </div>
      </div>
      <div className="formButtons">
        <Footer />
      </div>
    </div>
  )
}

export default EditForm
