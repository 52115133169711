import React from 'react'
import videojs from 'video.js'
import 'videojs-hotkeys'

import './videoPlayer.css'

class VideoPlayer extends React.Component {
  componentDidMount() {
    const { getPlayer, listenPlayPause, showCloseButton, close } = this.props

    // instantiate Video.js
    this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
      const player = this

      /* ADD HOT KEYS FUNCTIONALITY */
      this.hotkeys({
        volumeStep: 0.1,
        seekStep: 5,
        enableModifiersForNumbers: false
      })

      var Button = videojs.getComponent('Button')

      /* ADD REWIND BUTTON */
      var rewindButton = videojs.extend(Button, {
        constructor: function () {
          Button.apply(this, arguments)

          this.addClass('icon-angle-left')

          this.controlText('Rewind')
        },

        handleClick: function () {
          const alreadyPaused = player.paused()

          player.pause()

          if (player.currentTime() >= 5)
            player.currentTime(player.currentTime() - 5)
          else player.currentTime(0)

          if (!alreadyPaused) player.play()
        }
      })

      videojs.registerComponent('rewindButton', rewindButton)

      player.getChild('controlBar').addChild('rewindButton', {}, 1)

      /* ADD Forward BUTTON */
      var forwardButton = videojs.extend(Button, {
        constructor: function () {
          Button.apply(this, arguments)

          this.addClass('icon-angle-right')

          this.controlText('Forward')
        },

        handleClick: function () {
          const alreadyPaused = player.paused()

          player.pause()

          player.currentTime(player.currentTime() + 5)

          if (!alreadyPaused) player.play()
        }
      })

      videojs.registerComponent('forwardButton', forwardButton)

      player.getChild('controlBar').addChild('forwardButton', {}, 2)

      var Component = videojs.getComponent('Component')

      /* HEADER LOGO */
      var TitleBar = videojs.extend(Component, {
        constructor: function (player, options) {
          Component.apply(this, arguments)

          if (options.text) this.updateTextContent(options.text)
        },

        createEl: function () {
          return videojs.createEl('div', {
            className: 'vjs-title-bar'
          })
        },

        updateTextContent: function (text) {
          videojs.emptyEl(this.el())
        }
      })

      videojs.registerComponent('TitleBar', TitleBar)

      this.addChild('TitleBar', { text: '' })

      /* CLOSE BUTTON */
      if (showCloseButton) {
        var closeButton = videojs.extend(Button, {
          constructor: function () {
            Button.apply(this, arguments)

            this.addClass('vjs-close')

            this.controlText('Forward')
          },

          handleClick: function () {
            player.dispose()
            close()
          }
        })

        videojs.registerComponent('CloseButton', closeButton)

        this.addChild('CloseButton', { text: '' })
      }
    })

    if (getPlayer) {
      getPlayer(this.player)
    }

    // Used timer because while farwording it pause and forward to that postion
    // to avoid that situation
    const checkIfPaused = () => {
      setTimeout(() => {
        if (this.player.paused()) listenPlayPause(true)
        else listenPlayPause(false)
      }, 150)
    }

    if (listenPlayPause) {
      this.player.on('play', checkIfPaused)
      this.player.on('pause', checkIfPaused)
    }
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) this.player.dispose()
  }

  render() {
    return (
      <div data-vjs-player>
        <video
          data-setup='{"fluid": true}'
          ref={(node) => (this.videoNode = node)}
          className="video-js vjs-big-play-centered vjs-default-skin"></video>
      </div>
    )
  }
}
VideoPlayer.defaultProps = {
  listenPlayPause: null,
  showCloseButton: true,
  close: null,
  getPlayer: null
}

export default VideoPlayer
