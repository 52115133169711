import React from 'react'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import { Drawer, List, IconButton } from '@material-ui/core'
import { Menu as MenuIcon } from '@material-ui/icons'
import { useSelector, useDispatch } from 'react-redux'

import { PrimaryButton } from '../../styles/colors'
import { setIsReserveModal } from '../../redux/modules/globalModals'
import { makeDrawerStyles } from './styles'
import { MainDrawerItems } from '../../lib/constant'

const MenuDrawer = ({ state, toggleDrawer, selectedPage, mobileMenuId }) => {
  const classes = makeDrawerStyles()
  const history = useHistory()
  const User = useSelector((state) => state.user)
  const dispatch = useDispatch()

  const handleMenu = (menuId) => {
    if (menuId === '0') history.push('/')
    else if (menuId === '1') history.push('/cast')
    else if (menuId === '2') history.push('/game')
    else if (menuId === '3') {
    } else {
      if (User.isLogged)
        dispatch(setIsReserveModal({ isReserveModalVisible: true }))
      else history.push('/login')
    }
  }

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom'
      })}
      style={{ backgroundColor: 'black', height: '100vh' }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}>
      <List disablePadding={true} className="list-left">
        <IconButton
          className={classes.drawerIconButton}
          aria-label="show more"
          aria-controls={mobileMenuId}
          aria-haspopup="true"
          onClick={toggleDrawer('left', true)}
          color="inherit">
          <MenuIcon className={classes.drawerIcon} />
        </IconButton>
        {MainDrawerItems.map((item) => {
          return (
            <div
              id={item.id}
              className="left-menu"
              role="presentation"
              onClick={() => handleMenu(item.id)}
              style={{
                backgroundColor:
                  selectedPage === item.pageUrl ? PrimaryButton : 'inherit',
                color: selectedPage === item.pageUrl ? 'white' : '#cbcbcb'
              }}>
              {item.name}
            </div>
          )
        })}
      </List>
    </div>
  )

  return (
    <div>
      <Drawer
        anchor={'left'}
        open={state['left']}
        onClose={toggleDrawer('left', false)}>
        {list('left')}
      </Drawer>
    </div>
  )
}

export default MenuDrawer
