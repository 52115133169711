import { Auth } from 'aws-amplify'

// constants
const SET_USER = 'SET_USER'
const IS_LOGGED = 'IS_LOGGED'
const SET_PURCHASED = 'SET_PURCHASED'
const SET_EMAIL = 'SET_EMAIL'
const SET_USER_DETAIL = 'SET_USER_DETAIL'
const SET_PASSWORD = 'SET_PASSWORD'

// actions
export const setUser = () => ({
  type: SET_USER,
  isLogged: true
})

export const purchaseMovie = () => {
  return {
    type: SET_PURCHASED,
    purchased: true
  }
}

export const setEmailAddress = (payload) => {
  return {
    type: SET_EMAIL,
    payload
  }
}

export const setAccountPassword = (payload) => {
  return {
    type: SET_PASSWORD,
    payload
  }
}

// thunks
export const loginUser = (email, password) => {
  return async (dispatch) => {
    try {
      const user = await Auth.signIn(email, password)
      const { attributes, signInUserSession } = user
      dispatch({
        type: SET_USER_DETAIL,
        payload: {
          attributes,
          password: null,
          accessToken: signInUserSession.accessToken,
          isLogged: true
        }
      })
      return Promise.resolve()
    } catch (err) {
      return Promise.reject(err)
    }
  }
}
export const logoutUser = () => {
  return async (dispatch) => {
    try {
      await Auth.signOut()
      dispatch({
        type: SET_USER_DETAIL,
        payload: {
          email: null,
          attributes: null,
          password: null,
          accessToken: null,
          isLogged: false
        }
      })
      return Promise.resolve()
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

// reducer
const initialState = {
  isLogged: false,
  isLoading: false,
  purchased: false,
  email: null,
  attributes: null,
  accessToken: null,
  password: null
}

const reducer = (state = initialState, action) => {
  const { type, payload } = action
  // console.log('payload is: ',payload)
  switch (type) {
    case IS_LOGGED:
      return {
        ...state,
        isLogged: action.isLogged
      }
    case SET_USER:
      return {
        ...state,
        isLogged: action.isLogged
      }
    case SET_PURCHASED:
      return {
        ...state,
        purchased: action.purchased
      }
    case SET_EMAIL:
      return {
        ...state,
        email: payload.email
      }
    case SET_PASSWORD:
      return {
        ...state,
        password: payload.password
      }
    case SET_USER_DETAIL:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}

export default reducer
