import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Auth } from 'aws-amplify'
import RefreshIcon from '@material-ui/icons/Refresh'

import './verifyMobile.css'
import { ButtonComp, FormInput } from '../../../components'
import { loginUser } from '../../../redux/modules/user'

const VerifyEmail = () => {
  const history = useHistory()

  const User = useSelector((state) => state.user)

  const [code, setCode] = useState('')
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if (User.email) return

    history.push('/register')
  }, [User.email, history])

  const handleEmailVerification = async () => {
    if (code.trim() === '') {
      setError('Please enter confirmation code')
      return
    }

    setLoading(true)
    setError(null)

    try {
      const status = await Auth.confirmSignUp(User.email, code)

      if (status === 'SUCCESS') handleLogin()
      else {
        setLoading(false)
        setError('code not matching')
      }
    } catch (err) {
      if (err.code === 'CodeMismatchException') {
        setError(err.message)
      } else {
        setError('Something went wrong please try again')
      }
      setLoading(false)
    }
  }

  const handleLogin = async () => {
    try {
      await dispatch(loginUser(User.email, User.password))

      setLoading(false)

      history.push('/')
    } catch (err) {
      setLoading(false)

      switch (err.code) {
        case 'UserNotFoundException':
          setError('User not found')
          break
        case 'UserNotConfirmedException':
          setError('Account not confirmed')
          break
        case 'NotAuthorizedException':
          setError('Incorrect email or password')
          break
        default:
          setError('Something went wrong.')
          break
      }
    }
  }

  const resendVerificationCode = async () => {
    try {
      await Auth.resendSignUp(User.email)
    } catch (err) {
      console.log('Error in resending code is: ', err)
    }
  }

  return (
    <div className="pageWithBackgound">
      <div className="verifyPageMain">
        <div className="columnCenteredConatiner">
          <div className="verifyMobileTitle">
            <h3 className="verifyMobileTitleText">SUCCESSFULLY CREATED</h3>
            <p className="verifyMobileSubTitleText">
              Check your email and enter verification code
            </p>
          </div>
          <div className="codeInput">
            <FormInput
              value={code}
              placeholder="Confirmation Code"
              id="code"
              name="code"
              onChangeHandler={(event) => setCode(event.target.value)}
            />
            {error && <p className="errorMessage">{error}</p>}
            <div
              role="presentation"
              onClick={resendVerificationCode}
              className="sendAgainText">
              <span>
                <RefreshIcon className="sendAgainIcon" />
              </span>{' '}
              Send Again
            </div>
          </div>
        </div>
        <div className="verifyButtonContaienr">
          <ButtonComp
            onClickHandler={handleEmailVerification}
            disabled={false}
            loading={loading}
            title={'Verify'}
            width={300}
          />
        </div>
      </div>
    </div>
  )
}

export default VerifyEmail
