import React, { useState, useReducer } from 'react'
import { useHistory } from 'react-router-dom'
import { Auth } from 'aws-amplify'

import { ButtonComp, FormInput } from '../../../components'
import { validateEmail } from '../../../lib/utils'
import './forgetPassword.css'

const ForgetPassword = () => {
  const history = useHistory()

  const [{ email, password, code }, setInputValues] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      email: '',
      password: '',
      code: ''
    }
  )

  const [isCodeSent, setIsCodeSent] = useState(false)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleInputValues = (event) => {
    const { name, value } = event.target

    setInputValues({ [name]: value })
  }

  const sendVerificationCode = async () => {
    if (email.trim() === '') {
      setError('Please enter your email address')
      return
    }

    if (!validateEmail(email.toLowerCase())) {
      setError('Please enter a valid email address')
      return
    }

    setLoading(true)
    setError(null)

    try {
      await Auth.forgotPassword(email)

      setIsCodeSent(true)
      setLoading(false)
    } catch (err) {
      handleError(err)
    }
  }

  const handleError = (err) => {
    setLoading(false)

    switch (err.code) {
      case 'UserNotFoundException':
        setError('User not found')
        break
      case 'UserNotConfirmedException':
        setError('Account not confirmed')
        break
      case 'InvalidPasswordException':
        setError('Password must not be less than 8 and have lower characters')
        break
      case 'LimitExceededException':
        setError(err.message)
        break
      default:
        setError('Something went wrong')
        break
    }
  }

  const resetPassword = async () => {
    if (password.trim() === '') {
      setError('Please enter your password')
      return
    }

    if (password.length < 8) {
      setError('Password must not be less then 8')
      return
    }

    setLoading(true)

    try {
      await Auth.forgotPasswordSubmit(email, code, password)

      history.push('/login')
    } catch (err) {
      handleError(err)
    }
  }

  return (
    <div className="pageWithBackgound">
      <div className="columnCenteredConatiner">
        <div className="columnConatiner">
          <h3 className="verifyMobileTitleText">Reset Your Password</h3>
          <p className="verifyMobileSubTitleText">
            Enter your email address and check for verification code
          </p>
        </div>
        <div style={{ width: 300 }}>
          {!isCodeSent ? (
            <div className="codeInput">
              <p className="inputLabel">Email Address</p>
              <FormInput
                value={email}
                placeholder="Email Address"
                type="email"
                id="email"
                name="email"
                onChangeHandler={handleInputValues}
              />
            </div>
          ) : (
            <div>
              <div className="codeInput">
                <p className="inputLabel">Verification Code</p>
                <FormInput
                  value={code}
                  placeholder="Verification Code"
                  id="code"
                  name="code"
                  onChangeHandler={handleInputValues}
                />
              </div>
              <div className="codeInput">
                <p className="inputLabel">New Password</p>
                <FormInput
                  placeholder="New Password"
                  id="password"
                  name="password"
                  type="password"
                  onChangeHandler={handleInputValues}
                />
              </div>
            </div>
          )}
        </div>
        {error && <p className="resetPassworError">{error}</p>}
      </div>

      <div className="verifyButtonContaienr">
        <ButtonComp
          onClickHandler={isCodeSent ? resetPassword : sendVerificationCode}
          disabled={false}
          loading={loading}
          title={isCodeSent ? 'SUBMIT' : 'SEND CODE'}
          width={300}
        />
      </div>
    </div>
  )
}

export default ForgetPassword
