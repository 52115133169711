import React from 'react'
import { useMediaPredicate } from 'react-media-hook'

const animationDown = 'scaleDown 1500ms ease-in-out forwards'
const animationUp = 'scaleUp 1500ms ease-in-out forwards'

const CastDetail = ({ item, index, primary, secondary }) => {
  const mobileView = useMediaPredicate('(max-width: 1200px)')

  return (
    <div id={item.id}>
      <PicturesStack
        picture={mobileView ? item.pictureForMobile : item.picture}
        index={index}
        primary={primary}
        secondary={secondary}
      />
      <TitleLayer title={item.title} index={index} primary={primary} />
      <DetailLayer index={index} detail={item.detail} primary={primary} />
    </div>
  )
}

const TitleLayer = ({ title, index, primary }) => {
  return (
    <div className="titleLayer">
      <img
        src={title}
        className={'titleImage'}
        style={{
          transform:
            index === primary ? 'translate(0px,0)' : 'translate(-1000px,0)'
        }}
      />
    </div>
  )
}

const DetailLayer = ({ index, detail, primary }) => {
  return (
    <div
      className="detailLayer"
      style={{
        display: index === primary ? 'block' : 'none'
      }}>
      {detail}
    </div>
  )
}

const PicturesStack = ({ picture, index, primary, secondary }) => {
  return (
    <div
      className="picturesLayer"
      style={{
        zIndex: index === primary ? 3 : 1,
        display: index === primary || index === secondary ? 'flex' : 'none'
      }}>
      <div
        className={'castImageItem'}
        style={{
          transform:
            index === primary
              ? 'scale(0.87)'
              : index === secondary
              ? 'scale(0.55)'
              : 'scale(0)',
          animation: index === primary ? animationUp : animationDown
        }}>
        <img src={picture} style={{ height: '95vh' }} />
      </div>
    </div>
  )
}

export default CastDetail
