import React from 'react'
import { IconButton } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'

const dummyNotifications = [
  {
    id: '0',
    title: 'Pre Book with code ISHRAT20 for 20% off and other perks',
    time: '20 mints',
    read: false
  },
  {
    id: '1',
    title: 'Pre Book with code ISHRAT20 for 20% off and other perks',
    time: '30 mints',
    read: false
  },
  {
    id: '2',
    title: 'Pre Book with code ISHRAT20 for 20% off and other perks',
    time: '20 mints',
    read: true
  },
  {
    id: '3',
    title: 'Pre Book with code ISHRAT20 for 20% off and other perks',
    time: '30 mints',
    read: true
  }
]

const NotificationModal = ({ closeModal }) => {
  return (
    <div className="notification-mainContainer">
      <div className="notification-headerContainer">
        <div className="notifcation-backButton">
          <IconButton size={'small'} onClick={closeModal}>
            <ArrowBackIcon style={{ color: 'white' }} />
          </IconButton>
        </div>
        <div className={'notification-headerText'}>Notifications</div>
      </div>

      {dummyNotifications.map((item) => {
        return <NotificationItem item={item} />
      })}
    </div>
  )
}
export default NotificationModal

const NotificationItem = ({ item }) => {
  const { id, read, title, time } = item
  return (
    <div id={id} className="notification-item">
      <div className="notification-dot-container">
        <FiberManualRecordIcon
          className={read ? 'notification-dot' : 'unReadNotification-dot'}
        />
      </div>
      <div className="notificaiton-itemTitleContainer">
        <p
          className={
            read ? 'notification-item-title' : 'unReadnotification-item-title'
          }>
          {title}
        </p>
        <div className={read ? 'notification-time' : 'unReadnotification-time'}>
          {time}
        </div>
      </div>
    </div>
  )
}
