import FB from '../assets/icons/fb.svg'
import Instagram from '../assets/icons/instagram.svg'
import Twitter from '../assets/icons/twitter.svg'

const Icons = {
  FB,
  Instagram,
  Twitter
}
export default Icons
