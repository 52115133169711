import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  icon: {
    fill: '#969696'
  },
  passwordIcon: {
    color: '#969696',
    height: 18,
    width: 18
  },
  genderInput: {
    width: '100%',
    color: 'white'
  },
  unselectedGenderInput: {
    width: '100%',
    color: 'rgb(119,119,131)'
  },
  selected: {
    width: '30%',
    color: 'white'
  },
  unselected: {
    width: '30%',
    color: 'rgb(119,119,131)'
  },
  selectedCode: {
    width: '25%',
    color: 'white'
  },
  unselectedCode: {
    width: '25%',
    color: 'rgb(119,119,131)'
  }
})

export { useStyles }
