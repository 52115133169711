import React from 'react'

import { HistoryTableColumns } from '../../../lib/constant'

const dummyData = [
  {
    id: '0',
    accountNumber: '000024548765658',
    title: 'Ishrat',
    for: 'Ahmad',
    type: 'Premium',
    date: '15/02/2021',
    total: 800
  },
  {
    id: '1',
    accountNumber: '000024548765658',
    title: 'Ishrat',
    for: 'Mubashir',
    type: 'Standard',
    date: '15/02/2021',
    total: 800
  },
  {
    id: '2',
    accountNumber: '000024548765658',
    title: 'Ishrat',
    for: 'Self',
    type: 'Basic',
    date: '15/02/2021',
    total: 800
  }
]

const HistoryTable = () => {
  return (
    <div className="historyTableContainer">
      <table className="historyTable">
        {HistoryTableColumns.map((column) => (
          <th
            key={column.id}
            className={`tableHeader ${column.name === 'Total' ? 'total' : ''}`}>
            {column.name}
          </th>
        ))}
        {dummyData.map((item) => (
          <tr className="tableRow">
            <td className="tableColumn">{item.accountNumber}</td>
            <td className="tableColumn">{item.title}</td>
            <td className="tableColumn">{item.for}</td>
            <td className="tableColumn">{item.type}</td>
            <td className="tableColumn">{item.date}</td>
            <td className="tableColumn total">PKR {item.total}</td>
          </tr>
        ))}
      </table>
    </div>
  )
}

export default HistoryTable
