/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:b48f972f-a2e9-47af-a2f6-691cf2c8fad2",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_XjhN23pI7",
    "aws_user_pools_web_client_id": "346k3ge1mrko8pq6jmd8cjida4",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://ftkqgkm4xnbinjnp6juetjmf2m.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-ohoyi7m53rddpamkmbpm357cni",
    "aws_cloud_logic_custom": [
        {
            "name": "checkout",
            "endpoint": "https://582mep0pvg.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        },
        {
            "name": "stripeCheckoutSession",
            "endpoint": "https://w0hhw533rj.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        },
        {
            "name": "orderDetail",
            "endpoint": "https://mtueq10071.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        }
    ],
    "aws_user_files_s3_bucket": "thumbnails-and-captions173105-dev",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
